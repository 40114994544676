import React, { Component } from 'react';

import Header from '../header/header.js';
import Footer from '../footer/footer.js';
import ContactForm from './contactform.js';
import  {Helmet} from "react-helmet";

export default class Support extends Component {

  render() {
	  return (
		  <div>
			  <Helmet>
					<title>J2 Blueprint Supply Co. | Schedule a Call Back</title>	
					<meta name="description" content="Shedule a phone call with the professionals at J2" />
                </Helmet>
		  <Header page_support={true}/>

		  <div role="main" className="main">



<section className="page-header page-header-modern bg-color-dark page-header-md">

	<div className="container">

		<div className="row">





			<div className="col-md-8 order-2 order-md-1 align-self-center p-static">

				<h1 className=""><strong>Contact</strong> Us</h1>



			</div>





			<div className="col-md-4 order-1 order-md-2 align-self-center">

				<ul className="breadcrumb d-block text-md-right breadcrumb-light">

					<li><a href="/">Home</a></li>

					<li className="active">Support</li>

				</ul>

			</div>

		</div>

	</div>

</section>


	<ContactForm email="service" subject="Contact Form [Service Call Me]"/>


</div>



		  <Footer/>
		  </div>
	  )
  }
}