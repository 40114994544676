import React, { Component } from 'react';

import Header from '../header/header.js';
import Footer from '../footer/footer.js';


import ContactForm from '../support/contactform.js';


import  {Helmet} from "react-helmet";


import { FaAngleRight,FaAngleDown,FaPhone,FaClock,FaRocket,FaWrench,FaPrint,FaFacebookF,FaLinkedinIn,FaBars } from 'react-icons/fa';

export default class J2BSysHomepage extends Component {

	componentDidMount() {
		setTimeout(() => {
			window.initCarousel();
			window.doCaro();
			
			console.log("Loading homepage content");
		}, 500);
	
	}

  render() {
	  return (
		  <div>
			<Helmet>
				<title>J2 Business Systems |  Printers, Copiers, Scanners, MFPs and Supplies Sales and Service</title>	
				<meta name="keywords" content="scanner, printer, copier, color, giclee, hp, canon, oce, large format, large, wide, ink, toner, paper" />
				<meta name="description" content="J2 Business Systems offers all of the technology solutions your office needs to thrive." />
            </Helmet>

		  <Header page_home={true}/>
		

		  
	
		  			
					
		  
					  <section className="section section-overlay-opacity section-overlay-opacity-scale-7 border-0 m-0" style={{backgroundImage: 'url(j2img/hpstock/openhuddle.png)', backgroundSize: 'cover', backgroundPosition: 'center'}}>
					<div className="container py-5">
						<div className="row align-items-center justify-content-center">
							<div className="col-lg-6 text-center mb-5 mb-lg-0">
								<div className="d-flex flex-column align-items-center justify-content-center h-100">
									<h3 className="position-relative text-color-light text-5 line-height-5 font-weight-medium px-4 mb-2 appear-animation" data-appear-animation="fadeInDownShorterPlus" data-plugin-options="{'minWindowWidth': 0}">
										
										Streamline <span className="position-relative">your </span>
										
									</h3>
									<h1 className="text-color-light font-weight-extra-bold text-12 mb-2 appear-animation" data-appear-animation="blurIn" data-appear-animation-delay="1300" data-plugin-options="{'minWindowWidth': 0}">workflow.</h1>
									<p className="text-4 text-color-light font-weight-light opacity-7 mb-0" data-plugin-animated-letters data-plugin-options="{'startDelay': 3500, 'minWindowWidth': 0}">J2 Business Systems offers personalized solutions for your print and document management needs.</p>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="slider-contact-form-wrapper bg-primary rounded p-5 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="1000" data-appear-animation-duration="1s">
									<div className="row">
										<div className="col text-center">
											<h2 className="font-weight-semi-bold text-color-light text-6 mb-2">Get a FREE on site analysis</h2>
											<p className="text-color-light opacity-7 font-weight-light mb-4 px-xl-5">Enter your personal info below and let’s start talking about your technology needs.</p>
										</div>
									</div>
									<div className="row">
										<div className="col">



										<ContactForm email="service" subject="J2B Sys Site Survey" nomessage={true} nosubject={true} basicform={true}/>
											
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			


				<div className="container">

		  
			<div role="main" className="main">
			<div className="container py-4">

<div className="mt-4 pt-2"><h1>J2 Market Solutions</h1></div>

<div className=" mt-4 pt-2">
	<div className="row team-list sort-destination" data-sort-id="team">
		<div className="col-12 col-sm-6 col-lg-3 isotope-item marketing">
			<span className="thumb-info thumb-info-hide-wrapper-bg mb-4">
				<span className="thumb-info-wrapper">
					<a href="#">
						<img src="j2img/hpstock/small/aec.png" className="img-fluid" alt=""/>
						<span className="thumb-info-title">
							<span className="thumb-info-inner">AEC</span>
							<span className="thumb-info-type">Solutions</span>
						</span>
					</a>
				</span>
				
			</span>
		</div>
		<div className="col-12 col-sm-6 col-lg-3 isotope-item leadership">
			<span className="thumb-info thumb-info-hide-wrapper-bg mb-4">
				<span className="thumb-info-wrapper">
					<a href="#">
						<img src="j2img/hpstock/small/mfg.png" className="img-fluid" alt=""/>
						<span className="thumb-info-title">
							<span className="thumb-info-inner">Manufacturing</span>
							<span className="thumb-info-type">Solutions</span>
						</span>
					</a>
				</span>
				
			</span>
		</div>
		
		<div className="col-12 col-sm-6 col-lg-3 isotope-item development">
			<span className="thumb-info thumb-info-hide-wrapper-bg mb-4">
				<span className="thumb-info-wrapper">
					<a href="#">
						<img src="j2img/hpstock/small/small.png" className="img-fluid" alt=""/>
						<span className="thumb-info-title">
							<span className="thumb-info-inner">Small Business</span>
							<span className="thumb-info-type">Solutions</span>
						</span>
					</a>
				</span>
				
			</span>
		</div>
		<div className="col-12 col-sm-6 col-lg-3 isotope-item design">
			<span className="thumb-info thumb-info-hide-wrapper-bg mb-4">
				<span className="thumb-info-wrapper">
					<a href="#">
						<img src="j2img/hpstock/small/edu.png" className="img-fluid" alt=""/>
						<span className="thumb-info-title">
							<span className="thumb-info-inner">Education</span>
							<span className="thumb-info-type">Solutions</span>
						</span>
					</a>
				</span>
				
			</span>
		</div>




		<div className="col-12 col-sm-6 col-lg-3 isotope-item marketing">
			<span className="thumb-info thumb-info-hide-wrapper-bg mb-4">
				<span className="thumb-info-wrapper">
					<a href="#">
						<img src="j2img/hpstock/small/health.png" className="img-fluid" alt=""/>
						<span className="thumb-info-title">
							<span className="thumb-info-inner">Health Care</span>
							<span className="thumb-info-type">Solutions</span>
						</span>
					</a>
				</span>
				
			</span>
		</div>
		<div className="col-12 col-sm-6 col-lg-3 isotope-item leadership">
			<span className="thumb-info thumb-info-hide-wrapper-bg mb-4">
				<span className="thumb-info-wrapper">
					<a href="#">
						<img src="j2img/hpstock/small/gov.png" className="img-fluid" alt=""/>
						<span className="thumb-info-title">
							<span className="thumb-info-inner">Government</span>
							<span className="thumb-info-type">Solutions</span>
						</span>
					</a>
				</span>
				
			</span>
		</div>
		
	</div>

</div>

</div>
				
				
			</div>


			

				<div className="container">

				
					<hr/>
					<div className="row">
						<div className="col-lg-12">
							<div className="owl-carousel owl-theme carousel-center-active-item mb-0" data-plugin-options="{'responsive': {'0': {'items': 1, autoWidth:true}, '476': {'items': 1, autoWidth:true}, '768': {'items': 2, autoWidth:true}, '992': {'items': 2, autoWidth:true}, '1200': {'items': 2, autoWidth:true}}, 'autoplay': true, 'autoplayTimeout': 3000, 'dots': true}">
							
							
								<div>
								   <img className="img-fluid"   src="/img/logos/supplies/hp_opt.png" alt="HP"/>
									<ul>
										<li>Premier Partner Hp A3 copier</li>
										<li>Hp TPS Pagewide XL Servicing dealer</li>
										<li>Hp GDS Design Jet printer servicing dealer</li>
										<li>Hp LPS Low Volume product specialist Servicing dealer</li>
									</ul>
									
									
								</div>
								<div>
							{/*	<img className="img-fluid" src="/img/logos/supplies/canon_opt.png"  alt="Canon"/>
									<ul>
										<li>Authorized IPF wide format printer servicing dealer</li>
										<li>Authorized Canon Production Print systems servicing dealer.</li>
									</ul>

								*/}
								</div>
								<div>
									<img className="img-fluid" src="/img/logos/supplies/kyocera.png" alt="Kyocera"/>
									<ul><li>Authorized Servicing Kyocera dealer</li></ul>

								</div>
								
								
							</div>					
						</div>
						
					</div>

				</div>

		


		  
			</div>
		  <Footer/>
		  </div>
	  
	  )
  }
}