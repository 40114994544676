import React, { Component } from 'react';

import Header from '../header/header.js';
import Footer from '../footer/footer.js';
//import ContactForm from '../support/contactform.js';
import  {Helmet} from "react-helmet";


export default class BidsetDistribution extends Component {

	constructor(props) {
		super(props);
		this.state = {bidsets:[]}
	}

	componentDidMount() {
        
		fetch("https://plans.j2b.com/libs/bidsetProjectsJSON.php")
  .then(response => response.json())
  .then(data => {
                  //console.log(data);
                  console.log(data);
				this.setState({bidsets:data});
  	})
	}


    render() {


        var bidsetRender= "";
      //  console.log(this.state.bidsets);
        //console.log(this.state.bidsets.length);

        if (this.state.bidsets !== []) {
           // console.log("BidSet Render! ");
            //console.log(this.state.bidsets);
            
            bidsetRender = Object.keys(this.state.bidsets).map((co,label) => {
                if (co != null) {
                   // console.log("CO: " + co);
                    //console.log(this.state.bidsets[co]);
                    return (
                        <>
                        <div className="row">
                            <div className="col-md-12">
                                <h1>{co}</h1>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <td>Bid&nbsp;Date</td>
                                            <td>Project Name</td>
                                            <td>Addenda</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                {Object.keys(this.state.bidsets[co]).map((p,label)=>{
                                // console.log(this.state.bidsets[co][p]);
                                    return(
                                        <tr>
                                            <td style={{whiteSpace:'no-wrap'}}><span className="text-nowrap" style={{whiteSpace:'no-wrap'}}>{this.state.bidsets[co][p].biddate}</span></td>
                                            <td><a href={"https://plans.j2b.com/"+this.state.bidsets[co][p].url}>{this.state.bidsets[co][p].name}</a></td>  
                                            <td dangerouslySetInnerHTML={{__html: this.state.bidsets[co][p].addenda}}></td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                                </table>

                            </div>
                        </div>
                        </>
                    )
                } else 
                    return(<></>);
            })
        }


        return(
            <div>
                <Helmet>
				<title>J2 Blueprint Supply Co. |  Bidset Distribution</title>	
			
				<meta name="description" content="Construction projects availible for viewing and bidding hosted on J2's Online Planrooms." />
            </Helmet>


            <Header page_services={true}/>
            
              <div role="main" className="main">

				<section className="page-header page-header-modern page-header-md bg-transparent m-0">

                <div className="container">

                    <div className="row">



                        <div className="col-md-12 align-self-center p-static order-2 text-center">



                            <h1 className="text-dark text-10"><strong>Bidset Distribution</strong></h1>

                <span className="sub-title text-dark">Available Public Projects</span>

                        </div>



                        <div className="col-md-12 align-self-center order-1">



                            <ul className="breadcrumb d-block text-center">

                                <li><a href="/">Home</a></li>

                                <li className="active">Bidset Distribution</li>

                            </ul>

                        </div>

                    </div>

                </div>

                </section>

                <hr className="m-0" />
                    <section className="section bg-color-grey section-height-3 border-0 mt-5 mb-0">

                        <div className="container">
                        
                            
                            {bidsetRender}

                        </div>
                    </section>

                </div>
            <Footer />
            </div>
        );
    }

}