import React, { Component } from 'react';

import Header from '../header/header.js';
import Footer from '../footer/footer.js';
//import { stat } from 'fs';
import ContactForm from '../support/contactform.js';
import  {Helmet} from "react-helmet";

import { css } from "@emotion/core";

import Input from 'react-phone-number-input/input';
import { isPossibleNumber } from 'libphonenumber-js'
import GridLoader from "react-spinners/GridLoader";
import 'react-phone-number-input/style.css'
import Recaptcha from 'reaptcha';

import {FaPhone,FaEnvelope} from 'react-icons/fa';

var email_validator = require("email-validator");



const override = css`
display: block;
margin: 0 auto;
border-color: #316ec5;
`;

const WAIT_INTERVAL = 1000;
//const ENTER_KEY = 13;
const API_SERVER = "apigw.j2b.com";
const API_PORT = 443;
const API_KEY = "7pslocGf4impAFD2pObaGAteC1iBf3FQ"


const ReCAPTCHAKEY="6LcFmeAUAAAAAOhPsCu-HLepPjlPtUkHcXaSvYiX";
const ReCAPTCHAKEYv2="6LcGMLIZAAAAAGG-paf_wzsWLDCc8mTBlpYFJvtC";
const SCORE_THRESHOLD = 0.5;
const API_URL = "https://0k7qx2td9h.execute-api.us-west-2.amazonaws.com/prod";



export default class RepairServiceCall extends Component {

	constructor(props) {
		super(props);
		this.state= {};
		//if (props.testmode) {
			this.state = {idSel:false,validatedForm:false,loading:false,email:'',phone:'',name:'',message:'',messageLength:0};
			this.setState({idSel: false,idNumberError:3,equipmentDescription:"",selectedMachine:0});	
	//	} else {
	//	this.state = {idSel:"withoutid"/*false*/,validatedForm:false,loading:false,email:'',phone:'',name:'',message:'',messageLength:0};
	//	this.setState({idSel: "withoutid" /*false*/,idNumberError:3,equipmentDescription:"",selectedMachine:0});
	//	}


		this.handleNameChange = this.handleNameChange.bind(this);
		this.handlePhoneChange = this.handlePhoneChange.bind(this);
		this.handleMessageChange = this.handleMessageChange.bind(this);
		this.handleEmailChange = this.handleEmailChange.bind(this);


	}
	componentWillMount() {
        this.timer = null;
    }

	serviceCallWithId = () => {
		console.log("Service Call With ID");
		var state = this.state;
		state.idSel = "withid";
		this.setState(state);

	}

	selectMachine=(event) => {
		var state = this.state;
		state.selectedMachine = event.target.value;
		state.equipmentNumber = event.target.value;
		state.idNumberError = 1;
		this.setState(state);
		//console.log(event.target.value);
	}

	handleNameChange(event) {
		var _state = this.state;
		_state.touched  = true;
		_state.name = event.target.value;
		_state.validatedForm = this.validateForm(_state);
		this.setState(_state);

        //this.setState({ touched: true });
        //this.setState({ name: event.target.value })
    }
    handlePhoneChange(event) {
	   // console.log(event);
	   var _state = this.state;
		_state.touched  = true;
		if (event != undefined)
			_state.phone = event;
		_state.validatedForm = this.validateForm(_state);
		this.setState(_state);

      /* this.setState({ touched: true });
        if (event !== undefined)
           this.setState({ phone: event })*/
    }
    handleEmailChange(event) {
		var _state = this.state;
		_state.touched  = true;
		_state.email = event.target.value;
		_state.validatedForm = this.validateForm(_state);
		this.setState(_state);
        /*this.setState({ touched: true });
    	this.setState({ email: event.target.value })*/
    }
	handleMessageChange(event) {

		var _state = this.state;
		_state.touched  = true;
		_state.message = event.target.value;
		_state.validatedForm = this.validateForm(_state);
		_state.messageLength =  event.target.value.length;
		this.setState(_state);

		/*
        this.setState({ touched: true });
    	this.setState({ message: event.target.value,messageLength:event.target.value.length })*/
    }

	triggerChange(module)  {
		var state = this.state;
		if (module==="equipment") {
			if (state.equipmentNumber !== '') {
				//console.log(state.equipmentNumber);
				fetch('https://'+API_SERVER+':'+API_PORT+'/scgw/api/v1/equipment/number?equipmentNumber='+state.equipmentNumber+'&API_KEY='+API_KEY)
				.then(res => res.json())
				.then((data) => {
				//	console.log(data);
					//console.log(data.result.length);
					if (data.result)
					if (data.result.length > 0)
					{
						state.idNumberError=1;
						var descr = data.result[0].Model + " : " + data.result[0].Description;
						if (data.result[0].Model.toUpperCase() === data.result[0].Description.toUpperCase())
							descr = data.result[0].Model;
						if (data.result[0].Location)
							state.equipmentDescription = descr + " - " + data.result[0].Location;
						else 
							state.equipmentDescription = descr;
						state.selectedMachine = data.result[0].EquipmentID;
					} else {
						state.idNumberError=2;
						state.equipmentDescription = "";
						state.selectedMachine=0;

					}
					if (this.validateForm(state))
						state.validatedForm = true;
					else
						state.validatedForm = false;

					this.setState(state);
						
				//this.setState({ contacts: data })
				})
				.catch(console.log)
			} else {
				state.equipmentDescription= "";
				state.idNumberError=3;
				if (this.validateForm(state))
						state.validatedForm = true;
					else
						state.validatedForm = false;
				this.setState(state);
			}
		}	else if (module ==="customer") {

			if (state.customerNumber !== '') {
				//console.log(state.equipmentNumber);
				fetch('https://'+API_SERVER+':'+API_PORT+'/scgw/api/v1/equipment/customer/number?customerNumber='+state.customerNumber+'&API_KEY='+API_KEY)
				.then(res => res.json())
				.then((data) => {
				//	console.log(data);
					//console.log(data.result.length);
					if (data.result)
					if (data.result.length > 0)
					{
						state.selectedMachine = 0;
						
						state.idNumberError=2;
						state.equipmentDescription="";
						state.equipmentDescription = data.result.map((item,key) => 	
						<div className="radio-custom radio-success" key={item.EquipmentID}>
							<input type="radio" id={item.EquipmentID} value={item.EquipmentID} name="EquipementSelection" onClick={this.selectMachine}/>
					<label htmlFor={item.EquipmentID}>{item.EquipmentNumber} - {item.Model} {item.Description.toUpperCase()!==item.Model.toUpperCase()?(<>: {item.Description}</>):<></>} {item.Location?(<>- {item.Location}</>):<></>}</label>
						</div>
						);
					} else {
						state.idNumberError=2;
						state.equipmentDescription = "";
						state.selectedMachine=0;

					}
					if (this.validateForm(state))
						state.validatedForm = true;
					else
						state.validatedForm = false;
					this.setState(state);
						
				//this.setState({ contacts: data })
				})
				.catch(console.log)
			} else {
				state.idNumberError=3;
				state.equipmentDescription= "";
				if (this.validateForm(state))
						state.validatedForm = true;
					else
						state.validatedForm = false;
				this.setState(state);
			}





			console.log("Not Equipment");
			
		}
		//this.setState(state);


	}

	equipmentNumberChange = (event) => {
		clearTimeout(this.timer);
		var state = this.state;
		state.equipmentNumber = event.target.value;
        this.setState(state,() => {
			this.timer = setTimeout(this.triggerChange.bind(this,'equipment'), WAIT_INTERVAL);
		});

       // this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
	   //console.log(event.target.value);

	}

	customerNumberChange = (event) => {
		clearTimeout(this.timer);
		var state = this.state;
		state.customerNumber = event.target.value;
        this.setState(state,() => {
			this.timer = setTimeout(this.triggerChange.bind(this,'customer'), WAIT_INTERVAL);
		});

       // this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
	   //console.log(event.target.value);

	}


	onSubmit = async() => {

	}





	submitToAPI = async () => {
	
	
	}

	validateEmail(email) 
    {

        return !email_validator.validate(email);
    }
    validateName(name) 
    {
        if (name.length >= 5)
          return false;
        return true;

    }
    validateMessage(message)
    {
        if (message.length >= 10)
         return false;
        return true;
    }

    validateSubject(message)
    {
        if (message.length < 1)
         return true;
        return false;
    }

    validatePhone(phone)
    {
        var valid = isPossibleNumber(phone);
        if (valid)
            return false;
        else return !isPossibleNumber("1"+phone);
        //return false;
    }




	validateForm(state) {
	

		if(state.idNumberError !== 1) {
			console.log("idnumber");
			return false;
		}
		if (this.validateEmail(this.state.email)) {
			console.log("invalid email");
			return false;
		}
		
		
		
		if (this.validateName(this.state.name)) {
			console.log("Invalid name");
			return false;
		}
		if (this.validateMessage(this.state.message)) {
			console.log("invalid message");
			return false;

		}
		if (this.validatePhone(this.state.phone)) {
			console.log("invalid phone");
			return false;
		}

		//console.log("Valid Form");
		return true;
	}

	serviceCallWithoutId = () => {
		console.log("Service Call Without ID");
		this.setState({idSel:"withoutid"});
	}



	verifyToken = async (token) => {
        this.setState({token: token});
        var _this = this;
       // _this.setState({loading:true});

		//setToken(token);
		fetch(API_URL+"/verifycaptcha/", {
			method: 'post',
			body: JSON.stringify({token:token,v2:true})
		}).then(function(response) {
			return(response.json());
		}).then(function(data) {
            console.log(data);
            //_this.setState({loading:false});
			if (data.statusCode === 200){
                //setScore(data.body.score);
                _this.setState({score:data.body.score});
            } else {
                _this.setState({score:0});
				//setScore(0);
			}
		});


	};

     onSubmit = async () => { 
        var valid = this.state.valid;
        valid = this.validateForm(this.state);
        if (valid) {
        //await setPending(true);
            var _this = this;
            _this.setState({loading:true});
           // const captchaToken = await window.grecaptcha.execute(ReCAPTCHAKEYv2, {
            //    action: 'homepage',
           // });
            //const result = await executeRecaptcha("homepage");
        
           // this.setState({token:captchaToken});
            this.submitToAPI(_this.state.token);
        } else {
            this.setState({touched:true});
            window.alert('Please complete all required fields');
        }
	 }
	 submitToAPI = async (code) => {
		/*hererer*/
   //	setPending(true);
	   var _this = this;
	  // var tkn= "";
	   
	   //if (code)
	   //	tkn = this.state.code;
	   //else
	   //	tkn = this.state.token;

	   fetch('https://'+API_SERVER+'/scgw/api/v1/service/request?API_KEY='+API_KEY, {
		   method: 'POST',
		   headers: {
			'Content-Type': 'application/json'
			// 'Content-Type': 'application/x-www-form-urlencoded',
		  },
		   body: JSON.stringify({
				equipmentId: this.state.selectedMachine,
				callerName: this.state.name +" ("+ this.state.phone + "; " +this.state.email+")",
				callDescription: this.state.message 
		   })

	   })
	   .then(function(response) {
		   console.log(response);
	   })
	   .then((data) => { 

			console.log(data);

	   });



	   fetch(API_URL+"/contactemail/", {
		   method: 'post',
		   body: JSON.stringify({
			   contact_email:'service',
			   email:this.state.email,
			   name:this.state.name,
			   phone:this.state.phone,
			   subject:"Service Call - Submitted",
			   when:"When",
			   email_subject:"Email Subject",
			   desc:"Message: "+this.state.message,
			   token:this.state.token,v2:true})

	   }).then(function(response) {
		   console.log(response);
		   if (response.ok) {
			   _this.setState({loading:false});
			   /*****HERE */
			   _this.setState({submitted:true});
			   console.log("ok!");
		   }
		   return(response.json());
	   }).then(function(data) {
		   //console.log(data);
		   
		 
	   //	setPending(false);
		   //setSubmitted(true);
	   });
   }

	renderNoSelection() {
		return (
			<div>
				<Helmet>
					<title>{`${ process.env.REACT_APP_NAME } | Place a service call`}</title>	
					<meta name="description" content="Place a service call. Our factory trained service technicians will get you up and running in no time!" />
                </Helmet>
			<Header page_repair={true}/>
			  <div role="main" className="main">
				  <section className="page-header page-header-modern page-header-md bg-transparent m-0"> 
					  <div className="container"> 
						  <div className="row">
							  <div className="col-md-12 align-self-center p-static order-2 text-center">
								  <h1 className="text-dark text-10"><strong>Service Call</strong></h1> 
								  <span className="sub-title text-dark">We will have you up and running in no time</span>
							  </div>
							  <div className="col-md-12 align-self-center order-1">

								  <ul className="breadcrumb d-block text-center"> 
									  <li><a href="/">Home</a></li> 
									  <li><a href="/repair">Repair</a></li>  
									  <li className="active">Service Call</li> 
								  </ul>  
							  </div>  
						  </div>  
					  </div>  
				  </section>  
				  <hr className="m-0" />  
				  <div className="container">
				  <div className="row justify-content-center"> 					   
					  <div className="col-sm-10 col-md-7 col-lg-4 mb-4 mb-lg-0">  
						  <div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-primary my-4">  
							  <div className="featured-box">  
								  <div className="box-content px-lg-4 px-xl-5 py-lg-5">  
									  <i className="icon-featured far fa-file-alt text-color-primary"></i>  
									  <h2 className="font-weight-normal text-5">ID <strong className="font-weight-extra-bold">Required</strong></h2>  
									  <p className="mb-0" style={{color:"black"}}>I have my Equipment Tag # or Account Number ready. </p>  
									  <span onClick={this.serviceCallWithId} className="btn btn-light btn-outline font-weight-semibold text-color-dark btn-px-5 btn-py-2 border-width-1 text-1 mt-3">Place Call</span>
								  </div>  
							  </div>  
						  </div>  
					  </div>  
					  <div className="col-sm-10 col-md-7 col-lg-4">  
						  <div className="featured-boxes featured-boxes-modern-style-1 my-4">  
							  <div className="featured-box overlay overlay-show">  
								  <div className="featured-box-background"></div>  
								  <div className="box-content px-lg-4 px-xl-5 py-lg-5">  
									  <i className="icon-featured far fa-star"></i>  
									  <h2 className="font-weight-normal text-5"><strong className="font-weight-extra-bold">No ID</strong> Necessary</h2>  
									  <p className="mb-0" style={{color:"white"}}>I dont know or dont have my Customer ID or Equipment ID </p>  
									  <span onClick={this.serviceCallWithoutId}  className="btn btn-light-2 btn-outline font-weight-semibold btn-px-5 btn-py-2 border-width-1 text-1 mt-3">Place Call</span> 
								  </div>  
							  </div>  
						  </div>  
					  </div>  
					  </div>   
				  </div>  			  
			  </div>
			
			
			
			<Footer/>
			</div>
		)
	}

	renderWithId() {

		var email_error = false;
		var name_error = false;
		var message_error = false;
		var phone_error = false;

	
	
		if (this.state.touched) {
			email_error = this.validateEmail(this.state.email);
			name_error = this.validateName(this.state.name);
			message_error = this.validateMessage(this.state.message);
			phone_error = this.validatePhone(this.state.phone);
		
		}
	
		

		var state = this.state;
	//	if (state.valid)
		var numberError ="";
		//console.log(this.state.idNumberError);

		if (this.state.idNumberError === 1)
		{
			numberError="has-success";
		} else if (this.state.idNumberError === 2)
		{
			numberError="has-warning";
		} else {
			numberError="has-danger";
		}



		if (this.state.loading) {
		return (
			<div className="sweet-loading">
			<GridLoader
			css={override}
			size={50}
			color={"#214d8a"}
			loading={this.state.loading}
			/>
			</div>
		) 
		} else
		return (
			<div>
				<Header page_repair={true}/>
			
			  <div role="main" className="main">
				  <section className="page-header page-header-modern page-header-md bg-transparent m-0">
					  <div className="container">
						  <div className="row">
							  <div className="col-md-12 align-self-center p-static order-2 text-center">
								  <h1 className="text-dark text-10"><strong>Service Call</strong></h1>
								  <span className="sub-title text-dark">We will have you up and running in no time</span>
							  </div>
							  <div className="col-md-12 align-self-center order-1">
								  <ul className="breadcrumb d-block text-center">
									  <li><a href="/">Home</a></li>
									  <li><a href="/repair">Repair</a></li>
									  <li className="active"><a href="/repair/servicecall">Service Call</a></li>
									  <li className="active">With ID</li>
								  </ul>
							  </div>
						  </div>
					  </div>
				  </section>  
				  <hr className="m-0" />			  
				  <div className="row">
					<div className="col">


					{this.state.submitted?(
                            <div className="contact-form-success alert alert-success  mt-4" id="contactSuccess">

                                <strong>Thank you!</strong> Your request has been submitted to J2.

                            </div>
                        ):(<></>)}
                        



						{!this.state.submitted?(
							<>
						<section className="card">
							<header className="card-header">
								<h2 className="card-title center">Submit a Service Call with an Equipment Tag # or Account Number</h2>
							</header>

							<div className="card-body">
							
									<div className={"form-group row " + numberError}>
										<label className="col-lg-3 control-label text-lg-right pt-2">Equipment Tag # or Account Number</label>
										<div className="col-sm-8">
											<div className="row">
												<div className="col-sm-4">
													<input disabled={this.state.customerNumber?true:false} type="text" className="form-control" placeholder="Equipment Tag #" id="equipmentNumber"  onChange={this.equipmentNumberChange}/>
													{!this.state.equipmentDescription?<img src="/j2img/servicetag.png" style={{padding:"5px"}}/>:<></>}
												</div>																										
												<div className="col-sm-4">
													<input disabled={this.state.equipmentNumber?true:false} type="text" className="form-control" placeholder="Account Number" id="customerNumber"  onChange={this.customerNumberChange}/>
													{!this.state.equipmentDescription?<img src="/j2img/invoicetag.png" style={{padding:"5px"}}/>:<></>}
												</div>
											</div>
											<div className="row">
												<div className="col-sm-8">
													<h4>{state.equipmentDescription}</h4>
												</div>
											</div>
										</div>
									</div>
									<div className="form-group row">
										<label className="col-lg-3 control-label text-lg-right pt-2" htmlFor="inputDefault">Your Name</label>
										<div className="col-lg-6">
											<input value={this.state.name}
                                        	onChange={this.handleNameChange} type="text" className="form-control" id="inputName"/>
										</div>
									</div>
									<div className="form-group row">
										<label className="col-lg-3 control-label text-lg-right pt-2" htmlFor="inputDefault">Your Phone</label>
										<div className="col-lg-6">
										<span className="input-group-prepend">
															<span className="input-group-text">
																<FaPhone className="fa-selector"/>
															</span>
															<Input
                                 							  className="form-control" 
                                  							  country="US"
                                      						  placeholder="Enter phone number"
															  value={this.state.phone}
                                       						  onChange={this.handlePhoneChange} type="text" className="form-control" id="inputPhone" 
															  error={this.state.phone ? (isPossibleNumber(this.state.phone) ? undefined : 'Invalid phone number') : 'Phone number required'}
															/>
															
														</span>
														<br/>
															 {phone_error && (<h6 className="text-secondary">Please enter a valid phone number</h6>)}
											
										</div>
									</div>
									<div className="form-group row">
										<label className="col-lg-3 control-label text-lg-right pt-2" htmlFor="inputDefault">Your Email</label>
										<div className="col-lg-6">
										<span className="input-group-prepend">
															<span className="input-group-text">
															<FaEnvelope className="fa-selector"/>
															</span>
															<input value={this.state.email}
                                        			        	onChange={this.handleEmailChange} type="text" className="form-control" id="inputEmail"/>
														
														</span>
														<br/>
														 {email_error && (<h6 className="text-secondary">Please enter a valid email address</h6>)}
											
										</div>
									</div>
									<div className="form-group row">
										<label className="col-lg-3 control-label text-lg-right pt-2" htmlFor="description">Short Description of the Problem <br/>({this.state.messageLength} / 255 characters )<br/>
										{message_error && (<h6 className="text-secondary">Please enter a more detailed message</h6>)}</label>
										<div className="col-lg-6">
										
											<textarea onChange={this.handleMessageChange}  maxLength="255" className="form-control" rows="3" id="description" data-plugin-textarea-autosize></textarea>
										</div>
									</div>

								

									<div className="form-group row">
									
										<label className="col-lg-3 text-lg-right pt-2 " htmlFor="submitButton">Submit Service Request</label>
										<div className="col-lg-6">
										<Recaptcha
                                sitekey={ReCAPTCHAKEYv2}
                                
                                onExpire={()=>{this.setState({score:0.0})}}
                                onVerify={recaptchaResponse => {
                                    // Do something
                                   // console.log(recaptchaResponse);
                                    this.setState({token:recaptchaResponse,score:0.5});
                                  }}
                                
                            />
										{this.state.loading?<span>Submitting Service Call</span>:<></>}	
										
										{this.state.score>=SCORE_THRESHOLD?(<>

										{this.state.validatedForm?<button onClick={this.onSubmit}  className="form-control btn btn-lg btn-primary" id="submitButton">Send</button>:<span>Please Complete Form to Continue</span>}
										
										
										</>):<span>Verifying</span>}
										</div>
									</div>

								
							</div>
						</section>
						</>):(<></>)}
					</div>
				  </div>					
				</div>
  
				<Footer/>
			</div>
		)
	}


	renderWithNoId(){
		return (
			<div>
								<Header page_repair={true}/>
							
							  <div role="main" className="main">
								  <section className="page-header page-header-modern page-header-md bg-transparent m-0">
									  <div className="container">
										  <div className="row">
											  <div className="col-md-12 align-self-center p-static order-2 text-center">
												  <h1 className="text-dark text-10"><strong>Service Call</strong></h1>
												  <span className="sub-title text-dark">We will have you up and running in no time</span>
											  </div>
											  <div className="col-md-12 align-self-center order-1">
												  <ul className="breadcrumb d-block text-center">
													  <li><a href="/">Home</a></li>
													  <li><a href="/repair">Repair</a></li>
													  <li className="active"><a href="/repair/servicecall">Service Call</a></li>
													  <li className="active">Without ID</li>
												  </ul>
											  </div>
										  </div>
									  </div>
								  </section>  
								  <hr className="m-0" />			  
								  <div className="row">
									<div className="col">
										<section className="card">
											<header className="card-header">
												<h2 className="card-title center">Submit a Service Call without an identifier</h2>
											</header>
			
											<div className="card-body">


											<ContactForm repairform={true} email="service" subject="Contact Form [ Service ]"/>

											</div>
										</section>
									</div>
								  </div>					
								</div>
				  
								<Footer/>
							</div>
						)
	}


  render() {
	if (this.state.idSel === "withid")
		return(this.renderWithId());
	if (this.state.idSel === "withoutid")
		return(this.renderWithNoId());
		
	return(this.renderNoSelection())
  }
	
		
	
  
	
  
}