import React, { Component } from 'react';

import Header from '../../header/header.js';
import Footer from '../../footer/footer.js';
import ContactForm from '../../support/contactform.js';

import  {Helmet} from "react-helmet";
export default class ServicesScans extends Component {

  render() {
	  return (
		  <div>
			  <Helmet>
					<title>J2 Blueprint Supply Co. | Document Scanning and Archival</title>	
					<meta name="description" content="We've got you covered for high speed digital scanning and online document archival services " />
                </Helmet>
		  <Header page_services={true}/>
		  
<div role="main" className="main">

	<section className="page-header page-header-classic page-header-md">

		<div className="container">

			<div className="row">

				<div className="col-md-8 order-2 order-md-1 align-self-center p-static">

					<h1 data-title-border>Document Archival</h1>

				</div>

				<div className="col-md-4 order-1 order-md-2 align-self-center">

					<ul className="breadcrumb d-block text-md-right">

						<li><a href="/">Home</a></li>

						<li><a href="/services">Services</a></li>

						<li className="active">Document Archival</li>

					</ul>

				</div>

			</div>

		</div>

	</section>



	<section className="section bg-color-grey section-height-3 border-0 mt-5 mb-0">

		<div className="container">



			<div className="row">

				<div className="col">



					<div className="row align-items-center pt-4 appear-animation" data-appear-animation="fadeInLeftShorter">

						<div className="col-md-4 mb-4 mb-md-0">

							<img className="img-fluid scale-2 pr-5 pr-md-0 my-4" src="/j2img/services_archival.png" alt="layout styles" />

						</div>

						<div className="col-md-8 pl-md-5">

							<h2 className="font-weight-normal text-6 mb-3"><strong className="font-weight-extra-bold">Archive</strong> your old documents</h2>

							<p className="text-4">High speed scanning and labeling of your old document archives</p>

							<p>Do you have a room of old disintergrating documents? Is it impossible to find the sheets you need when you need them? Call us today to find out more about our comprehensive document archival services. You'll get high quality scanned documents that you can view with the click of a mouse! Our custom online Plan rooms make sharing and viewing your archived documents as easy as possible.</p>
							<p>Get more information about our private planroom services <a href="/services/bid/plancenter">here!</a></p>
						</div>

					</div>

					<div className="row align-items-center pt-4 appear-animation" data-appear-animation="fadeInRightShorter">

					

						<div className="col-md-8 pl-md-5">

							<h2 className="font-weight-normal text-6 mb-3"><strong className="font-weight-extra-bold">Scan</strong> your artwork</h2>

							<p className="text-4">Contactless high resolution scanning for artwork</p>

							<p>Our contactless scanner will create reproduction ready scans of your artwork (up to 36"x60"). We can scan your delicate items without ever placing them on glass or through dangerous feed rollers. Your art remains safe and you get the highest quality scans possible.</p>
							<p>Learn more about our contactless artwork scanner <a href="/services/scans/art">here!</a></p>
						</div>
						<div className="col-md-4 mb-4 mb-md-0">

						<img className="img-fluid scale-2 pr-5 pr-md-0 my-4" src="/j2img/services_scanart.png" alt="layout styles" />

						</div>

					</div>

				</div>

			</div>

		</div>

	</section>		

		<ContactForm email="help" subject="Contact Form [ Scan Services Page ]"/>

	

		






</div>
		  
		  
		  
		  <Footer/>
		  </div>
	  )
  }
}