import React, { Component } from 'react';

import Header from '../header/header.js';
import Footer from '../footer/footer.js';

import ContactForm from './contactform.js';
import  {Helmet} from "react-helmet";
export default class ExpeditedPickupRequest extends Component {

  render() {
	  return (
		  <div>
			  <Helmet>
					<title>J2 Blueprint Supply Co. | Epedited Pickup Request</title>	
					<meta name="description" content="Submit an expedited pickup request" />
                </Helmet>
		  <Header page_supplies={true}/>
		  
				<section className="page-header page-header-modern bg-color-light-scale-1 page-header-md">

					<div className="container">

						<div className="row">





							<div className="col-md-8 order-2 order-md-1 align-self-center p-static">

								<h1 className="text-dark">Expedited <strong>Pickup Request</strong></h1>

<span className="sub-title text-dark"><p>Pickup Request Form</p></span>

							</div>





							<div className="col-md-4 order-1 order-md-2 align-self-center">

								<ul className="breadcrumb d-block text-md-right">

									<li><a href="/">Home</a></li>

									<li className="active">Supplies</li>

								</ul>

							</div>

						</div>

					</div>

				</section>



				
		  
		  	<ContactForm expeditedpickup={true} email="xreq" subject="Contact Form [ Expidted Pickup ]"/>
				
		  <Footer/>
		  </div>
	  )
  }
}