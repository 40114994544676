import React, { Component } from 'react';

import { FaPhoneSquare } from 'react-icons/fa';
import { FaPhone } from 'react-icons/fa';
import { FaFax } from 'react-icons/fa';
import { FaGlobe } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';
import { FaClock } from 'react-icons/fa';
import { FaAngleRight } from 'react-icons/fa';

export default class Footer extends Component {

	showCurrentYear() {
		return new Date().getFullYear();
	}
  render() {
	  return (
			<footer id="footer" className="border-0">

				<div className="container py-5">

					<div className="row justify-content-md-center py-4 mt-3">

						<div className="col-md-12 col-lg-3 d-flex  mb-5 mb-lg-0">
						<ul className="list list-icons list-icons-lg">
							<li>	{process.env.REACT_APP_SITE_CO == "j2b" && 
								<a href="/"><img src="/img/j2logo_opt.png" alt="Logo" className="img-fluid" style={{maxWidth: '175px'}}/></a>
	  }
							</li><li>
						
	{process.env.REACT_APP_SITE_CO == "j2bsys" && <>
	<h2>Print services Provided by:</h2>
	<a href="https://www.j2b.com/"><img src="/img/j2logo_opt.png" alt="j2 blueprint" className="img-fluid" style={{maxWidth: '175px'}}/></a> 
	</>}
								</li>
						</ul>
						</div>

						<div className="col-md-8 col-lg-5">

							<h5 className="text-3 mb-3">Navigation</h5>

							<div className="row">

								<div className="col-6 col-md-4 mb-5 mb-lg-0">
	  								  {process.env.REACT_APP_SITE_CO == "j2b" && 
									<ul className="list list-icons list-icons-sm">

										<li><FaAngleRight className="fa-selector"/><a href="/equipment" className="link-hover-style-1 ml-1"> Equipment</a></li>

										<li><FaAngleRight className="fa-selector"/><a href="/supplies" className="link-hover-style-1 ml-1"> Supplies</a></li>

										<li><FaAngleRight className="fa-selector"/><a href="/services" className="link-hover-style-1 ml-1"> Services</a></li>
										<li><FaAngleRight className="fa-selector"/><a href="/documents/J2CreditApplication.pdf" className="link-hover-style-1 ml-1"> J2 Credit Application</a></li>

									</ul>
  									}
									{process.env.REACT_APP_SITE_CO == "j2bsys" && 
									<ul className="list list-icons list-icons-sm">

									<li><FaAngleRight className="fa-selector"/><a href="/equipment" className="link-hover-style-1 ml-1"> Equipment</a></li>

									

									<li><FaAngleRight className="fa-selector"/><a href="https://www.j2b.com" className="link-hover-style-1 ml-1"> Print Services</a></li>

									</ul>
								    }
								</div>

								<div className="col-6 col-md-4 mb-5 mb-lg-0">

									<ul className="list list-icons list-icons-sm">

										<li><FaAngleRight className="fa-selector"/><a href="/repair" className="link-hover-style-1 ml-1"> Equipment Repair</a></li>

										<li><FaAngleRight className="fa-selector"/><a href="/support" className="link-hover-style-1 ml-1"> Support</a></li>
										{process.env.REACT_APP_SITE_CO == "j2b" &&
										<li><FaAngleRight className="fa-selector"/><a target="_new" href="https://uploadjob.j2b.com/upload/" className="link-hover-style-1 ml-1"> Send your Files</a></li>
										}
									</ul>

								</div>

								<div className="col-6 col-md-4 mb-5 mb-lg-0">

									<ul className="list list-icons list-icons-sm">

										

										

									</ul>

								</div>

							</div>

						</div>

						<div className="col-md-4 col-lg-3 mb-4 mb-lg-0">

							<h5 className="text-3 mb-3 pb-1">CONTACT US</h5>

							<p className="text-8 text-color-light font-weight-bold mb-2">1-800-464-0069</p>

							<ul className="list list-icons list-icons-lg">
		  						<li className="mb-1"><FaPhoneSquare className="fa-selector"/><p className="m-0"> Oregon: 1-503-241-2361</p></li>
		  						<li className="mb-1"><FaPhone className="fa-selector"/> <p className="m-0"> Washington: 1-360-696-1861</p></li>
		  						<li className="mb-1"><FaFax className="fa-selector"/><p className="m-0">Fax 1-360-571-9131</p></li>
		 	 					<li className="mb-1"><FaGlobe className="fa-selector"/> <a href="https://g.page/j2blue?share" className="m-0"> 8100 Ne St Johns Rd Suite B101<br/>Vancouver, WA 98665</a></li>
								<li className="mb-1"><FaClock className="fa-selector"/><p className="m-0"> Hours: Monday - Friday<br/>8am-5pm</p></li>
								{process.env.REACT_APP_SITE_CO == "j2b" && 
								<li className="mb-1"><FaEnvelope className="fa-selector"/> <p className="m-0"><a href="mailto:help@j2b.com">help@j2b.com</a></p></li>
								}
									{process.env.REACT_APP_SITE_CO == "j2bsys" &&
									<li className="mb-1"><FaEnvelope className="fa-selector"/> <p className="m-0"><a href="mailto:help@j2bsys.com">help@j2bsys.com</a></p></li>
							
							}

							</ul>

						</div>

					</div>

				</div>

				<div className="footer-copyright footer-copyright-style-2 background-transparent footer-top-light-border">

					<div className="container py-2">

						<div className="row py-4">

							<div className="col d-flex align-items-center justify-content-center mb-4 mb-lg-0">

								<p className="font-weight-semibold text-3">© Copyright {this.showCurrentYear()}{process.env.REACT_APP_SITE_CO == "j2b" &&  <> J2 Blueprint Supply Co. </> }{process.env.REACT_APP_SITE_CO == "j2bsys" && <> J2 Business Systems</> } All Rights Reserved.</p>

							</div>

						</div>

					</div>

				</div>

			</footer>
	  )
  }
}