import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import {hydrate, render} from "react-dom";

//import { render } from 'react-snapshot';
import './index.css';
//import App from './App';
import * as serviceWorker from './serviceWorker';


import { BrowserRouter as Router, Route,Switch,useLocation } from "react-router-dom";



/*
const Homepage = React.lazy(()=>import( './components/homepage/homepage.js'));
const Equipment = React.lazy(()=>import( './components/equipment/equipment.js'));
const EquipmentView = React.lazy(()=>import( './components/equipment/equipmentView.js'));
const EquipmentList = React.lazy(()=>import( './components/equipment/equipmentList.js'));
const Supplies = React.lazy(()=>import( './components/supplies/supplies.js'));
const Services = React.lazy(()=>import( './components/services/services.js'));
const ServicesPrintFineArt = React.lazy(()=>import( './components/services/print/fineart.js'));
const ServicesPrintSigns = React.lazy(()=>import( './components/services/print/signs.js'));
const ServicesPrintBusiness = React.lazy(()=>import( './components/services/print/business.js'));
const ServicesPrintSmallFormat = React.lazy(()=>import( './components/services/print/smallformat.js'));
const ServicesPrintEngineering = React.lazy(()=>import( './components/services/print/engineering.js'));
const ServicesPrintLamination = React.lazy(()=>import( './components/services/print/lamination.js'));
const ServicesScans = React.lazy(()=>import( './components/services/scans/scans.js'));
const ServicesScansArt = React.lazy(()=>import( './components/services/scans/artwork.js'));
const Repair = React.lazy(()=>import( './components/repair/repair.js'));
const RepairServiceCall = React.lazy(()=>import( './components/repair/servicecall.js'));
const Support = React.lazy(()=>import( './components/support/support.js'));
const Quote = React.lazy(()=>import( './components/support/quote.js'));
const Callme = React.lazy(()=>import( './components/support/callme.js'));
const CovidSigns = React.lazy(()=>import( './components/covid/covid.js'));
const Account = React.lazy(()=>import( './components/account/account.js'));
const HP = React.lazy(()=>import( './components/hp/hp.js'));
const HPDesign = React.lazy(()=>import( './components/services/print/design.js'));
const Lease = React.lazy(()=>import( './components/lease/lease.js'));
const BidsetDistribution = React.lazy(()=>import( './components/bid/distribution.js'));
const Einfo = React.lazy(()=>import( './components/einfo/einfo.js'));
const NoMatch = React.lazy(()=>import( './components/404/404.js'));
const QuickSupplyOrder = React.lazy(()=>import( './components/support/quicksupply.js'));
const ExpeditedPickupRequest = React.lazy(()=>import( './components/support/expeditedpickup'));
const ServicesBidPlancenter = React.lazy(()=>import( './components/services/bid/plancenter.js'));
const ServicesBidDistribution = React.lazy(()=>import( './components/services/bid/distribution.js'));
const LegacyUpload = React.lazy(()=>import( './components/legacy/upload.js'));
const LegacyGetJob = React.lazy(()=>import( './components/legacy/getJob.js'));
const LegacyGetPrintJobs = React.lazy(()=>import( './components/legacy/getPrintJobs.js'));
const FileSizeDPI = React.lazy(()=>import( './components/services/print/filesizedpi.js'));
const Template = React.lazy(()=>import( './components/library/template.js'));
const Library = React.lazy(()=>import( './components/library/library.js'));

*/



import Homepage from './components/homepage/homepage.js';

import J2BSysHomepage from './components/homepage/j2bsyshome.js';
import Equipment from './components/equipment/equipment.js';
import EquipmentView from './components/equipment/equipmentView.js';
import EquipmentList from './components/equipment/equipmentList.js';
import Supplies from './components/supplies/supplies.js';
import Services from './components/services/services.js';
import ServicesPrintFineArt from './components/services/print/fineart.js';
import ServicesPrintSigns from './components/services/print/signs.js';
import ServicesPrintBusiness from './components/services/print/business.js';
import ServicesPrintSmallFormat from './components/services/print/smallformat.js';
import ServicesPrintEngineering from './components/services/print/engineering.js';
import ServicesPrintLamination from './components/services/print/lamination.js';
import ServicesScans from './components/services/scans/scans.js';
import ServicesScansArt from './components/services/scans/artwork.js';
import Repair from './components/repair/repair.js';
import RepairServiceCall from './components/repair/servicecall.js';
import Support from './components/support/support.js';
import Quote from './components/support/quote.js';
import Callme from './components/support/callme.js';
import CovidSigns from './components/covid/covid.js';
import Account from './components/account/account.js';
import HP from './components/hp/hp.js';
import HPDesign from './components/services/print/design.js';
import Lease from './components/lease/lease.js';
import BidsetDistribution from './components/bid/distribution.js';
import Einfo from './components/einfo/einfo.js';
import NoMatch from './components/404/404.js';
import QuickSupplyOrder from './components/support/quicksupply.js';
import ExpeditedPickupRequest from './components/support/expeditedpickup';
import ServicesBidPlancenter from './components/services/bid/plancenter.js';
import ServicesBidDistribution from './components/services/bid/distribution.js';
import LegacyUpload from './components/legacy/upload.js';
import LegacyGetJob from './components/legacy/getJob.js';
import LegacyGetPrintJobs from './components/legacy/getPrintJobs.js';

import FileSizeDPI from './components/services/print/filesizedpi.js';
import Template from './components/library/template.js';
import Library from './components/library/library.js';
import Syndicate from './components/hpsyn/syndicate.js';

import About from './components/about/about.js';


//if (process.env.REACT_APP_SITE_CO == "j2b" )
const RealHomepage = (process.env.REACT_APP_SITE_CO == "j2b" ) ? Homepage : J2BSysHomepage;

//import Keys from './config/keys.js';

//import Core from './components/core.js';

		
		

//const uuidv4 = require('uuid/v4');

//console.log(Keys.getKey());

//var session_id = localStorage.getItem("session_id");
//console.log("Read session_id: " + session_id);
//if (!session_id)
//{/
//	session_id = uuidv4();
//	console.log("Setting session_id: " + session_id);
//	localStorage.setItem("session_id",session_id);
//	
//}

const uuidv4 = require('uuid/v4');

//console.log(Keys.getKey());

var session_id = localStorage.getItem("session_id");
console.log("Read session_id: " + session_id);
if (!session_id)
{
	session_id = uuidv4();
	console.log("Setting session_id: " + session_id);
	localStorage.setItem("session_id",session_id);
	
}




//<Route exact path="/uploadn" component={() => <Upload session_id={session_id} />} />
//<Route exact path="/plotn" component={() => <Upload session_id={session_id} />} />




const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
	hydrate(
	//	<React.Suspense fallback={<span>Loading...</span>}>
		<Router>
		<div>
			<Switch>
			<Route exact path="/" component={RealHomepage} />

			<Route exact path="/bidsets" component={BidsetDistribution} />
			<Route exact path="/bidset" component={BidsetDistribution} />
			<Route exact path="/bid/distribution" component={BidsetDistribution} />

			<Route exact path="/einfo" component={Einfo} />

			<Route exact path="/equipment" component={Equipment} />
			<Route exact  path="/equipment/mfp" component={()=><EquipmentList cat="mfp" name="MFP Devices" callout="Multi Function Printing Devices"/>}  />
			<Route exact path="/equipment/mfp/:id" component={(props) => <EquipmentView cat="mfp" id={props.match.params.id}/>} />
			
			<Route exact path="/equipment/wide" component={()=><EquipmentList cat="wide" name="Wide Format Multi Function" callout="Wide format Printer, Scanner Copiers"/>}  />
			<Route exact path="/equipment/wide/:id" component={(props) => <EquipmentView cat="wide" id={props.match.params.id}/>} />
			<Route exact path="/equipment/graphics" component={()=><EquipmentList cat="graphics" name="Wide Format Graphics" callout="Wide format graphics printers for your fine arts needs"/>} />
			<Route exact path="/equipment/graphics/:id" component={(props) => <EquipmentView cat="graphics" id={props.match.params.id}/>} />
			

			<Route exact path="/equipment/cad" component={()=><EquipmentList cat="cad" name="Wide Format CAD" callout="Wide format CAD printers"/>} />
			<Route exact path="/equipment/cad/:id" component={(props) => <EquipmentView cat="cad" id={props.match.params.id}/>} />
			
			<Route exact path="/equipment/scanners" component={()=><EquipmentList cat="scanners" name="Scanners" callout="Scanners"/>} />
			<Route exact path="/equipment/scanners/:id" component={(props) => <EquipmentView cat="scanners" id={props.match.params.id}/>} />
			
			<Route exact path="/supplies" component={Supplies} />
			<Route exact path="/supply" component={Supplies} />
			<Route exact path="/services" component={Services} />
			<Route exact path="/services/print/fineart" component={ServicesPrintFineArt} />
			<Route exact path="/services/print/engineering" component={ServicesPrintEngineering} />
			<Route exact path="/services/print/signs" component={ServicesPrintSigns} />
			<Route exact path="/services/print/business" component={ServicesPrintBusiness} />
			<Route exact path="/services/print/smallformat" component={ServicesPrintSmallFormat} />
			<Route exact path="/services/print/lamination" component={ServicesPrintLamination} />
			<Route exact path="/services/scans" component={ServicesScans} />
			<Route exact path="/services/scans/art" component={ServicesScansArt} />

			
			<Route exact path="/repair/servicecall" component={RepairServiceCall} />
			<Route exact path="/repair" component={Repair} />
			<Route exact path="/repairbeta" component={(props)=> <RepairServiceCall testmode={true}/>} />
		
			<Route exact path="/equipment-service/place-a-service-call.html" component={RepairServiceCall} />
			<Route exact path="/online-services/place-a-service-call.html" component={RepairServiceCall} />

			<Route exact path="/service/repair" component={RepairServiceCall} />

			<Route exact path="/-online-services/quick-supply-order.html" component={QuickSupplyOrder} />
			<Route exact path="/online-services/quick-supply-order.html" component={QuickSupplyOrder} />
			<Route exact path="/quicksupply" component={QuickSupplyOrder} />

			<Route exact path="/online-services/pickup-request.html" component={ExpeditedPickupRequest} />
			<Route exact path="/pickup" component={ExpeditedPickupRequest} />
			<Route exact path="/support" component={Support} />
			<Route exact path="/account" component={Account} />
			<Route exact path="/hpshow" component={HP} />
			<Route exact path="/hp" component={(props) => <Syndicate ></Syndicate>} />
			<Route exact path="/hp/mps" component={(props) => <Syndicate syndication="mps"></Syndicate>} />
			<Route exact path="/hp/printersandscanners" component={(props) => <Syndicate  syndication="printersandscanners"></Syndicate>} />
			<Route exact path="/about" component={About} />
			<Route exact path="/lease" component={Lease} />
			
		
			<Route exact path="/quote" component={Quote} />
			<Route exact path="/callme" component={Callme} />
			<Route exact path="/covid" component={CovidSigns} />
			<Route exact path="/design" component={HPDesign} />
			<Route exact path="/library" component={Library} />
			<Route exact path="/library/template" component={Library} />
			<Route exact path="/library/template/:id" component={(props) => <Template id={props.match.params.id}/>} />
			

			<Route exact path="/services/bid/plancenter" component={ServicesBidPlancenter} />
			<Route exact path="/services/bid/distribution" component={ServicesBidDistribution} />
			
			<Route exact path="/filesize-dpi" component={FileSizeDPI} />

			<Route exact path="/uploadR" component={() => { 
    				 window.location.href = 'https://uploadjob.j2b.com/upload/'; 
    				 return null;
			}} />
			<Route exact path="/plotR" component={() => { 
    				 window.location.href = 'https://uploadjob.j2b.com/upload/'; 
    				 return null;
			}} />
		
			<Route path='/legacy-upload' component={() => { 
    				 window.location.href = 'https://uploadjob.j2b.com/upload/'; 
    				 return null;
			}}/>

			<Route exact path="/online-services/upload-a-print-job.htmlR" component={() => { 
    				 window.location.href = 'https://uploadjob.j2b.com/upload/'; 
    				 return null;
			}} />



			
		<Route exact path="/getPrintJobs" component={() => { var query = useLocation(); return(<LegacyGetPrintJobs query={query.search}/>)}}/>
		<Route exact path="/getJob" component={() => { var query = useLocation(); return(<LegacyGetJob query={query.search}/>)}}/>
		<Route exact path="/upload" component={() => { 
    				 window.location.href = 'https://uploadjob.j2b.com/upload/'; 
    				 return null;
			}}/>
		<Route exact path="/plot" component={() => { var query = useLocation(); return(<LegacyUpload query={query.search}/>)}}/>
		<Route exact path="/online-services/upload-a-print-job.html" component={() => { 
    				 window.location.href = 'https://uploadjob.j2b.com/upload/'; 
    				 return null;
			}}/>
		
		

			<Route exact path="/getPrintJobsR" component={({match}) => { 

				var query = useLocation();
		
    				 window.location.href = 'https://uploadjob.j2b.com/getPrintJobs/'+query.search; 
    				 return null;
			}} />
			<Route exact path="/getJobR" component={({match}) => { 

				var query = useLocation();

					window.location.href = 'https://uploadjob.j2b.com/getJob/'+query.search; 
					return null;
			}} />
			
			<Route component={NoMatch} />
			</Switch>
		</div>
	</Router>
//	</React.Suspense>

		,rootElement
	)
} else {
	render(
	//	<React.Suspense fallback={<span>Loading...</span>}>
		<Router>
		<div>
			<Switch>
			<Route exact path="/" component={RealHomepage} />

			<Route exact path="/bidsets" component={BidsetDistribution} />
			<Route exact path="/bidset" component={BidsetDistribution} />
			<Route exact path="/bid/distribution" component={BidsetDistribution} />

			<Route exact path="/einfo" component={Einfo} />

			<Route exact path="/equipment" component={Equipment} />
			<Route exact  path="/equipment/mfp" component={()=><EquipmentList cat="mfp" name="MFP Devices" callout="Multi Function Printing Devices"/>}  />
			<Route exact path="/equipment/mfp/:id" component={(props) => <EquipmentView cat="mfp" id={props.match.params.id}/>} />
			<Route exact  path="/equipment/wide" component={()=><EquipmentList cat="wide" name="Wide Format Multi Function" callout="Wide format Printer, Scanner Copiers"/>}  />
					
			<Route exact path="/equipment/wide/:id" component={(props) => <EquipmentView cat="wide" id={props.match.params.id}/>} />
			<Route exact  path="/equipment/graphics" component={()=><EquipmentList cat="graphics" name="Wide Format Graphics" callout="Wide format graphics printers for your fine arts needs"/>} />
			<Route exact path="/equipment/graphics/:id" component={(props) => <EquipmentView cat="graphics" id={props.match.params.id}/>} />
			<Route exact  path="/equipment/cad" component={()=><EquipmentList cat="cad" name="Wide Format CAD" callout="Wide format CAD printers"/>} />
			<Route exact path="/equipment/cad/:id" component={(props) => <EquipmentView cat="cad" id={props.match.params.id}/>} />
			<Route exact  path="/equipment/scanners" component={()=><EquipmentList cat="scanners" name="Scanners" callout="Scanners"/>} />
			<Route exact path="/equipment/scanners/:id" component={(props) => <EquipmentView cat="scanners" id={props.match.params.id}/>} />
			
			<Route exact path="/supplies" component={Supplies} />
			<Route exact path="/supply" component={Supplies} />
			<Route exact path="/services" component={Services} />
			<Route exact path="/services/print/fineart" component={ServicesPrintFineArt} />
			<Route exact path="/services/print/engineering" component={ServicesPrintEngineering} />
			<Route exact path="/services/print/signs" component={ServicesPrintSigns} />
			<Route exact path="/services/print/business" component={ServicesPrintBusiness} />
			<Route exact path="/services/print/smallformat" component={ServicesPrintSmallFormat} />
			<Route exact path="/services/print/lamination" component={ServicesPrintLamination} />
			<Route exact path="/services/scans" component={ServicesScans} />
			<Route exact path="/services/scans/art" component={ServicesScansArt} />
		
			<Route exact path="/about" component={About} />
			
			<Route exact path="/repair/servicecall" component={RepairServiceCall} />
			<Route exact path="/repair" component={Repair} />
			<Route exact path="/repairbeta" component={(props)=> <RepairServiceCall testmode={true}/>} />
		
			<Route exact path="/equipment-service/place-a-service-call.html" component={RepairServiceCall} />
			<Route exact path="/online-services/place-a-service-call.html" component={RepairServiceCall} />

			<Route exact path="/service/repair" component={RepairServiceCall} />

			<Route exact path="/-online-services/quick-supply-order.html" component={QuickSupplyOrder} />
			<Route exact path="/online-services/quick-supply-order.html" component={QuickSupplyOrder} />
			<Route exact path="/quicksupply" component={QuickSupplyOrder} />

			<Route exact path="/online-services/pickup-request.html" component={ExpeditedPickupRequest} />
			<Route exact path="/pickup" component={ExpeditedPickupRequest} />
			<Route exact path="/support" component={Support} />
			<Route exact path="/account" component={Account} />
			<Route exact path="/hpshow" component={HP} />
			<Route exact path="/hp" component={(props) => <Syndicate ></Syndicate>} />
			<Route exact path="/hp/mps" component={(props) => <Syndicate syndication="mps"></Syndicate>} />
			<Route exact path="/hp/printersandscanners" component={(props) => <Syndicate  syndication="printersandscanners"></Syndicate>} />

			<Route exact path="/lease" component={Lease} />
			
		
			<Route exact path="/quote" component={Quote} />
			<Route exact path="/callme" component={Callme} />
			<Route exact path="/covid" component={CovidSigns} />
			<Route exact path="/design" component={HPDesign} />
			<Route exact path="/library" component={Library} />
			<Route exact path="/library/template" component={Library} />
			<Route exact path="/library/template/:id" component={(props) => <Template id={props.match.params.id}/>} />
			

			<Route exact path="/services/bid/plancenter" component={ServicesBidPlancenter} />
			<Route exact path="/services/bid/distribution" component={ServicesBidDistribution} />
			
			<Route exact path="/filesize-dpi" component={FileSizeDPI} />

			<Route exact path="/uploadR" component={() => { 
    				 window.location.href = 'https://uploadjob.j2b.com/upload/'; 
    				 return null;
			}} />
			<Route exact path="/plotR" component={() => { 
    				 window.location.href = 'https://uploadjob.j2b.com/upload/'; 
    				 return null;
			}} />
		
			<Route path='/legacy-upload' component={() => { 
    				 window.location.href = 'https://uploadjob.j2b.com/upload/'; 
    				 return null;
			}}/>

			<Route exact path="/online-services/upload-a-print-job.htmlR" component={() => { 
    				 window.location.href = 'https://uploadjob.j2b.com/upload/'; 
    				 return null;
			}} />



			
		<Route exact path="/getPrintJobs" component={() => { var query = useLocation(); return(<LegacyGetPrintJobs query={query.search}/>)}}/>
		<Route exact path="/getJob" component={() => { var query = useLocation(); return(<LegacyGetJob query={query.search}/>)}}/>
		<Route exact path="/upload" component={() => { 
    				 window.location.href = 'https://uploadjob.j2b.com/upload/'; 
    				 return null;
			}}/>
		<Route exact path="/plot" component={() => { var query = useLocation(); return(<LegacyUpload query={query.search}/>)}}/>
		<Route exact path="/online-services/upload-a-print-job.html" component={() => { 
    				 window.location.href = 'https://uploadjob.j2b.com/upload/'; 
    				 return null;
			}}/>
		
		

			<Route exact path="/getPrintJobsR" component={({match}) => { 

				var query = useLocation();
		
    				 window.location.href = 'https://uploadjob.j2b.com/getPrintJobs/'+query.search; 
    				 return null;
			}} />
			<Route exact path="/getJobR" component={({match}) => { 

				var query = useLocation();

					window.location.href = 'https://uploadjob.j2b.com/getJob/'+query.search; 
					return null;
			}} />
			
			<Route component={NoMatch} />
			</Switch>
		</div>
	</Router>
//	</React.Suspense>
		,rootElement
	)
}

//
//ReactDOM.render(
	//<React.Suspense fallback={<span>Loading...</span>}>

	//</React.Suspense>
	
//, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
