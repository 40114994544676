import React, { useEffect } from 'react';

import Header from '../header/header.js';
import Footer from '../footer/footer.js';
import ContactForm from '../support/contactform.js';

//import IframeResizer from 'iframe-resizer-react';
import  {Helmet} from "react-helmet";

import Popup from "reactjs-popup";




export default function CovidSigns() {

	

    useEffect(()=>{
        window.$('.image-hotspot')
		.append('<span class="ring"></span>')
        .append('<span class="circle"></span>');
        window.loadCommon();
    })
    


        return(
            <div>
                 <Helmet>
				<title>J2 Blueprint Supply Co. |  COVID-19 Signage for your business</title>	
			
				<meta name="description" content="COVID-19 Signage for your business" />
                </Helmet>
                <Header page_services={true}/>
            
              <div role="main" className="main">

              <section className="page-header page-header-modern scale-1 page-header-md" style={{backgroundColor:"rgb(154, 1, 7)"}}>

                <div className="container">

                    <div className="row">

                        <div className="col-md-8 order-2 order-md-1 align-self-center p-static">

                            <h1 className="text-light">Custom Corona Virus <strong>Signage</strong></h1>

                <span className="sub-title text-light">Select from our premade options or upload your own graphics for custom signage</span>

                        </div>

                        <div className="col-md-4 order-1 order-md-2 align-self-center">

                            <ul className="breadcrumb d-block text-md-right">

                                <li><a href="/" className="text-light">Home</a></li>

                                <li className="active text-light">COVID-19</li>

                            </ul>

                        </div>

                    </div>

                </div>

                </section>
            
				

              <div className="container py-4">

                    <div className="row py-4 mb-4">
                        <div className="col">
                           
                            <div className="image-hotspots">
                                <img src="/img/covidbanner.jpg" className="img-fluid" alt="Covid Banner"/>
                                <Popup
                               
                            trigger={ 
                                <span style={{left: "49%", top: "28%"}} className="image-hotspot image-hotspot-light" data-container="body" >
                                    <strong>1</strong>
                                </span>
                            }
                            position="top center"
                            closeOnDocumentClick>
                                <div className="text-center">
                                Wall posters and window clings to spread safety information.
                                <br/><br/>
                                <a className="btn btn-badge btn-modern btn-primary mb-2" href="#quote">Get a Quote</a>
                                </div>
                            </Popup>
                            <Popup
                             
                            trigger={ 
                                <span style={{left: "59%", top: "88%"}} className="image-hotspot image-hotspot-light" data-container="body" >
                                    <strong>3</strong>
                                </span>
                            }
                            position="top center"
                            closeOnDocumentClick>
                                <div className="text-center">
                                Help ensure your customers maintain social distancing with strategically placed floor graphics.
                                <br/><br/>
                                <a className="btn btn-badge btn-modern btn-primary mb-2" href="#quote">Get a Quote</a>
                                </div>
                            </Popup>
                            <Popup
                            
                            trigger={ 
                                <span style={{left: "57%", top: "51%"}} className="image-hotspot image-hotspot-light" data-container="body" >
                                    <strong>2</strong>
                                </span>
                    
                            }
                            position="top center"
                            closeOnDocumentClick>
                                <div className="text-center">
                                Keep your customers informed with counter top signs where they are sure to see them.
                                <br/><br/>
                                <a className="btn btn-badge btn-modern btn-primary mb-2" href="#quote">Get a Quote</a>
                                </div>
                            </Popup>
                            <Popup
                            
                            trigger={ <span style={{left: "77%", top: "26%"}} className="image-hotspot image-hotspot-light" data-container="body" >
                                   
                            <strong>4</strong>
                        </span>}
                            position="top center"
                            closeOnDocumentClick>
                                <div className="text-center">
                                Easy to reposition and captures your customers attention.
                                <br/><br/>
                                <a className="btn btn-badge btn-modern btn-primary mb-2" href="#quote">Get a Quote</a>
                                </div>
                            </Popup>
                            <Popup
                          
                            trigger={ <span style={{left: "94%", top: "46%"}} className="image-hotspot image-hotspot-light" data-container="body" >
                                   
                            <strong>5</strong>
                        </span>}
                            position="top center"
                            closeOnDocumentClick>
                                <div className="text-center">
                                Put reminders infront of your customers eyes to help ensure proper hygene.
                                <br/><br/>
                                <a className="btn btn-badge btn-modern btn-primary mb-2" href="#quote">Get a Quote</a>
							
                                </div>
                            </Popup>
                            </div>
                        </div>
                    </div>

                </div>
                </div> 
                
              <div className="container">
                <div className="row mt-5">
						<div className="col">

							<div className="row process my-5">
								<div className="process-step col-lg-4 mb-5 mb-lg-4 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">
									<div className="process-step-circle">
										<strong className="process-step-circle-content">1</strong>
									</div>
									<div className="process-step-content">
										<h4 className="mb-1 text-5 font-weight-bold">Wall Posters</h4>
										<p className="mb-0">Wall posters and window clings to spread safety information.</p>
                                        <br/>
                                        <a className="btn btn-badge btn-modern btn-primary mb-2" href="#quote">Get a Quote</a>
									</div>
								</div>
								<div className="process-step col-lg-4 mb-5 mb-lg-4 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400">
									<div className="process-step-circle">
										<strong className="process-step-circle-content">2</strong>
									</div>
									<div className="process-step-content">
										<h4 className="mb-1 text-5 font-weight-bold">Counter top Signs</h4>
										<p className="mb-0">Keep your customers informed with counter top signs where they are sure to see them.</p>
                                        <br/>
                                        <a className="btn btn-badge btn-modern btn-primary mb-2" href="#quote">Get a Quote</a>
                                    </div>
								</div>
								<div className="process-step col-lg-4 mb-5 mb-lg-4 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="600">
									<div className="process-step-circle">
										<strong className="process-step-circle-content">3</strong>
									</div>
									<div className="process-step-content">
										<h4 className="mb-1 text-5 font-weight-bold">Floor Graphics</h4>
										<p className="mb-0">Help ensure your customers maintain social distancing with strategically placed floor graphics.</p>
                                        <br/>
                                        <a className="btn btn-badge btn-modern btn-primary mb-2" href="#quote">Get a Quote</a>
                                    </div>
								</div>
							</div>

						</div>
					</div>
                </div>



                <div className="container">

                <div className="row">
                    <div className="col">
                    

                        <div className="row process my-5">
                            <div className="process-step col-lg-6 mb-5 mb-lg-4 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">
                                <div className="process-step-circle">
                                    <strong className="process-step-circle-content">4</strong>
                                </div>
                                <div className="process-step-content">
                                    <h4 className="mb-1 text-5 font-weight-bold">Popup Banners</h4>
                                    <p className="mb-0">Easy to reposition and captures your customers attention.</p>
                                    <br/>
                                    <a className="btn btn-badge btn-modern btn-primary mb-2" href="#quote">Get a Quote</a>
                                </div>
                            </div>
                            <div className="process-step col-lg-6 mb-5 mb-lg-4 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400">
                                <div className="process-step-circle">
                                    <strong className="process-step-circle-content">5</strong>
                                </div>
                                <div className="process-step-content">
                                    <h4 className="mb-1 text-5 font-weight-bold">Hygene Control Signs and Stickers</h4>
                                    <p className="mb-0">Put reminders infront of your customers eyes to help ensure proper hygene.</p>
                                    
                                    <br/>
                                    <a className="btn btn-badge btn-modern btn-primary mb-2" href="#quote">Get a Quote</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                
                </div>
                

                <div id="quote" style={{paddingTop:"100px"}}>
                <ContactForm email="help" subject={"Contact Form [ CovidSigns ]"} quote={true}/>
                </div>

               
            <Footer />
            </div>
        );
    

}