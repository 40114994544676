import React, { Component } from 'react';

import Header from '../header/header.js';
import Footer from '../footer/footer.js';
import ContactForm from '../support/contactform.js';
import  {Helmet} from "react-helmet";

export default class About extends Component {


  render() {
	  return (
		  <div>
			  <Helmet>
					<title>{`${ process.env.REACT_APP_NAME } |  About Us`} </title>	
					<meta name="description" content="Submit a support request with J2's Professionals." />
                </Helmet>
		  <Header page_about={true}/>

		  <div role="main" className="main">



<section className="page-header page-header-modern bg-color-dark page-header-md">

	<div className="container">

		<div className="row">

			<div className="col-md-8 order-2 order-md-1 align-self-center p-static">

				<h1 className=""><strong>About</strong> Us</h1>



			</div>





			<div className="col-md-4 order-1 order-md-2 align-self-center">

				<ul className="breadcrumb d-block text-md-right breadcrumb-light">

					<li><a href="/">Home</a></li>

					<li className="active">About Us</li>

				</ul>

			</div>

		</div>

	</div>

</section>
			<div className="container py-5 mt-3">
				<section className="call-to-action call-to-action-primary mb-5">
					<div className="col-sm-9 col-lg-9">
						<div className="call-to-action-content">
							<h3>Place a <strong>Service Call</strong> online.</h3>
							<p className="mb-0 opacity-7">Our <strong className="font-weight-extra-bold">Service Team</strong> will get back to you right away.</p>
						</div>
					</div>
					<div className="col-sm-3 col-lg-3">
						<div className="">
							<a href="/repair/servicecall" target="_blank" className="btn btn-modern text-2 btn-light border-0">Place a Service Call Now!</a>
						</div>
					</div>
				</section>
			
			</div>

        
        <div className="container py-2">
            <div className="row">

                <div className="col-lg-3">

                    <aside className="sidebar" id="sidebar" data-plugin-sticky data-plugin-options="{'minWidth': 991, 'containerSelector': '.container', 'padding': {'top': 110}}">



                        <h4 className="pt-2">Digital Workflow Solutions.</h4>

                        <p>J2 Business Systems offers personalized solutions for your print and document management needs.</p>



                        

                        

                    </aside>

                </div>

                <div className="col-lg-9">



                    <h2 className="font-weight-normal text-7 mb-2"><strong className="font-weight-extra-bold">Personalized</strong> solutions for you business.</h2>

                    <p className="lead">J2 Business Systems is the next evolution of office systems delivery.</p>

                    <img className="float-left img-fluid" width="300" height="211"  src="img/warehouse.jpg" alt="Warehouse" />
                     <p>J2 Business System draws from 32 years of experience as J2 Blueprint Supply taking care of the printing needs of the Architectural, Engendering, Construction, and Government. This brings a unique perspective on the office equipment world. In these industry segments a technician cannot simply schedule two weeks out and then run into a customer’s office and slap a part in and run out the door. If the customer is down, they are not making any money and neither are we.  We must take care of the cause of the problem not just the symptom. This fundamental difference in service strategy is what has made J2 stand apart from our competitors since 1988. J2 Business Systems expands on this legacy of excellence with expanded MPS offerings with robust product offerings from the best names in the industry. Hewlett Packard, Kyocera {/*, Canon as well as Canon Production Print Systems (formally known as Oce) */}. J2 Business Systems has a solution for you. </p>


                    <p>J2 stocks a wide range of printing supplies. Everything from Inks and Toners to Paper and Maintenence Kits. We offer all of these products at competetive prices and can provide lightning fast delivery anywhere in the PDX metro area.</p>

                    <div className="col-lg-12">
								<div className="slider-contact-form-wrapper bg-primary rounded p-5 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="1000" data-appear-animation-duration="1s">
									<div className="row">
										<div className="col text-center">
											<h2 className="font-weight-semi-bold text-color-light text-6 mb-2">Get a FREE on site analysis</h2>
											<p className="text-color-light opacity-7 font-weight-light mb-4 px-xl-5">Enter your personal info below and let’s start talking about your technology needs.</p>
										</div>
									</div>
									<div className="row">
										<div className="col">



										<ContactForm email="service" subject="J2B Sys Site Survey" nomessage={true} nosubject={true} basicform={true}/>
											
										</div>
									</div>
								</div>
							</div>

                   

                   



                    

                </div>

                </div>
                </div>



</div>



		  <Footer/>
		  </div>
	  )
  }
}