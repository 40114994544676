import React, { Component } from 'react';

import Header from '../header/header.js';
import Footer from '../footer/footer.js';

import ContactForm from '../support/contactform.js';
import  {Helmet} from "react-helmet";



var librarySet = [
    { 
      filename: "bodytempcheck",
      sizes: ["8.5x11","17x22","34x44"],
      description: "Body temperature Check",
      price: [5,10,20]
    
    },
    { 
        filename: "donotenterifsick",
        sizes: ["8.5x11","17x22","34x44"],
        description: "Body temperature Check",
        price: [5,10,20]
      
    },
    { 
        filename: "employeetempcheck",
        sizes: ["8.5x11","17x22","34x44"],
        description: "Body temperature Check",
        price: [5,10,20]
      
    },
    { 
        filename: "facemaskrequired",
        sizes: ["8.5x11","17x22","34x44"],
        description: "Body temperature Check",
        price: [5,10,20]
      
    },
    { 
        filename: "handsanitizerstation",
        sizes: ["8.5x11","17x22","34x44"],
        description: "Body temperature Check",
        price: [5,10,20]
      
    },
    { 
        filename: "maskrequired",
        sizes: ["8.5x11","17x22","34x44"],
        description: "Body temperature Check",
        price: [5,10,20]
      
    },
    { 
        filename: "newhours",
        sizes: ["8.5x11","17x22","34x44"],
        description: "Body temperature Check",
        price: [5,10,20]
      
    },
    { 
        filename: "opencurbside",
        sizes: ["8.5x11","17x22","34x44"],
        description: "Body temperature Check",
        price: [5,10,20]
      
    },
    { 
        filename: "socialdistancing",
        sizes: ["8.5x11","17x22","34x44"],
        description: "Body temperature Check",
        price: [5,10,20]
      
    },
    { 
        filename: "socialdistancing2",
        sizes: ["8.5x11","17x22","34x44"],
        description: "Body temperature Check",
        price: [5,10,20]
      
    },
    { 
        filename: "takeoutcurbside",
        sizes: ["8.5x11","17x22","34x44"],
        description: "Body temperature Check",
        price: [5,10,20]
      
    },
    { 
        filename: "takeoutonly",
        sizes: ["8.5x11","17x22","34x44"],
        description: "Body temperature Check",
        price: [5,10,20]
      
    },
    { 
        filename: "tempscreening",
        sizes: ["8.5x11","17x22","34x44"],
        description: "Body temperature Check",
        price: [5,10,20]
      
    },
    { 
        filename: "wewilldeliver",
        sizes: ["8.5x11","17x22","34x44"],
        description: "Body temperature Check",
        price: [5,10,20]
      
    }







]



export default class Library extends Component {

componentDidMount() {
    

}
renderTemplates() {
    var rows = "";
    var row="";

  //  console.log("Rendering " + this.state.machines[cat].length + " Machines.");
    if (librarySet.length ===0) {
          
                rows=(<div className="row mb-5 pb-3"><h1>No Templates Found</h1></div>)
    } else
    for(var i = librarySet.length-1; i >=0 ;i--) {
       // console.log(i);
        var item = librarySet[i];
        row = ( <>
        
            
            <div className="col-md-2 col-lg-2 mb-5 mb-lg-0 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200" >

                            <div className="card">
                            <a href={"/library/template/"+i} >
                                <img className="card-img-top" src={"/templates/" + item.filename+"_tn.jpg"} alt="Product Preview" />
                                </a>
                                <div className="card-body">
                                    
                                    
                                
                                    <a href={"/library/template/"+i} className="read-more text-color-primary font-weight-semibold text-2">Order Prints <i className="fas fa-angle-right position-relative top-1 ml-1"></i></a>
                                </div>
                            </div>
                        </div>
                        {row}
                </>
        )
        if (i%5===0) {
            
            rows = (<><div className="row mb-5 pb-3"> {row} </div>{rows}</>);
            row="";

        } 
    }
    return rows;
}
  render() {
      var rows = this.renderTemplates();
	  return (
		  <div>
              <Helmet>
					<title>J2 Blueprint Supply Co. | Print Template</title>	
					<meta name="description" content="Print template example" />
                </Helmet>


		  <Header page_services={true}/>
		  
          <div role="main" className="main" suppressHydrationWarning={true}>
          <section className="section section-text-light section-background section-center section-overlay-opacity section-overlay-opacity-scale-8" style={{backgroundImage: "url(/j2img/hpdesign/page/donutsNew.jpg"}}>
					<div className="container">
						<div className="row">
							<div className="col">
                        
								<h4 className="mb-0">Select a print from our Library</h4>
								<p className="mb-0">We've got a library of commonly used signs and posters for you to choose from</p>
							</div>
						</div>
					</div>
				</section>
          <section className="section section-center">

                <div className="container align-self-center">
                        {rows}
                </div>
            </section>
          
              <ContactForm email="help" subject="Contact Form [ Print Library ]"/>
                </div>
            <Footer />
        </div>
      )
    }

}