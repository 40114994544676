import React, { Component } from 'react';

import Header from '../../header/header.js';
import Footer from '../../footer/footer.js';
import ContactForm from '../../support/contactform.js';

import  {Helmet} from "react-helmet";
export default class ServicesScansArt extends Component {

  render() {
	  return (
		  <div>
			  <Helmet>
					<title>{`${ process.env.REACT_APP_NAME } | Artwork Scanning and Archival`} </title>	
					<meta name="description" content="Our museum grade contactless artwork scanner willl capture every detail of your artwork without ever touching it. " />
                </Helmet>
		  <Header page_services={true}/>
		  
<div role="main" className="main">

	<section className="page-header page-header-classic page-header-md">

		<div className="container">

			<div className="row">

				<div className="col-md-8 order-2 order-md-1 align-self-center p-static">

					<h1 data-title-border>Fine Art Scanning</h1>

				</div>

				<div className="col-md-4 order-1 order-md-2 align-self-center">

					<ul className="breadcrumb d-block text-md-right">

						<li><a href="/">Home</a></li>

						<li><a href="/services">Services</a></li>

						<li className="active">Artwork Scanning</li>

					</ul>

				</div>

			</div>

		</div>

	</section>



	<section className="section bg-color-grey section-height-3 border-0 mt-5 mb-0">

		<div className="container">



			<div className="row">

				<div className="col">



					<div className="row align-items-center pt-4 appear-animation" data-appear-animation="fadeInLeftShorter">

						<div className="col-md-4 mb-4 mb-md-0">

							<img className="img-fluid scale-2 pr-5 pr-md-0 my-4" src="/j2img/services_scanart.png" alt="layout styles" />

						</div>

						<div className="col-md-8 pl-md-5">

							<h2 className="font-weight-normal text-6 mb-3"><strong className="font-weight-extra-bold">Scan</strong> your artwork</h2>

							<p className="text-4">Contactless high resolution scanning for artwork</p>

							<p>Our contactless scanner will create reproduction ready scans of your artwork (up to 36"x60"). We can scan your delicate items without ever placing them on glass or through dangerous feed rollers. Your art remains safe and you get the highest quality scans possible.</p>

						</div>

					</div>

				</div>

			</div>
			<div class="row">
				<div class="col-lg-12 text-center">
					<h1>Example Scans</h1>
				</div>
			</div>
			<div class="row">
						<div class="col-lg-4">
							<div class="featured-box featured-box-primary border-radius-0 featured-box-effect-1">
								<div class="box-content box-content-border-bottom border-radius-0 p-5">
									
									<h4 class="font-weight-normal text-5 text-dark">Scan <strong class="font-weight-extra-bold">Flat</strong></h4>
									<img src="/img/scan/art_flat.jpg" alt="flat art" height="159"/>
									<p class="mb-0 mt-3"><a href="/img/scan/OilPaintingFlat.jpg" class="text-primary text-decoration-none">View Flat Scan <i class="fas fa-angle-right"></i></a></p>
								</div>
							</div>
						</div>
						<div class="col-lg-4">
							<div class="featured-box featured-box-primary border-radius-0 featured-box-effect-1">
								<div class="box-content border-radius-0 p-5">
									
									<h4 class="font-weight-normal text-5 text-dark">Scan with <strong class="font-weight-extra-bold">Light Texture</strong></h4>
									<img src="/img/scan/art_light.jpg" alt="light texture" height="159" />
									<p class="mb-0 mt-3"><a href="/img/scan/OilPaintingLightTexture.jpg" class="text-primary text-decoration-none">View Scan with Light Texture <i class="fas fa-angle-right"></i></a></p>
								</div>
							</div>
						</div>
						<div class="col-lg-4">
							<div class="featured-box featured-box-primary border-radius-0 featured-box-effect-1">
								<div class="box-content box-content-border-bottom border-radius-0 p-5">
									
									<h4 class="font-weight-normal text-5 text-dark">Scan with <strong class="font-weight-extra-bold">Strong Texture</strong></h4>
									<img src="/img/scan/art_strong.jpg" alt="strong texture"  height="159"/>
									<p class="mb-0 mt-3"><a href="/img/scan/OilPaintingStrongTexture.jpg" class="text-primary text-decoration-none">View Scan with Strong Texture<i class="fas fa-angle-right"></i></a></p>
								</div>
							</div>
						</div>
					</div>



					<div className="row">

						<div className="col">



							<div className="row align-items-center pt-4 appear-animation" data-appear-animation="fadeInLeftShorter">

								<div className="col-md-12 mb-12 mb-md-0">


											<p>Our new WideTEK® 36ART-600 was designed for archiving and reproduction of works of art. 
											</p>
											<p>
											Original artwork is moved under the CCD camera elements completely contact free, protecting the art from any damage and making it possible to scan a painting that is still wet, as long as it is framed or the sides are dry enough to place the artwork on the scanning bed.
											</p>
											<p>
											This scanner exceeds all criteria set forth in the FADGI *** guidelines and ISO 19264-1 level B.
											</p>
											<p>
											In cooperation with large format printers from HP, Canon and EPSON, the WideTEK® 36ART-600 fine art scanner brings the basis for professional art reproduction. Native printer drivers installed on the scanner make color accuracy on the resulting image even higher.
											</p>
									</div>
								</div>
						</div>
					</div>
		</div>

	</section>		

		<ContactForm email="help" subject="Contact Form [ Artwork Scanning Page ]"/>

	

		






</div>
		  
		  
		  
		  <Footer/>
		  </div>
	  )
  }
}