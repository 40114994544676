import React, { Component } from 'react';

import Header from '../header/header.js';
import Footer from '../footer/footer.js';
import ContactForm from '../support/contactform.js';

import  {Helmet} from "react-helmet";
import '../../public/vendor/simple-line-icons/css/simple-line-icons.min.css';

export default class Services extends Component {

  render() {
	  return (
		  <div>
			  <Helmet>
					<title>{`${ process.env.REACT_APP_NAME } | Print and Document Services`} </title>	
					<meta name="description" content="We've been in the print business for more than a quarter century. We know printing. Our talented team can help you get the output you deserve, whether its engineering documents or fine artwork reproduction, we've got you covered." />
                </Helmet>
		  <Header page_services={true}/>
		  
<div role="main" className="main">

	<section className="page-header page-header-classic page-header-md">

		<div className="container">

			<div className="row">

				<div className="col-md-8 order-2 order-md-1 align-self-center p-static">

					<h1 data-title-border>Services</h1>

				</div>

				<div className="col-md-4 order-1 order-md-2 align-self-center">

					<ul className="breadcrumb d-block text-md-right">

						<li><a href="/">Home</a></li>

						<li className="active">Services</li>

					</ul>

				</div>

			</div>

		</div>

	</section>



	<div className="container pb-1">



		<div className="row pt-4">

			<div className="col">

				<div className="overflow-hidden mb-3">

					<h2 className="font-weight-bold text-8 mb-0 appear-animation" data-appear-animation="maskUp">

						We Are Here To Help You

					</h2>

				</div>

			</div>

		</div>



		<div className="row">

			<div className="col-lg-10">

				<div className="overflow-hidden">

					<p className="lead mb-0 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="200">

						We've been in the print business for more than a quarter century. We <span className="alternative-font">know</span> printing. Our talented team can help you get the output you deserve, whether its engineering documents or fine artwork reproduction, we've got you covered.

					</p>

				</div>

			</div>

			<div className="col-lg-2 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="500">

				<a target="_new" href="https://uploadjob.j2b.com/upload/" className="btn btn-modern btn-dark mt-1">Send your Files!</a>

			</div>

		</div>



		<div className="row">

			<div className="col py-3">

				<hr className="solid mt-5 mb-2"/>

			</div>

		</div>



		<div className="row">

			<div className="featured-boxes featured-boxes-style-2">

				<div className="row">

					<div className="col-md-6 col-lg-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="700">

						<div className="featured-box featured-box-effect-4">

							<div className="box-content">

							<a href="/services/print/engineering/"><i className="icon-featured icon-screen-tablet icons text-color-primary bg-color-grey"></i></a>

								<h4 className="font-weight-bold">Engineering / Construction</h4>

								<p className="px-3">We can print your fullsized Plan Sets, Specifications and any other project documents you need for your construction or engineering project.</p>

							</div>

						</div>

					</div>

					<div className="col-md-6 col-lg-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="900">

						<div className="featured-box featured-box-effect-4">

							<div className="box-content">

							<a href="/services/print/business/"><i className="icon-featured icon-layers icons text-color-light bg-color-primary"></i></a>

								<h4 className="font-weight-bold">Business Documents</h4>

								<p className="px-3">Print and bind documents up to 11x17. Reports, Documentation, Manuals and more. With our digital press you'll get results that you can count on.</p>

							</div>

						</div>

					</div>

					<div className="col-md-6 col-lg-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="1100">

						<div className="featured-box featured-box-effect-4">

							<div className="box-content">

							<a href="/services/print/fineart/"><i className="icon-featured icon-magnifier icons text-color-primary bg-color-grey"></i></a>

								<h4 className="font-weight-bold">Fine Arts</h4>

								<p className="px-3">Stunningly vivid archival quality reproductions of your artwork. Ready for the art gallery or your wall.</p>

							</div>

						</div>

					</div>

					<div className="col-md-6 col-lg-4 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="1500">

						<div className="featured-box featured-box-effect-4">

							<div className="box-content">

							<a href="/services/print/signs/"><i className="icon-featured icon-screen-desktop icons text-color-light bg-color-primary"></i></a>

								<h4 className="font-weight-bold">Signs and Banners</h4>

								<p className="px-3">Outdoor and Indoor Signs and Banners for your business or organization. Full color printing on any flat material (up to 4x8' and 2" thick) - Paper, Wood, Acrylic, Metal etc.</p>

							</div>

						</div>

					</div>

					<div className="col-md-6 col-lg-4 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="1300">

						<div className="featured-box featured-box-effect-4">

							<div className="box-content">

							<a href="/services/print/smallformat/"><i className="icon-featured icon-doc icons text-color-primary bg-color-grey"></i></a>

								<h4 className="font-weight-bold">Cards, Flyers, Bochures</h4>

								<p className="px-3">Print your business cards, holiday cards and all kinds of bochures and flyers all professionally cut, folded and ready to impress.</p>

							</div>

						</div>

					</div>

					<div className="col-md-6 col-lg-4 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="1100">

						<div className="featured-box featured-box-effect-4">

							<div className="box-content">

							<a href="/services/scans/"><i className="icon-featured icon-menu icons text-color-light bg-color-primary"></i></a>

								<h4 className="font-weight-bold">Scanning &amp; Document Archival</h4>

								<p className="px-3">Scan artwork with our museum grade contactless scanner, or digitize your old planroom documents. We've got you covered.</p>

							</div>

						</div>

					</div>

				</div>

			</div>

		</div>

	</div>



	<section className="section bg-color-grey section-height-3 border-0 mt-5 mb-0">

		<div className="container">



			<div className="row">

				<div className="col">


				



					<div className="row align-items-center pt-5 pb-3 appear-animation" data-appear-animation="fadeInRightShorter">

						<div className="col-md-8 pr-md-5 mb-5 mb-md-0">

							<h2 className="font-weight-normal text-6 mb-3"><strong className="font-weight-extra-bold">Plans and Specifications</strong>, we know construction reprographics. </h2>

					<p className="text-4">We started printing construction documents back when blueprints we actually <b>blue</b>.</p>

							<p>No matter if you just need the M sheets or a complete set we can print your plan sets quickly and accurately. Spec</p>

						</div>

						<div className="col-md-4 px-5 px-md-3">

							<a href="/services/print/engineering/"><img className="img-fluid scale-2 my-4" src="/j2img/services_engineering.jpg" alt="style switcher" /></a>

						</div>

					</div>

					<hr className="solid my-5"/>

					<div className="row align-items-center pt-4 appear-animation" data-appear-animation="fadeInLeftShorter">

						<div className="col-md-4 mb-4 mb-md-0">

							<img className="img-fluid scale-2 pr-5 pr-md-0 my-4" src="/j2img/services_fineart.jpg" alt="layout styles" />

						</div>

						<div className="col-md-8 pl-md-5">

							<h2 className="font-weight-normal text-6 mb-3"><strong className="font-weight-extra-bold">Fine Art</strong> Scans &amp; Prints</h2>

							<p className="text-4">We can do museum quality contactless scans of your artwork and then produce archival quality glicee reproductions on a wide variety of print meida</p>

							

						</div>

					</div>



					<hr className="solid my-5"/>



					<div className="row align-items-center pt-5 pb-3 appear-animation" data-appear-animation="fadeInRightShorter">

						<div className="col-md-8 pr-md-5 mb-5 mb-md-0">

							<h2 className="font-weight-normal text-6 mb-3"><strong className="font-weight-extra-bold">Our Flatbed</strong> Prints on anything </h2>

		  <p className="text-4">We can print on any material thats flat and less than 2-inches thick, up to 8ft by 4ft</p>

							

						</div>

						<div className="col-md-4 px-5 px-md-3">

							<img className="img-fluid scale-2 my-4" src="/j2img/services_flatbed.png" alt="style switcher" />

						</div>

					</div>

				</div>

			</div>

		</div>

	</section>				



		<ContactForm email="help" subject="Contact Form [ Services Page ]"/>


</div>
		  
		  
		  
		  <Footer/>
		  </div>
	  )
  }
}