import React, { Component } from 'react';

import Header from '../header/header.js';
import Footer from '../footer/footer.js';

import ContactForm from '../support/contactform.js';


import  {Helmet} from "react-helmet";
export default class Supplies extends Component {
	componentDidMount() {
		setTimeout(() => {
			window.initCarousel();
			window.doCaro();
	
		}, 500);
	
	}
  render() {
	  return (
		  <div>
			  <Helmet>
					<title>J2 Blueprint Supply Co. | Supplies - ink, toner, paper</title>	
					<meta name="description" content="J2 Stocks a full line of ink, toner, paper, maintenence kits and more for all your printing needs." />
                </Helmet>
		  <Header page_supplies={true}/>
		  
		  
				<section className="page-header page-header-modern bg-color-light-scale-1 page-header-md">

					<div className="container">

						<div className="row">





							<div className="col-md-8 order-2 order-md-1 align-self-center p-static">

								<h1 className="text-dark">Keep on <strong>Printing</strong></h1>

<span className="sub-title text-dark">We carry all the inks, toners and print media you need to get the job done.</span>

							</div>





							<div className="col-md-4 order-1 order-md-2 align-self-center">

								<ul className="breadcrumb d-block text-md-right">

									<li><a href="/">Home</a></li>

									<li className="active">Supplies</li>

								</ul>

							</div>

						</div>

					</div>

				</section>

				<div className="container">
				<section className="call-to-action call-to-action-primary mb-5">
					<div className="col-sm-9 col-lg-9">
						<div className="call-to-action-content">
							<h3>Order with your <strong>NET 30</strong> account.</h3>
							<p className="mb-0 opacity-7">Login to your <strong class="font-weight-extra-bold">J2 Account</strong> and order supplies directly.</p>
						</div>
					</div>
					<div className="col-sm-3 col-lg-3">
						<div className="">
							<a href="/einfo" className="btn btn-modern text-2 btn-light border-0">Order Online Now!</a>
						</div>
					</div>
				</section>
				</div>

				<div className="container py-4">

				<div className="row text-center mt-5 pb-5 mb-5">
                        <div className="owl-carousel owl-theme carousel-center-active-item mb-0" data-plugin-options="{'responsive': {'0': {'items': 1, autoWidth:true}, '476': {'items': 1, autoWidth:true}, '768': {'items': 2, autoWidth:true}, '992': {'items': 2, autoWidth:true}, '1200': {'items': 2, autoWidth:true}}, 'autoplay': true, 'autoplayTimeout': 3000, 'dots': false}">
                            <div>
                                <img className="img-fluid" src="/img/logos/supplies/hp.png"  alt=""/>
                            </div>
                            {/*<div>
                                <img className="img-fluid" src="/img/logos/supplies/canon.png"  alt=""/>
							</div>*/}
                            <div>
                                <img className="img-fluid" src="/img/logos/supplies/kyocera.png"  alt=""/>
                            </div>
							<div>
                                <img className="img-fluid" src="/img/logos/supplies/oce.png"   alt=""/>
                            </div>
							<div>
                                <img className="img-fluid" src="/img/logos/supplies/rb.png"   alt=""/>
                            </div>
                            
                        </div>
                    </div>

					<div className="row py-3 justify-content-center">

						<div className="col-sm-8 col-md-4 mb-4 mb-md-0 appear-animation" data-appear-animation="fadeIn">

							<article>

								<div className="row">

									<div className="col">

										

											<img src="/j2img/supplies_ink.jpg" className="img-fluid hover-effect-2 mb-3" alt="" />

									

									</div>

								</div>

								<div className="row">

									<div className="col">

										<h4 className="mb-0"><span className="text-2 text-uppercase font-weight-bold pt-2 d-block text-dark text-decoration-none">Ink</span></h4>

										<p className="mb-2 lead text-4">{/*Canon, HP and Oce Ink*/} HP Ink</p>

										<p className="text-2">We've got all the ink you need for your printers and plotters </p>

									</div>

								</div>

							</article>

						</div>

						<div className="col-sm-8 col-md-4 mb-4 mb-md-0 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="250">

							<article>

								<div className="row">

									<div className="col">

										

											<img src="/j2img/supplies_toner.jpg" className="img-fluid hover-effect-2 mb-3" alt="" />

										

									</div>

								</div>

								<div className="row">

									<div className="col">

										<h4 className="mb-0"><a href="/supplies" className="text-2 text-uppercase font-weight-bold pt-2 d-block text-dark text-decoration-none">Toner</a></h4>

										<p className="mb-2 lead text-4">Toner Cartidges</p>

										<p className="text-2">Long lasting and cost effective OEM toner cartridges</p>

									</div>

								</div>

							</article>

						</div>

						<div className="col-sm-8 col-md-4 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="500">

							<article>

								<div className="row">

									<div className="col">

										

											<img src="/j2img/supplies_media.jpg" className="img-fluid hover-effect-2 mb-3" alt="" />

										
									</div>

								</div>

								<div className="row">

									<div className="col">

										<h4 className="mb-0"><a href="/supplies" className="text-2 text-uppercase font-weight-bold pt-2 d-block text-dark text-decoration-none">Print Media</a></h4>

										<p className="mb-2 lead text-4">All the print medias you need</p>

										<p className="text-2">From cases of 20lb bond to rolls semi-gloss photo paper. We've got the paper you need at the right price.</p>

									</div>

								</div>

							</article>

						</div>

					</div>



				</div>
		  
				<div className="container py-2">



<div className="row">

	<div className="col-lg-3">

		<aside className="sidebar" id="sidebar" data-plugin-sticky data-plugin-options="{'minWidth': 991, 'containerSelector': '.container', 'padding': {'top': 110}}">



			<h4 className="pt-2">Paper, Ink, Toner and more!</h4>

			<p>J2 stocks a wide range of printing supplies in our local warehouse. We also maintain relationships with regional and national suppliers and can order whatever you need at competitive prices.</p>



			<a className="btn btn-modern btn-primary mb-4" href="/support">Contact Us Now!</a>

			

		</aside>

	</div>

	<div className="col-lg-9">



		<h2 className="font-weight-normal text-7 mb-2"><strong className="font-weight-extra-bold">Supplies</strong> for all of your printing needs.</h2>

		<p className="lead">J2 has been a regional leader in the print industry for over 25 years. A key driver of success in our production printing and fleet service operations is our fully stocked warehouse of printing supplies. We are proud to be able to offer you these high quality products at competative prices with extremely fast local delivery. Chances are if you need it we either have it in stock or can get it for you immediately.</p>



		<img className="float-left img-fluid" width="300" height="211"  src="img/warehouse.jpg" alt="Warehouse" />



		<p>J2 stocks a wide range of printing supplies. Everything from Inks and Toners to Paper and Maintenence Kits. We offer all of these products at competetive prices and can provide lightning fast delivery anywhere in the PDX metro area.</p>



		<p>Our in house delivery drivers are always ready to pickup the originals you need copied, deliver your prints and bring your print supplies directly to your door.</p>



		<p>Quick turn arounds are our specialty. If you find your self out of paper or ink and need to get your prints made we can dispatch our delivery drivers driectly to you with the goods you need. Just fill out our 'Quick Supply Order' form <a href="/quicksupply">here</a>.</p>



		<div className="sticky-container">

			<div className="row">

				<div className="col-lg-8">

					<p>We only stock the highest quality OEM products. As a servicing dealer of printing equipment we know how much the quality of your printing supplies can impact the longevity and performances of your printers. </p>



					<p>In addition to providing all the supplies you need we also offer service supply agreements for all of the equipment we sell. With our comprehensive contracts you'll never have to worry about running out of paper, ink, or what to do if your printer stops working as expected.</p>

				</div>

				<div className="col-lg-4">

					<div className="text-center" data-plugin-sticky data-plugin-options="{'minWidth': 991, 'containerSelector': '.sticky-container', 'padding': {'top': 110}}">

						<img className="float-right img-fluid" width="300" height="211" src="img/rolls.jpg" alt="" />

						<a className="btn btn-modern btn-primary mb-4" href="/quote">Request a Quote!</a>


					</div>

				</div>

			</div>

		</div>



		

	</div>

</div>

</div>
			
		  	<ContactForm email="sales" subject="Contact Form [ Supplies ]"/>
				
		  <Footer/>
		  </div>
	  )
  }
}