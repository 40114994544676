import React, { Component } from 'react';

import Header from '../header/header.js';
import Footer from '../footer/footer.js';
import ContactForm from '../support/contactform.js';
import  {Helmet} from "react-helmet";

import postscribe from 'postscribe';


//import IframeResizer from 'iframe-resizer-react';


export default class Syndicate extends Component {

	constructor(props) {
		super(props);
		this.state = {
					  machines:[],
					  myExternalLib:null
					 }

		this.handleScriptInject = this.handleScriptInject.bind(this);
	}
	componentDidMount() {
		setTimeout(() => {
			window.initCarousel();
			window.doCaro();

		

		
	
		}, 500);
		var desc = '//syndication.inc.hp.com/inpage/contentid.min.js#Yz1zaG93Y2FzZS91cy9lbi9hMy1tYW5hZ2VkJm09TkEmY2M9dXMmcD0zLUhTRTQtOTIyMg';
		if (this.props.syndication ==="mps")
			desc = '//syndication.inc.hp.com/inpage/contentid.min.js#Yz1zaG93Y2FzZS91cy9lbi9hMy1tYW5hZ2VkJm09TkEmY2M9dXMmcD0zLUhTRTQtOTIyMg';
		else if (this.props.syndication === "printersandscanners")
			desc = '//syndication.inc.hp.com/inpage/contentid.min.js#Yz1zaG93Y2FzZS91cy9lbi9wcmludGVycy1zY2FubmVycyZtPU5BJmNjPXVzJnA9My1IU0U0LTkyMjI';
		else if (this.props.syndication == "pagewidemoneyback") {
			desc = '//syndication.inc.hp.com/inpage/contentid.min.js#Yz1zaG93Y2FzZS91cy9lbi9wYWdld2lkZS1tb25leS1iYWNrJm09TkEmY2M9dXMmcD0zLUhTRTQtOTIyMg';
		} else if (this.props.syndication == "hprefreshrecycle") {
			desc = '//syndication.inc.hp.com/inpage/contentid.min.js#Yz1zaG93Y2FzZS91cy9lbi9yZWZyZXNoLXJlY3ljbGUmbT1OQSZjYz11cyZwPTMtSFNFNC05MjIy';
		} else if (this.props.syndication == "brandshowcase") {
			desc = '//syndication.inc.hp.com/inpage/contentid.min.js#Yz1zaG93Y2FzZS91cy9lbiZtPU5BJmNjPXVzJnA9My1IU0U0LTkyMjI';
		} else if (this.props.syndication == "largeformat") {
			desc = '//syndication.inc.hp.com/inpage/contentid.min.js#Yz1zaG93Y2FzZS91cy9lbi9sYXJnZS1mb3JtYXQmbT1OQSZjYz11cyZwPTMtSFNFNC05MjIy';
		}

		postscribe('#postscribe', '<script src="'+desc+'"></script>');
	
	}

	handleScriptInject({ scriptTags }) {
        if (scriptTags) {
            const scriptTag = scriptTags[0];
            scriptTag.onload = () => {
                // I don't really like referencing window.
				//console.log(`Flexsis loaded!`, window.myExternalLib);
				var desc = '//syndication.inc.hp.com/inpage/contentid.min.js#Yz1zaG93Y2FzZS91cy9lbi9hMy1tYW5hZ2VkJm09TkEmY2M9dXMmcD0zLUhTRTQtOTIyMg';
				if (this.props.syndication ==="mps")
					desc = '//syndication.inc.hp.com/inpage/contentid.min.js#Yz1zaG93Y2FzZS91cy9lbi9hMy1tYW5hZ2VkJm09TkEmY2M9dXMmcD0zLUhTRTQtOTIyMg';
				else if (this.props.syndication === "printersandscanners")
					desc = '//syndication.inc.hp.com/inpage/contentid.min.js#Yz1zaG93Y2FzZS91cy9lbi9wcmludGVycy1zY2FubmVycyZtPU5BJmNjPXVzJnA9My1IU0U0LTkyMjI';
				else if (this.props.syndication == "pagewidemoneyback") {
					desc = '//syndication.inc.hp.com/inpage/contentid.min.js#Yz1zaG93Y2FzZS91cy9lbi9wYWdld2lkZS1tb25leS1iYWNrJm09TkEmY2M9dXMmcD0zLUhTRTQtOTIyMg';
				} else if (this.props.syndication == "hprefreshrecycle") {
					desc = '//syndication.inc.hp.com/inpage/contentid.min.js#Yz1zaG93Y2FzZS91cy9lbi9yZWZyZXNoLXJlY3ljbGUmbT1OQSZjYz11cyZwPTMtSFNFNC05MjIy';
				} else if (this.props.syndication == "brandshowcase") {
					desc = '//syndication.inc.hp.com/inpage/contentid.min.js#Yz1zaG93Y2FzZS91cy9lbiZtPU5BJmNjPXVzJnA9My1IU0U0LTkyMjI';
				} else if (this.props.syndication == "largeformat") {
					desc = '//syndication.inc.hp.com/inpage/contentid.min.js#Yz1zaG93Y2FzZS91cy9lbi9wcmludGVycy1zY2FubmVycyZtPU5BJmNjPXVzJnA9My1IU0U0LTkyMjI';
				}
				this.setState({desc:desc});

				//OTI4JjE2MzQzJnVzX2VuJmxhcmdlLWZvcm1hdC5odG1s

				
				//global.window.FlixjQ("#flixsis").SIS({descriptor: desc});

                this.setState({
                    myExternalLib: window.myExternalLib
                });
            };
        }
    }

	

  render() {
	var page_equipment = true;
	var page_homepage = false;

	if (this.props.page === "home")
	{	
		page_homepage = true; 
		page_equipment = false; 
	}
   
	  return (
		<div>
			 
				<Helmet script={[{ src: "//media.flixsyndication.net/js/v2/flixmedia.sis.https.js" }]}
                // Helmet doesn't support `onload` in script objects so we have to hack in our own
                onChangeClientState={(newState, addedTags) => this.handleScriptInject(addedTags)}>
>
					<title> {`${ process.env.REACT_APP_NAME } |  Print and Scan Solutions`} </title>	
					<meta name="description" content="We sell and lease everything you need for printing and scanning. Large Format, Office MFP, Scanners and more!" />
					
			    </Helmet>
				

		<Header page_equipment={page_equipment} page_home={page_homepage} />					
		
			  <section className="">

				  <div  className="holds-the-iframe" style={{width:"100%"}}>
					<div id="postscribe"></div>
						<>
				
							<div id="flixsis"></div>
						
					<script type="text/javascript">
											
					</script>
				</>
				  </div>

			  </section>
			  
			  <div className="container">

				
<hr/>
<div className="row">
	<div className="col-lg-12">
		<div className="owl-carousel owl-theme carousel-center-active-item mb-0" data-plugin-options="{'responsive': {'0': {'items': 1, autoWidth:true}, '476': {'items': 1, autoWidth:true}, '768': {'items': 2, autoWidth:true}, '992': {'items': 2, autoWidth:true}, '1200': {'items': 2, autoWidth:true}}, 'autoplay': true, 'autoplayTimeout': 3000, 'dots': true}">
		
		
			<div>
			   <img className="img-fluid"   src="/img/logos/supplies/hp_opt.png" alt="HP"/>
				<ul>
					<li>Premier Partner Hp A3 copier</li>
					<li>Hp TPS Pagewide XL Servicing dealer</li>
					<li>Hp GDS Design Jet printer servicing dealer</li>
					<li>Hp LPS Low Volume product specialist Servicing dealer</li>
				</ul>
				
				
			</div>
			<div>
			{/* 	<img className="img-fluid" src="/img/logos/supplies/canon_opt.png"  alt="Canon"/>
				<ul>
					<li>Authorized IPF wide format printer servicing dealer</li>
					<li>Authorized Canon Production Print systems servicing dealer.</li>
				</ul>
				*/}
			</div>
			<div>
				<img className="img-fluid" src="/img/logos/supplies/kyocera.png" alt="Kyocera"/>
				<ul><li>Authorized Servicing Kyocera dealer</li></ul>

			</div>
			
			
		</div>					
	</div>
	
</div>

</div>




			  <ContactForm email="sales" subject={"Contact Form [Equipment - ["+this.props.name+"]"} quote={true}/>
			  
		
		<Footer/>
		</div>
	  )
  }
}