import React, { Component } from 'react';

import Header from '../../header/header.js';
import Footer from '../../footer/footer.js';
import ContactForm from '../../support/contactform.js';

import  {Helmet} from "react-helmet";
export default class ServicesBidDistribution extends Component {

  render() {
	  return (
		  <div>
			   <Helmet>
					<title>J2 Blueprint Supply Co. | Bidset Distribution Services</title>	
					<meta name="description" content="J2 can help manage the electronic and print distribution of your bidset project." />
                </Helmet>
		  <Header page_services={true}/>
		  
<div role="main" className="main">

	<section className="page-header page-header-classic page-header-md">

		<div className="container">

			<div className="row">

				<div className="col-md-8 order-2 order-md-1 align-self-center p-static">

					<h1 data-title-border>Bidset Distribution</h1>

				</div>

				<div className="col-md-4 order-1 order-md-2 align-self-center">

					<ul className="breadcrumb d-block text-md-right">

						<li><a href="/">Home</a></li>
						<li><a href="/services">Services</a></li>

						<li className="active">Bidset Distribution</li>

					</ul>

				</div>

			</div>

		</div>

	</section>



	<div className="container pb-1">



		<div className="row pt-4">

			<div className="col">

				<div className="overflow-hidden mb-3">

					<h2 className="font-weight-bold text-8 mb-0 appear-animation" data-appear-animation="maskUp">

						Plans and Specifications are our specialty

					</h2>

				</div>

			</div>

		</div>



		<div className="row">

			<div className="col-lg-10">

				<div className="overflow-hidden">

					<p className="lead mb-0 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="200">

						We've been in the print business for more than a quarter century. We <span className="alternative-font">know</span> printing. Our talented team can help you get the output you deserve, whether its Engineering prints or fine artwork, we've got you covered.

					</p>

				</div>

			</div>

			<div className="col-lg-2 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="500">

				<a target="_new" href="https://uploadjob.j2b.com/upload/" className="btn btn-modern btn-dark mt-1">Send your Files!</a>

			</div>

		</div>



		<div className="row">

			<div className="col py-3">

				<hr className="solid mt-5 mb-2"/>

			</div>

		</div>



		

	</div>



	<section className="section bg-color-grey section-height-3 border-0 mt-5 mb-0">

		<div className="container">



			<div className="row">

				<div className="col">



					<div className="row align-items-center pt-4 appear-animation" data-appear-animation="fadeInLeftShorter">

						<div className="col-md-4 mb-4 mb-md-0">

							<img className="img-fluid scale-2 pr-5 pr-md-0 my-4" src="/j2img/services_engineering.jpg" alt="layout styles" />

						</div>

						<div className="col-md-8 pl-md-5">

							<h2 className="font-weight-normal text-6 mb-3"><strong className="font-weight-extra-bold">Managed</strong> bidset projects.</h2>

							<p className="text-4">We've got the infrastructure, expertise and experience necessary to help your project bid successfully. </p>

							<p>Our Digital Planroom is the perfect place for your bidset project. </p>
							

								<h4> We manage the project</h4>
								
								
								<p>For a per-project fee we can take care of everything. We set up the project in our planroom, take care of uploading files, notifying interested parties about changes etc. We offer this concierge service as a premium service to you. You can rest easy knowing our professionals will take care of everything.</p>
								
								
								
								<h4> You take control</h4>
								
								
								<p>For a small monthly or yearly fee we give you the steering wheel. You can post as many projects as you want, upload your own documents, issue your own notifications and more.</p>
						

						
						</div>

					</div>

                    <section className="call-to-action call-to-action-primary mb-5">
					<div className="col-sm-9 col-lg-9">
						<div className="call-to-action-content">
							<h3>Learn more about our <strong>Bidset</strong> services.</h3>
							<p className="mb-0 opacity-7">Our <strong class="font-weight-extra-bold">Bidset Managers</strong> will walk you through the process.</p>
						</div>
					</div>
					<div className="col-sm-3 col-lg-3">
						<div className="">
							<a href="/support" target="_blank" className="btn btn-modern text-2 btn-light border-0">Contact me about Bidset Services</a>
						</div>
					</div>
				</section>

					

				</div>

			</div>

		</div>

	</section>				

	<ContactForm email="help" subject="Contact Form [ Engineering Services Page ]"/>




</div>
		  
		  
		  
		  <Footer/>
		  </div>
	  )
  }
}