import React, { Component } from 'react';

import Header from '../header/header.js';
import Footer from '../footer/footer.js';

import ContactForm from '../support/contactform.js';
import  {Helmet} from "react-helmet";

export default class NoMatch extends Component {

	constructor(props) {
		super(props);
		this.state = {
					ready:false
		}
	}

	componentDidMount()
	{
		this.setState({ready:true})
	}


  render() {
	if (this.state.ready) {

	 return (
		  <div>
			  <Helmet>
				<title>{`${ process.env.REACT_APP_NAME } |  404 - Page Not Found`}</title>	
				<meta name="robots" content="noindex" />
				<meta name="description" content="Sorry! we cant find your page!" />
            </Helmet>


		  <Header page_supplies={true}/>
		  
				<section className="page-header page-header-modern bg-color-light-scale-1 page-header-md">

					<div className="container">

						<div className="row">





							<div className="col-md-8 order-2 order-md-1 align-self-center p-static">

								<h1 className="text-dark">404<strong> Page Not Found</strong></h1>

<span className="sub-title text-dark">We were unable to find what you're looking for!</span>

							</div>





							<div className="col-md-4 order-1 order-md-2 align-self-center">

								<ul className="breadcrumb d-block text-md-right">

									<li><a href="/">Home</a></li>

									<li className="active">404</li>

								</ul>

							</div>

						</div>

					</div>

				</section>



				<div className="container py-4">



					<div className="row py-3 justify-content-center">

						<div className="col-md-7 text-center">
                            <section className="http-error">
                                <div className="http-error-main">
                                <h2>404!</h2>
                                <p>We're sorry but the page you're looking for doesn't exist!</p>
                                </div>
                            </section>
						</div>

                        <div className="col-md-4 mt-4 mt-md-0">
                       
								<h4 className="text-primary">Here are some useful links</h4>
								<ul className="nav nav-list flex-column">
									<li className="nav-item"><a className="nav-link" href="/">Home</a></li>
								
									
									<li className="nav-item"><a className="nav-link" href="/support">Contact Us</a></li>
								</ul>
							
							

						</div>

						

					</div>



				</div>
		  
		  	<ContactForm email="help" subject="Contact Form [ 404 Error Page ]"/>
				
		  <Footer/>
		  </div>
	  ) 
	  } else {
		  return null;
	  }
  }
}