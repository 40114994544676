import React, { Component } from 'react';

import Header from '../../header/header.js';
import Footer from '../../footer/footer.js';
import ContactForm from '../../support/contactform.js';

import  {Helmet} from "react-helmet";
export default class ServicesPrintFineArt extends Component {

  render() {
	  return (
		  <div>
			   <Helmet>
					<title>{`${ process.env.REACT_APP_NAME } | Fine Art Reproductions`} </title>	
					<meta name="description" content="We've got the latest archival quality large format printers and museum grade contactless scanners. Our in house artists will give your artwork that special touch it deserves. " />
                </Helmet>
		  <Header page_services={true}/>
		  
<div role="main" className="main">

	<section className="page-header page-header-classic page-header-md">

		<div className="container">

			<div className="row">

				<div className="col-md-8 order-2 order-md-1 align-self-center p-static">

					<h1 data-title-border>Fine Art Services</h1>

				</div>

				<div className="col-md-4 order-1 order-md-2 align-self-center">

					<ul className="breadcrumb d-block text-md-right">

						<li><a href="/">Home</a></li>
						<li><a href="/services">Services</a></li>

						<li className="active">Finearts</li>

					</ul>

				</div>

			</div>

		</div>

	</section>



	<div className="container pb-1">



		<div className="row pt-4">

			<div className="col">

				<div className="overflow-hidden mb-3">

					<h2 className="font-weight-bold text-8 mb-0 appear-animation" data-appear-animation="maskUp">

						We Are Here To Help You

					</h2>

				</div>

			</div>

		</div>



		<div className="row">

			<div className="col-lg-10">

				<div className="overflow-hidden">

					<p className="lead mb-0 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="200">

						We've been in the print business for more than a quarter century. We <span className="alternative-font">know</span> printing. Our talented team can help you get the output you deserve, whether its Engineering prints or fine artwork, we've got you covered.

					</p>

				</div>

			</div>

			<div className="col-lg-2 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="500">

				<a target="_new" href="https://uploadjob.j2b.com/upload/" className="btn btn-modern btn-dark mt-1">Send your Files!</a>

			</div>

		</div>



		<div className="row">

			<div className="col py-3">

				<hr className="solid mt-5 mb-2"/>

			</div>

		</div>


		<div className="alert alert-info alert-lg">
										<strong>Fine Art Pricing</strong> - view our current pricing information <a href="/documents/FAPricing.pdf">here.</a>
									</div>
		

	</div>



	<section className="section bg-color-grey section-height-3 border-0 mt-5 mb-0">

		<div className="container">



			<div className="row">

				<div className="col">



					<div className="row align-items-center pt-4 appear-animation" data-appear-animation="fadeInLeftShorter">

						<div className="col-md-4 mb-4 mb-md-0">

							<img className="img-fluid scale-2 pr-5 pr-md-0 my-4" src="/j2img/services_fineart.jpg" alt="layout styles" />

						</div>

						<div className="col-md-8 pl-md-5">

							<h2 className="font-weight-normal text-6 mb-3"><strong className="font-weight-extra-bold">Fine Art</strong> Scans &amp; Prints</h2>

							<p className="text-4">We can do museum quality contactless scans of your artwork and then produce archival quality giclee reproductions on a wide variety of print meida</p>

							<p>Our contactless scanner will create reproduction ready scans of your artwork (up to 36"x60"). We can scan your delicate items without ever placing them on glass or through dangerous feed rollers. Your art remains safe and you get the highest quality scans possible.</p>

						</div>

					</div>



					<hr className="solid my-5"/>



					<div className="row align-items-center pt-5 pb-3 appear-animation" data-appear-animation="fadeInRightShorter">

						<div className="col-md-8 pr-md-5 mb-5 mb-md-0">

							<h2 className="font-weight-normal text-6 mb-3"><strong className="font-weight-extra-bold">Artwork</strong> Reproductions </h2>

		  <p className="text-4">12 color archival grade images on gorgeous print media.</p>

						{/* 	<p>We've got the latest 12 color machines from Canon, the leader in artwork printers, and a wide selection of media to choose from. We can do matte prints, photo prints, canvas prints and more!</p>*/}
						<p>	We are your water color & canvas giclée printing specialist!
The word “giclée,” is a neologism coined in 1991 by printmaker Jack Duganne for fine art digital prints made on inkjet printers. It has since come to be associated with prints using fade-resistant archival inks.  </p>
{/*<p>
We utilize the EFI Fiery XF Rip in conjunction with Canon iPF8400 & IPF9400 12-color LUCIA pigment ink printers for excellent color detail and more natural skin tones. 
</p>*/}
<p>
Reproduction & Digital Archival
J2 can not only print your large format digital artwork we can also scan and reproduce your originals with our high-resolution color flat-bed and sheet fed scanners.
</p>
<p>
True to life color
All of J2's color equipment has been professionally calibrated utilizing the EFI Fiery XF Rip and our work area is equipped with color accurate lighting so you can be sure that your prints will look just as good as your originals!
</p>
						</div>

						<div className="col-md-4 px-5 px-md-3">

							<img className="img-fluid scale-2 my-4" src="/j2img/artworkrepro.jpg" alt="style switcher" />

						</div>

					</div>

					

				</div>

			</div>

		</div>

	</section>				
	<ContactForm email="help" subject="Contact Form [ Fine Art Services Page ]"/>

</div>
		  
		  
		  
		  <Footer/>
		  </div>
	  )
  }
}