import React, { Component } from 'react';
import Input from 'react-phone-number-input/input';
import { isPossibleNumber } from 'libphonenumber-js'
import { FaClock,FaMapMarkerAlt,FaPhone,FaEnvelope } from 'react-icons/fa';
/*import { GoogleReCaptchaProvider,
    GoogleReCaptcha
    
  } from 'react-google-recaptcha-v3';

*/
  import Recaptcha from 'reaptcha';

  import { css } from "@emotion/core";
  import GridLoader from "react-spinners/GridLoader";
  import 'react-phone-number-input/style.css'
  var email_validator = require("email-validator");

  //var Recaptcha = require('react-recaptcha');
  const override = css`
  display: block;
  margin: 0 auto;
  border-color: #316ec5;
`;
const ReCAPTCHAKEY="6LcFmeAUAAAAAOhPsCu-HLepPjlPtUkHcXaSvYiX";
const ReCAPTCHAKEYv2="6LcGMLIZAAAAAGG-paf_wzsWLDCc8mTBlpYFJvtC";
const SCORE_THRESHOLD = 0.5;
const API_URL = "https://0k7qx2td9h.execute-api.us-west-2.amazonaws.com/prod";

export default class ContactForm extends Component {


    constructor(props) {
        super(props);
        this.state = {

            contact_email:this.props.email?this.props.email:"help",
            email_subject:this.props.subject?this.props.subject:"Website Contact Form",
            name:"",
            subject:"",
            message:"",
            email:"",
            phone:"",
            when:"",
            token:"",
            score:0,
            loading:false,
            submitted:false,
            touched: false
            
        }

       this.submitForm = this.submitForm.bind(this);

       this.handleNameChange = this.handleNameChange.bind(this);
       this.handlePhoneChange = this.handlePhoneChange.bind(this);
       this.handleMessageChange = this.handleMessageChange.bind(this);
       this.handleWhenChange = this.handleWhenChange.bind(this);
       this.handleEmailChange = this.handleEmailChange.bind(this);
       this.handleSubjectChange = this.handleSubjectChange.bind(this);
     
       
    }

    submitForm(event) {
        event.preventDefault();
        console.log(this.state);
    }

    handleNameChange(event) {
        this.setState({ touched: true });
        this.setState({ name: event.target.value })
    }
    handlePhoneChange(event) {
       // console.log(event);
       this.setState({ touched: true });
        if (event !== undefined)
           this.setState({ phone: event })
    }
    handleEmailChange(event) {
        this.setState({ touched: true });
    this.setState({ email: event.target.value })
    }
    handleWhenChange(event) {
    this.setState({ when: event.target.value })
    }
    handleMessageChange(event) {
        this.setState({ touched: true });
    this.setState({ message: event.target.value })
    }
    handleSubjectChange(event) {
        this.setState({ touched: true });
        this.setState({ subject: event.target.value })
      }

      async componentDidMount() {
       // const token = await googleReCaptchaProps.executeRecaptcha('homepage');
      //  this.setState({token: token});
      }


    verifyToken = async (token) => {
        this.setState({token: token});
        var _this = this;
       // _this.setState({loading:true});

		//setToken(token);
		fetch(API_URL+"/verifycaptcha/", {
			method: 'post',
			body: JSON.stringify({token:token,v2:true})
		}).then(function(response) {
			return(response.json());
		}).then(function(data) {
            console.log(data);
            //_this.setState({loading:false});
			if (data.statusCode === 200){
                //setScore(data.body.score);
                _this.setState({score:data.body.score});
            } else {
                _this.setState({score:0});
				//setScore(0);
			}
		});


	};

     onSubmit = async () => { 
         console.log("onSubmit enter");
         //await window.grecaptcha.reset();
        
        var valid = this.state.valid;
        valid = this.validateForm();
        console.log("onSubmit validateForm: "+ valid);
        if (valid) {
        //await setPending(true);
            var _this = this;
            _this.setState({loading:true});
            console.log("grecaptcha called");
            

         
            ///WHY IS THIS TIMING OUT SOMETIMES? ERRRROR LOOK INTO IT ERROR
            /*const captchaToken = await window.grecaptcha.execute(ReCAPTCHAKEY, {
                action: 'homepage',
            }); 
            */
            //const result = await executeRecaptcha("homepage");
            console.log("Submitting Contact form");
           // this.setState({token:captchaToken});
            this.submitToAPI(_this.state.token);//captchaToken);
        } else {
            this.setState({touched:true});
            window.alert('Please complete all required fields');
        }
	 }

     
      captchaExpire() {
          this.setState({score:0.0});
      }



	 submitToAPI = async (code) => {
         /*hererer*/
    //	setPending(true);
    console.log("submitToAPI entered");
        var _this = this;
       // var tkn= "";
        
		//if (code)
		//	tkn = this.state.code;
		//else
		//	tkn = this.state.token;


		fetch(API_URL+"/contactemail/", {
			method: 'post',
            body: JSON.stringify({
                contact_email:this.state.contact_email,
                email:this.state.email,
                name:this.state.name,
                phone:this.state.phone,
                subject:this.state.email_subject,
                when:this.state.when,
                email_subject:this.state.email_subject,
                desc:"\n\nSubject:"+this.state.email_subject+"\n\nBest Time to Call: "+ this.state.when+"\n\nSubject: "+this.state.subject+"\n\nMessage: "+this.state.message,
                token:this.state.token,v2:true})

		}).then(function(response) {
			console.log(response);
			if (response.ok) {
                _this.setState({loading:false});
                /*****HERE */
                _this.setState({submitted:true});
				console.log("ok!");
			}
			return(response.json());
		}).then(function(data) {
            //console.log(data);
            
          
		//	setPending(false);
			//setSubmitted(true);
		});
	}

    validateForm() {
       
        return (!this.validateEmail(this.state.email) && 
                !this.validateName(this.state.name) && 
                !this.validateMessage(this.state.message) && 
                !this.validatePhone(this.state.phone) && 
                !this.validateSubject(this.state.subject));

    }

    validateEmail(email) 
    {

        return !email_validator.validate(email);
    }
    validateName(name) 
    {
        if (name.length < 5)
          return true;
        return false;

    }
    validateMessage(message)
    {
        if (this.props.nomessage)
          return false;
        if (message.length < 10)
         return true;
        return false;
    }

    validateSubject(message)
    {
        if (this.props.nosubject)
         return false;
        if (message.length < 1)
         return true;
        return false;
    }

    validatePhone(phone)
    {
        var valid = isPossibleNumber(phone);
        if (valid)
            return false;
        else return !isPossibleNumber("+1"+phone);
        //return false;
    }

  render() {
  //  <GoogleReCaptchaProvider reCaptchaKey={ReCAPTCHAKEY}>
  //  <GoogleReCaptcha onVerify={token => this.verifyToken(token)} />
 // </GoogleReCaptchaProvider>
    var email_error = false;
    var name_error = false;
    var message_error = false;
    var phone_error = false;
    var subject_error = false;


    if (this.state.touched) {
        email_error = this.validateEmail(this.state.email);
        name_error = this.validateName(this.state.name);
        message_error = this.validateMessage(this.state.message);
        phone_error = this.validatePhone(this.state.phone);
        subject_error = this.validateSubject(this.state.subject);
    }

    


    var header="";
    var fullname="Full Name";
    var emailaddress="Email Address";
    var phonenumber="Phone Number";
    var subject = "Subject";
    var whentocall="When to Call";
    var message="Message";
    var boxclass="row mb-5 rounded bg-color-grey";
    var textcolorclass="text-dark";
    var btnClass="btn btn-lg btn-primary";


    var  footer = (
        <>
         <div className="row mb-5">

<div className="col-lg-4">



<div className="overflow-hidden mb-3">

    <h4 className="pt-5 mb-0 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="200" data-plugin-options="{'accY': -200}">Get in <strong>Touch</strong></h4>

</div>

<div className="overflow-hidden mb-3">

    <p className="lead text-4 mb-0 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="400" data-plugin-options="{'accY': -200}">Contact us with any questions or product inquiries you might have!</p>

</div>

<div className="overflow-hidden">

    <p className="mb-0 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="600" data-plugin-options="{'accY': -200}">We'd love to hear from you!</p>
</div>



</div>

<div className="col-lg-4 offset-lg-1 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="200" data-plugin-options="{'accY': -200}">



<h4 className="pt-5">Our <strong>Office</strong></h4>

<ul className="list list-icons list-icons-style-3 mt-1">

    <li><FaMapMarkerAlt className="fa-selector"/>  <strong>Address:</strong> <a href="https://g.page/j2blue?share">8100 Ne St Johns Rd. Suite B101, Vancouver WA 98665</a></li>

    <li><FaPhone className="fa-selector"/> <strong>Phone:</strong> (360) 696-1861</li>

    <li><FaEnvelope className="fa-selector"/> <strong>Email:</strong> <a href="mailto:help@j2b.com">help@j2b.com</a></li>

</ul>



</div>

<div className="col-lg-3 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="300" data-plugin-options="{'accY': -200}">



<h4 className="pt-5">Business <strong>Hours</strong></h4>

<ul className="list list-icons list-dark mt-2">

    <li><FaClock/> Monday - Friday - 8am to 5pm</li>

    <li><FaClock/> Saturday - Closed</li>

    <li><FaClock/> Sunday - Closed</li>

</ul>



</div>

</div>
        </>
    );



    if (this.props.basicform)
    {
        whentocall="";
        subject="";
        message="";
        boxclass="";
        textcolorclass="text-color-light";
        btnClass="btn btn-lg btn-success";
        header = (
            <>
            </>
        );
       footer = (<></>);
    } else if (this.props.quicksupply)
    {
        whentocall="";
        subject="Company Name";
        message="Supplies to Order";
        header = (
            <>
            <h1><strong>Quick</strong> Supply Order Form</h1>
            <p>Expedited Supply Order Form
    This is a rapid supply orderservice that takes just seconds to complete and initiate a 
    courier dispatch for your supply delivery. This form was designed to accept minimal information. 
    A J2 Blueprint Supply Co Employee will call you to schedule a delivery time and give you a order 
    confirmation number.</p></>

    );
     } else  if (this.props.expeditedpickup)
        {
            fullname="Contact Name";
            subject="Company Name";
            whentocall="";
            
            message="Notes about Pickup";
            header = (
                <>
                <h1>Expedited <strong>Pickup Request</strong> </h1>

<p>This is a rapid order reprographic pick-up service that takes just seconds to complete and initiate a courier dispatch for your pick-up. This form was designed to accept minimal information. A J2 Blueprint Supply Co Employee will call you to schedule a return delivery time and give you an order confirmation number.</p>

<p>There is a $10 minimum order for pickup and delivery services</p>
                </>
    
        );
    } else  if (this.props.repairform)
    {
        fullname="Full Name";
        subject="Company Name";
        whentocall="Company Name";
        subject="Machine Make and Model";
        message="Description of the Problem";
        header = (
            <>
            <h1>Place a <strong>Service Call</strong> </h1>


            </>

    );
    } else if (this.props.quote) {
        header = (<h1>Request a <strong>Quote</strong></h1>);
    }else {
        header = (<h1>Contact <strong>J2</strong></h1>);
    }


    if (this.state.loading)
    return (
        <div className="sweet-loading">
        <GridLoader
        css={override}
        size={50}
        color={"#214d8a"}
        loading={this.state.loading}
        />
        </div>
    )
else
	  return (
            <div className="container py-4">
                
               
                
                <div className={boxclass}>
                
                    <div className="col">
    
                        {header}


                        <form id="contactForm" className="contact-form-recaptcha-v3" >
                        {this.state.submitted?(
                            <div className="contact-form-success alert alert-success  mt-4" id="contactSuccess">

                                <strong>Thank you!</strong> Your request has been submitted to J2.

                            </div>
                        ):(<></>)}
                        

                            <div className="contact-form-error alert alert-danger d-none mt-4" id="contactError">

                                <strong>Error!</strong> There was an error sending your message.

                                <span className="mail-error-message text-1 d-block" id="mailErrorMessage"></span>

                            </div>

                            
                            {!this.state.submitted?(
                                <>
                            <div className="form-row">

                                <div className="form-group col-lg-6">

                                    <label className={"required font-weight-bold " + textcolorclass + " text-2 required"}>{fullname}</label>

                                    <input type="text"  data-msg-required="Please enter your name." maxLength="100" className="form-control" name="name" id="name" required value={this.state.name} onChange={this.handleNameChange}/>
                                    {name_error && (<h6 className="text-secondary">Please enter your full name</h6>)}
                                </div>

                                <div className="form-group col-lg-6">

                                    <label className={"required font-weight-bold " + textcolorclass + " text-2"}>{emailaddress}</label>

                                    <input value={this.state.email} onChange={this.handleEmailChange} type="email"  data-msg-required="Please enter your email address." data-msg-email="Please enter a valid email address." maxLength="100" className="form-control" name="email" id="email" required />
                                    {email_error && (<h6 className="text-secondary">Please enter a valid email address</h6>)}
                                </div>

                            </div>

                            <div className="form-row">

                                <div className="form-group col-lg-6">

                                    <label className={"required font-weight-bold " + textcolorclass + " text-2"}>{phonenumber}</label>
                                    <Input
                                    className="form-control" 
                                     country="US"
                                     
                                        placeholder="Enter phone number"
                                        value={this.state.phone}
                                        onChange={this.handlePhoneChange}
                                        error={this.state.phone ? (isPossibleNumber(this.state.phone) ? undefined : 'Invalid phone number') : 'Phone number required'}/>
                                        {phone_error && (<h6 className="text-secondary">Please enter a valid phone number</h6>)}
                                     </div>
                            {whentocall?(
                                <div className="form-group col-lg-6">

                                    <label className={"font-weight-bold " + textcolorclass + " text-2"}>{whentocall}</label>

                                    <input value={this.state.when} onChange={this.handleWhenChange} type="text"  data-msg-required="Please let us know a good time to call"  maxLength="100" className="form-control" name="when" id="when"  />
                                    
                                </div>
                                ):(<></>)}

                            </div>
                            
                            {subject?(
                            <div className="form-row">

                                <div className="form-group col">

                                    <label className={"required font-weight-bold " + textcolorclass + " text-2"}>{subject}</label>

                                    <input value={this.state.subject} onChange={this.handleSubjectChange} type="text" data-msg-required="Please enter the subject." maxLength="100" className="form-control" name="subject" id="subject" required />
                                    {subject_error && (<h6 className="text-secondary">This field is required</h6>)}
                                </div>

                            </div>
                             ):(<></>)}
                            {message?(
                            <div className="form-row">

                                <div className="form-group col">

                                    <label className={"required font-weight-bold " + textcolorclass + " text-2"}>{message}</label>

                                    <textarea  onChange={this.handleMessageChange} maxLength="5000" data-msg-required="Please enter your message." rows="5" className="form-control" name="message" id="message" required value={this.state.message}></textarea>
                                    {message_error && (<h6 className="text-secondary">Please enter a more detailed message</h6>)}
                                </div>

                            </div>
                            ):(<></>)}

                                <br/>
                            <Recaptcha
                                sitekey={ReCAPTCHAKEYv2}
                                
                                onExpire={()=>{this.setState({score:0.0})}}
                                onVerify={recaptchaResponse => {
                                    // Do something
                                   // console.log(recaptchaResponse);
                                    this.setState({token:recaptchaResponse,score:0.5});
                                    this.validateForm();
                                  }}
                                
                            />


                            <div className="form-row">

                                <div className="form-group col">
                                {this.state.score>=SCORE_THRESHOLD?(<button type="button" onClick={this.onSubmit} className={btnClass} style={{"marginTop":"20px"}}>Submit</button> ):(<p></p>)}
                                  
                                </div>

                            </div>
                            </>):(<></>)}
                        </form>


                    </div>
                    

                </div>

               {footer}
            </div>
      )
    }
}

