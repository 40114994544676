import React, { Component } from 'react';

import Header from '../../header/header.js';
import Footer from '../../footer/footer.js';
import ContactForm from '../../support/contactform.js';

import  {Helmet} from "react-helmet";
export default class ServicesPrintBusiness extends Component {

  render() {
	  return (
		  <div>
			   <Helmet>
					<title>{`${ process.env.REACT_APP_NAME } | Business Document Reproductions`} </title>	
					<meta name="description" content="J2 uses the latest high-speed digital copiers to create crystal clear offset-like reproductions and original documents." />
                </Helmet>
		  <Header page_services={true}/>
		  
<div role="main" className="main">

	<section className="page-header page-header-classic page-header-md">

		<div className="container">

			<div className="row">

				<div className="col-md-8 order-2 order-md-1 align-self-center p-static">

					<h1 data-title-border>Business Documents</h1>

				</div>

				<div className="col-md-4 order-1 order-md-2 align-self-center">

					<ul className="breadcrumb d-block text-md-right">

						<li><a href="/">Home</a></li>

						<li><a href="/services">Services</a></li>

						<li className="active">Business</li>

					</ul>

				</div>

			</div>

		</div>

	</section>



	<div className="container pb-1">



		<div className="row pt-4">

			<div className="col">

				<div className="overflow-hidden mb-3">

					<h2 className="font-weight-bold text-8 mb-0 appear-animation" data-appear-animation="maskUp">

						We Are Here To Help You

					</h2>

				</div>

			</div>

		</div>



		<div className="row">

			<div className="col-lg-10">

				<div className="overflow-hidden">

					<p className="lead mb-0 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="200">

						We've been in the print business for more than a quarter century. We <span className="alternative-font">know</span> printing. Our talented team can help you get the output you deserve, whether its Engineering prints or fine artwork, we've got you covered.

					</p>

				</div>

			</div>

			<div className="col-lg-2 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="500">

				<a target="_new" href="https://uploadjob.j2b.com/upload/" className="btn btn-modern btn-dark mt-1">Send your Files!</a>

			</div>

		</div>



		<div className="row">

			<div className="col py-3">

				<hr className="solid mt-5 mb-2"/>

			</div>

		</div>

	</div>



	<section className="section bg-color-grey section-height-3 border-0 mt-5 mb-0">

		<div className="container">



			<div className="row">

				<div className="col">



					<div className="row align-items-center pt-4 appear-animation" data-appear-animation="fadeInLeftShorter">

						<div className="col-md-4 mb-4 mb-md-0">

							<img className="img-fluid scale-2 pr-5 pr-md-0 my-4" src="/j2img/digitalpress.jpg" alt="layout styles" />

						</div>

						<div className="col-md-8 pl-md-5">

							<h2 className="font-weight-normal text-6 mb-3"><strong className="font-weight-extra-bold">Your Documents</strong> are our business.</h2>

							<p className="text-4">
							J2 uses the latest high-speed digital copiers

to create crystal clear offset-like reproductions and original documents. We have a complete assortment of paper stock in a variety of colors and weights. We also offer a complete array of finishing options ranging from acetate covers and printed tabs to pro
fessional bindings. We can print from almost any standard digital format and can also work from your hard-copy originals. We offer high speed monocrome and full color services at competitive rates with guaranteed fast turn arounds! No order is too small or too large for the J2 Small Format team!
</p>

			<ul>

			<li>Color &amp; Monochrome Printing</li>
			<li>Wide range of binding options</li>
			<li>Double Sided</li>
			<li>Cover Pages</li>
			<li>Tabs</li>
			<li>Card Stock</li>
			<li>Wide range of materials and colors available</li>
			</ul>
						</div>

					</div>



					<hr className="solid my-5"/>



					

				</div>

			</div>

		</div>

	</section>				


	<ContactForm email="help" subject="Contact Form [ Business Services Page ]"/>



</div>
		  
		  
		  
		  <Footer/>
		  </div>
	  )
  }
}