import React, { Component } from 'react';

import Header from '../../header/header.js';
import Footer from '../../footer/footer.js';
import ContactForm from '../../support/contactform.js';

import  {Helmet} from "react-helmet";
export default class FileSizeDPI extends Component {

  render() {
	  return (
		  <div>
			   <Helmet>
					<title>{`${ process.env.REACT_APP_NAME } | File Size / DPI Relationships`}</title>	
					<meta name="description" content="File Size DPI - Success when Printing Digital Files" />
                </Helmet>
		  <Header page_services={true}/>
		  
<div role="main" className="main">

	<section className="page-header page-header-classic page-header-md">

		<div className="container">

			<div className="row">

				<div className="col-md-8 order-2 order-md-1 align-self-center p-static">

					<h1 data-title-border>Fine Art Services</h1>

				</div>

				<div className="col-md-4 order-1 order-md-2 align-self-center">

					<ul className="breadcrumb d-block text-md-right">

						<li><a href="/">Home</a></li>
						<li><a href="/services">Services</a></li>

						<li className="active">Finearts</li>

					</ul>

				</div>

			</div>

		</div>

	</section>



	<div className="container pb-1">



		<div className="row pt-4">

			<div className="col">

				<div className="overflow-hidden mb-3">

					<h2 className="font-weight-bold text-8 mb-0 appear-animation" data-appear-animation="maskUp">

						We Are Here To Help You

					</h2>

				</div>

			</div>

		</div>



		<div className="row">

			<div className="col-lg-10">

				<div className="overflow-hidden">

					<p className="lead mb-0 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="200">

						We've been in the print business for more than a quarter century. We <span className="alternative-font">know</span> printing. Our talented team can help you get the output you deserve, whether its Engineering prints or fine artwork, we've got you covered.

					</p>

				</div>

			</div>

			<div className="col-lg-2 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="500">

				<a target="_new" href="https://uploadjob.j2b.com/upload/" className="btn btn-modern btn-dark mt-1">Send your Files!</a>

			</div>

		</div>



		<div className="row">

			<div className="col py-3">

				<hr className="solid mt-5 mb-2"/>

			</div>

		</div>

	</div>



	<section className="section bg-color-grey section-height-3 border-0 mt-5 mb-0">

		<div className="container">



			<div className="row">

				<div className="col">



					<div className="row align-items-center pt-4 appear-animation" data-appear-animation="fadeInLeftShorter">

						
<div className="row">
						<div className="col-md-12">
						<h1><b>Success When Printing Digital Files</b></h1>
						<p>When printing digital files, we like to print at 300dpi (or higher) whenever possible. Printing at high dpi's yeild the best results. It is important to note that while we can scale images up in size, a 300dpi original will look much better than a 100 dpi original that was scaled up to 300dpi.</p>
						<p>The Following guide is intended to help you decide what size prints you can make from your files. Depending on the application, sometimes lower resolution prints will yeild acceptable results but its always best to start with the highest resolution source files possible.</p>
						<h1>DPI - Dots Per Inch</h1>
						<p>DPI is how we describe pixel density on a printed page. For example a 300x300 pixel image will be 1"x1" when printed at 300 dpi, a 3000x3000 pixel image will be 10"x10" when printed at 300 dpi. The Higher the DPI the smaller the dots on the printed page and the sharper the print will look to the human eye.&nbsp;</p>
						<p>&nbsp;</p>
						<table className="subhead" style={{width: "600px", marginLeft: "auto", marginRight: "auto"}} border="0">
						<tbody>
						<tr>
						<td colspan="9">
						<div><strong>Digital camera resolution vs print quality</strong></div>
						</td>
						</tr>
						<tr>
						<td rowspan="2">
						<div><strong>Capture Resolution</strong></div>
						</td>
						<td rowspan="2">
						<div><strong>Video Display</strong></div>
						</td>
						<td colspan="7">
						<div><strong>Print Size</strong></div>
						</td>
						</tr>
						<tr>
						<td>
						<div><strong>2x3"</strong></div>
						</td>
						<td>
						<div><strong>4x5"/4x6"</strong></div>
						</td>
						<td>
						<div><strong>5x7"</strong></div>
						</td>
						<td>
						<div><strong>8x10"</strong></div>
						</td>
						<td>
						<div><strong>11x14"</strong></div>
						</td>
						<td>
						<div><strong>16x20"</strong></div>
						</td>
						<td>
						<div><strong>20x30"</strong></div>
						</td>
						</tr>
						<tr>
						<td>
						<div><strong>320x240</strong></div>
						</td>
						<td style={{background: "#f4ee42"}}>
						<div style={{background: "#f4ee42"}}>
						<p>OK</p>
						</div>
						</td>
						<td style={{background: "#f4ee42"}}>
						<div>
						<p>Good</p>
						</div>
						</td>
						<td style={{background: "#f4ee42"}}>
						<div style={{background: "#f4ee42"}}>
						<p>OK</p>
						</div>
						</td>
						<td style={{background: "#f46242"}}>
						<p>Bad</p>
						</td>
						<td style={{background: "#f46242"}}>
						<p>Bad</p>
						</td>
						<td style={{background: "#f46242"}}>
						<p>Bad</p>
						</td>
						<td style={{background: "#f46242"}}>
						<p>Bad</p>
						</td>
						<td style={{background: "#f46242"}}>
						<p>Bad</p>
						</td>
						</tr>
						<tr>
						<td>
						<div><strong>640x480<br />0.3Megapixel</strong></div>
						</td>
						<td style={{background: "#f4ee42"}}>
						<div>
						<p>Good</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Excellent</p>
						</div>
						</td>
						<td style={{background: "#f4ee42"}}>
						<div>
						<p>Good</p>
						</div>
						</td>
						<td style={{background: "#f46242"}}>
						<p>Bad</p>
						</td>
						<td style={{background: "#f46242"}}>
						<p>Bad</p>
						</td>
						<td style={{background: "#f46242"}}>
						<p>Bad</p>
						</td>
						<td style={{background: "#f46242"}}>
						<p>Bad</p>
						</td>
						<td style={{background: "#f46242"}}>
						<p>Bad</p>
						</td>
						</tr>
						<tr>
						<td>
						<div><strong>800x600</strong></div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Excellent</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#41d6f4"}}>
						<div>
						<p>Very Good</p>
						</div>
						</td>
						<td style={{background: "#f4ee42"}}>
						<div>
						<p>Good</p>
						</div>
						</td>
						<td style={{background: "#f46242"}}>
						<p>Bad</p>
						</td>
						<td style={{background: "#f46242"}}>
						<p>Bad</p>
						</td>
						<td style={{background: "#f46242"}}>
						<p>Bad</p>
						</td>
						<td style={{background: "#f46242"}}>
						<p>Bad</p>
						</td>
						</tr>
						<tr>
						<td>
						<div><strong>1024x768</strong></div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Excellent</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Excellent</p>
						</div>
						</td>
						<td style={{background: "#f4ee42"}}>
						<div>
						<p>Good</p>
						</div>
						</td>
						<td style={{background: "#f4ee42"}}>
						<div>
						<p>OK</p>
						</div>
						</td>
						<td style={{background: "#f46242"}}>
						<p>Bad</p>
						</td>
						<td style={{background: "#f46242"}}>
						<p>Bad</p>
						</td>
						<td style={{background: "#f46242"}}>
						<p>Bad</p>
						</td>
						</tr>
						<tr>
						<td>
						<div><strong>1280x960<br />1 MP</strong></div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Excellent</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#41d6f4"}}>
						<div>
						<p>Very Good</p>
						</div>
						</td>
						<td style={{background: "#f4ee42"}}>
						<div>
						<p>Good</p>
						</div>
						</td>
						<td style={{background: "#f46242"}}>
						<p>Bad</p>
						</td>
						<td style={{background: "#f46242"}}>
						<p>Bad</p>
						</td>
						<td style={{background: "#f46242"}}>
						<p>Bad</p>
						</td>
						</tr>
						<tr>
						<td>
						<div><strong>1536x1180</strong></div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Excellent</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Excellent</p>
						</div>
						</td>
						<td style={{background: "#41d6f4"}}>
						<div>
						<p>Very Good</p>
						</div>
						</td>
						<td style={{background: "#f4ee42"}}>
						<div>
						<p>OK</p>
						</div>
						</td>
						<td style={{background: "#f46242"}}>
						<p>Bad</p>
						</td>
						<td style={{background: "#f46242"}}>
						<p>Bad</p>
						</td>
						</tr>
						<tr>
						<td>
						<div><strong>1600x1200<br />2MP</strong></div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Excellent</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#41d6f4"}}>
						<div>
						<p>Very Good</p>
						</div>
						</td>
						<td style={{background: "#f4ee42"}}>
						<div>
						<p>OK</p>
						</div>
						</td>
						<td style={{background: "#f4ee42"}}>
						<div>
						<p>OK</p>
						</div>
						</td>
						<td style={{background: "#f46242"}}>
						<p>Bad</p>
						</td>
						</tr>
						<tr>
						<td>
						<div><strong>2048x1536<br />3 Megapixel</strong></div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Excellent</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Excellent</p>
						</div>
						</td>
						<td style={{background: "#f4ee42"}}>
						<div>
						<p>Good</p>
						</div>
						</td>
						<td style={{background: "#f4ee42"}}>
						<div style={{background: "#f4ee42"}}>
						<p>OK</p>
						</div>
						</td>
						<td style={{background: "#f4ee42"}}>
						<div>
						<p>OK</p>
						</div>
						</td>
						</tr>
						<tr>
						<td>
						<div><strong>2240x1680<br />4 Megapixel</strong></div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Excellent</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#41d6f4"}}>
						<div>
						<p>Very Good</p>
						</div>
						</td>
						<td style={{background: "#f4ee42"}}>
						<div>
						<p>Good</p>
						</div>
						</td>
						<td style={{background: "#f4ee42"}}>
						<div style={{background: "#f4ee42"}}>
						<p>OK</p>
						</div>
						</td>
						</tr>
						<tr>
						<td>
						<div><strong>2560x1920<br />5 Megapixel</strong></div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Excellent</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Excellent</p>
						</div>
						</td>
						<td style={{background: "#41d6f4"}}>
						<div>
						<p>Very Good</p>
						</div>
						</td>
						<td style={{background: "#41d6f4"}}>
						<div>
						<p>Very Good</p>
						</div>
						</td>
						</tr>
						<tr>
						<td>
						<div><strong>3032x2008<br />6 Megapixel</strong></div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Excellent</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Excellent</p>
						</div>
						</td>
						<td style={{background: "#41d6f4"}}>
						<div>
						<p>Very Good</p>
						</div>
						</td>
						</tr>
						<tr>
						<td>
						<div><strong>3072x2304<br />7 Megapixel</strong></div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Excellent</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Excellent</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Excellent</p>
						</div>
						</td>
						</tr>
						<tr>
						<td>
						<div><strong>3264x2448<br />8 Megapixel</strong></div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Excellent</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Excellent</p>
						</div>
						</td>
						</tr>
						<tr>
						<td>
						<div><strong>10 Megapixel +</strong></div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Excellent</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						<td style={{background: "#55ff2b"}}>
						<div>
						<p>Photo Quality</p>
						</div>
						</td>
						</tr>
						</tbody>
						</table>
						<p style={{textAlign: "center"}}>&nbsp;</p>
						<table className="subhead" style={{width: "600px", marginLeft: "auto", marginRight: "auto"}} border="0">
						<tbody>
						<tr>
						<td style={{background: "#f46242"}}>
						<p>Bad</p>
						</td>
						<td>
						<p>&nbsp;Horribly pixelated blocky mess, like something out of 1987</p>
						</td>
						</tr>
						<tr>
						<td style={{background: "#f4ee42"}}>
						<p><strong>OK</strong></p>
						</td>
						<td>
						<p>&nbsp;Looks reasonable enough, but nowhere near photo quality</p>
						</td>
						</tr>
						<tr>
						<td style={{background: "#f4ee42"}}>
						<p><strong>Good</strong></p>
						</td>
						<td>
						<p>&nbsp;Pretty good all round, with a fair bit of detail</p>
						</td>
						</tr>
						<tr>
						<td style={{background: "#41d6f4"}}>
						<p><strong>Very Good</strong></p>
						</td>
						<td>
						<p>&nbsp;This will be fine for most uses, unless you get up reeeeal close</p>
						</td>
						</tr>
						<tr>
						<td style={{background: "#55ff2b"}}>
						<p><strong>Excellent</strong></p>
						</td>
						<td>
						<p>&nbsp;As good as a photo for most purposes</p>
						</td>
						</tr>
						<tr>
						<td style={{background: "#55ff2b"}}>
						<p><strong>Photo Quality</strong></p>
						</td>
						<td>
						<p>&nbsp;Woohoo! Fantastic quality print, full of detail and perfect for your picture frame or for your showing off your very best shots.&nbsp;</p>
						</td>
						</tr>
						</tbody>
						</table>
						<p><br /><br /><br /></p>
						<table className="table table-striped" style={{width: "600px", marginLeft: "auto", marginRight: "auto"}} border="1">
						<tbody>
						<tr>
						<td className="text1" colspan="4"><strong>Printing images - what file size do you need?</strong><br /><span style={{fontSize: "small"}}>A guide to required file sizes for printing out images<br /><br /></span></td>
						</tr>
						<tr>
						<td>
						<p><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif", fontSize: "small"}}>Image size Pixels<br />(Virtual Size of Scans)</span></p>
						</td>
						<td>
						<p><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif", fontSize: "small"}}>Megapixel<br />rating<br /></span></p>
						</td>
						<td>
						<p><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif", fontSize: "small"}}>Print size (inches)<br />at 200dpi</span></p>
						</td>
						<td>
						<p><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif", fontSize: "small"}}>Print size (inches)<br />at 300dpi</span></p>
						</td>
						</tr>
						<tr>
						<td>
						<p><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>640&nbsp; x&nbsp; 480</span></p>
						</td>
						<td>
						<p><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>0.3</span></p>
						</td>
						<td>
						<p><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>3.2 x 2.4</span></p>
						</td>
						<td>
						<p><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>2.1 x 1.6</span></p>
						</td>
						</tr>
						<tr>
						<td>
						<p><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>1,024 x 768</span></p>
						</td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>0.8</span></td>
						<td>
						<p><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>5.1 x 3.8</span></p>
						</td>
						<td>
						<p><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>3.4 x 2.5</span></p>
						</td>
						</tr>
						<tr>
						<td>
						<p><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>1,280 x&nbsp; 960</span></p>
						</td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>1.2</span></td>
						<td>
						<p><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>6.4 x 4.8</span></p>
						</td>
						<td>
						<p><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>4.2 x 3.2</span></p>
						</td>
						</tr>
						<tr>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>1,504 x 1,000</span></td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>1.5</span></td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>7.5 x 5.0</span></td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>5.0 x 3.3</span></td>
						</tr>
						<tr>
						<td>
						<p><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>1,632 x 1,224</span></p>
						</td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>2.0</span></td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>3.3 x 6.1</span></td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>5.4 x 4.1</span></td>
						</tr>
						<tr>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>2,000 x 1,312</span></td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>2.6</span></td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>10.0 x 6.6</span></td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>6.7 x 4.4</span></td>
						</tr>
						<tr>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>2,240 x 1,488</span></td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>3.3</span></td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>11.2 x 7.4</span></td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>7.5 x 5.0</span></td>
						</tr>
						<tr>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>2,275 x 1,520</span></td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>3.5</span></td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>11.4 x 7.6</span></td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>7.6 x 5.1</span></td>
						</tr>
						<tr>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>2,272 x 1,704</span></td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>3.9</span></td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>11.4 x 8.5</span></td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>7.6 x 5.7</span></td>
						</tr>
						<tr>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>2,590 x 1,920</span></td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>5.0</span></td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>13.0 x 9.6</span></td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>8.6 x 6.4</span></td>
						</tr>
						<tr>
						<td>
						<p><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>3,008 x 2,000</span></p>
						</td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>6.0</span></td>
						<td>
						<p><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>15.0 x 10.0</span></p>
						</td>
						<td>
						<p><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>10.0 x 6.7</span></p>
						</td>
						</tr>
						<tr>
						<td>
						<p><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>4,256 x 2,848</span></p>
						</td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>12.1</span></td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>21.3 x 14.2</span></td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>14.2 x 9.5</span></td>
						</tr>
						<tr>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>4,536 x 3,024</span></td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>13.7</span></td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>22.7 x 15.1</span></td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>15.1 x 10.1</span></td>
						</tr>
						<tr>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>5,782 x 3,946</span></td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>22.8</span></td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>&nbsp;28.9 x 19.7</span></td>
						<td><span style={{fontFamily: "Verdana, Arial, Helvetica, sans-serif"}}>19.3 x 13.2</span></td>
						</tr>
						<tr>
						<td colspan="4"><span style={{color: "#000000", fontSize: "small"}}><br /><br /><strong>Professional/online printing</strong><br /><br /><span style={{fontSize: "small"}}><br /><a href="http://www.kodak.com/US/en/digital/dlc/book1/chapter8/lesson1/p01.shtml" target="_new">Kodak</a> suggests these resolution/file sizes:<br />For a 4" x 6" print, the image resolution should be 640 x 480 pixels minimum.<br />For a 5" x 7" print, the image resolution should be 1024 x 768 pixels minimum.<br />For an 8" x 10" print, the image resolution should be 1536 x 1024 pixels minimum.<br />For a 16" x 20" print, the image resolution should be 1600 x 1200 pixels minimum.<br />For a 20" x 30" print, the image resolution should be 1600 x 1200 pixels minimum.<br />For a Wallet-size print, the image resolution should be 320 x 240 pixels minimum.<br /><br /><br /></span><strong>Quality vs file size</strong><br />This guide shows you the kind of quality you can expect when you're printing out your photos.<br /><br /></span></td>
						</tr>
						</tbody>
						</table>


						</div>
					</div>

					</div>

					

				</div>

			</div>

		</div>

	</section>				
	<ContactForm email="help" subject="Contact Form [ Filesize DPI Questions ]"/>

</div>
		  
		  
		  
		  <Footer/>
		  </div>
	  )
  }
}


