import React, { Component } from 'react';

import Header from '../header/header.js';
import Footer from '../footer/footer.js';

import ContactForm from '../support/contactform.js';
import  {Helmet} from "react-helmet";

export default class Lease extends Component {

componentDidMount() {
    setTimeout(() => {
        window.initCarousel();
        window.initParallax();
        window.doCaro();

        window.loadParallax();

    }, 500);

}

  render() {
	  return (
		  <div>
              <Helmet>
					<title>{`${ process.env.REACT_APP_NAME } |   Machine Leasing solutions`}</title>	
					<meta name="description" content="We can get you the machine you need with the most innovative financing options available." />
                </Helmet>


		  <Header page_equipment={true}/>
		  
          <div role="main" className="main" suppressHydrationWarning={true}>


            <div className="container pt-4">
                    <div className="row text-center pt-4 mt-5">
                        <div className="col">
                            <h2 className="word-rotator slide font-weight-bold text-8 mb-2">
                                <span>We use the  </span>
                                <span className="word-rotator-words bg-primary">
                                    <b className="is-visible">best</b>
                                    <b>most innovative</b>
                                    
                                </span>
                                <span> financing options available.</span>
                            </h2>
                            <h4 className="text-primary lead tall text-4">With innovative leasing options available for any situation, J2 can help you find a equipment to fit your needs with a lease payment that fits your budget.</h4>
                        </div>
                    </div>

                    <div className="row text-center mt-5 pb-5 mb-5">
                        <div className="owl-carousel owl-theme carousel-center-active-item mb-0" data-plugin-options="{'responsive': {'0': {'items': 1, autoWidth:true}, '476': {'items': 1, autoWidth:true}, '768': {'items': 2, autoWidth:true}, '992': {'items': 2, autoWidth:true}, '1200': {'items': 2, autoWidth:true}}, 'autoplay': true, 'autoplayTimeout': 3000, 'dots': false}">
                            <div>
                                <img className="img-fluid" src="/img/logos/hpefin.png" alt=""/>
                            </div>
                            {/* <div>
                                <img className="img-fluid" src="/img/logos/canonfin.png" alt=""/>
                            </div> */}
                            <div>
                                <img className="img-fluid" src="/img/logos/leafcommercial.png" alt=""/>
                            </div>
                            
                        </div>
                    </div>
                </div>
    
            <section className="section bg-color-grey section-height-3 border-0 mt-5 mb-0">
                    <div className="container">

                        <div className="row">
                            <div className="col">

                                <div className="row align-items-center pt-4 appear-animation" data-appear-animation="fadeInLeftShorter">
                                    <div className="col-md-4 mb-4 mb-md-0">
                                        <img className="img-fluid scale-2 pr-5 pr-md-0 my-4" src="/j2img/equipment/wide/pagewide_xl4100.jpg" alt="layout styles" />
                                    </div>
                                    <div className="col-md-8 pl-md-5">
                                        <h2 className="font-weight-normal text-6 mb-3">
            Copier Leasing: <strong className="font-weight-extra-bold">The Benefits</strong></h2>
                                        <p className="text-4">
                                                <b>Leasing keeps your equipment up-to-date.</b> Computers, copiers, printers and other technology will inevitably  
                                            become obsolete. With a lease you pass the financial burden of obsolescence to the equipment leasing company. For example, 
                                            let's say you have a three-year lease on a copy machine. After that lease expires, you're free to lease whatever equipment 
                                            is newer, faster and cheaper. (This is also a reason some people prefer to lease their cars.) In fact, 65 percent of 
                                            respondents to a 2005 Equipment Leasing Association survey said the ability to have the latest equipment was leasing's 
                                            number-one benefit.
                                        </p><p className="text-4">
                                            <b>You'll have predictable monthly expenses.</b> With a copier lease you have a pre-determined monthly line item which will help you budget more effectively. 
                                            Thirty-five percent of respondents to the Equipment Leasing Association's survey said this was leasing's second-highest benefit.
                                            You pay nothing up front. Many small businesses struggle with cash flow and must keep their coffers as full as possible. 
                                            Because leases rarely require a down payment, you can acquire new equipment without tapping much-needed funds.
                                            Leasing can enable your small business to acquire 
                                            sophisticated office technology that might be otherwise unaffordable. The result: You're better able to keep up with your larger 
                                            competitors without draining your financial resources.
                                        </p>
                                    </div>
                                </div>

                                <hr className="solid my-5"/>
                            {false?(
                                <div className="row align-items-center pt-5 pb-3 appear-animation" data-appear-animation="fadeInRightShorter">
                                    <div className="col-md-8 pr-md-5 mb-5 mb-md-0">
                                       
                                    </div>
                                    <div className="col-md-4 px-5 px-md-3">
                                        <img className="img-fluid scale-2 my-4" src="/j2img/equipment_pagewide.jpg" alt="style switcher" />
                                    </div>
                                </div>
                            ):<></>
                                }
                            </div>
                        </div>
                    </div>
                </section>


               

				<section className="parallax section section-text-light section-parallax mt-0 mb-0" data-plugin-parallax data-plugin-options="{'speed': 1.5}" data-image-src="/img/pagewide_parra.png">
					<div className="container py-5 my-5">
						<div className="pricing-table pricing-table-no-gap">
							<div className="col-md-4">
								<div className="plan">
									<div className="plan-header bg-light py-4">
										<h3 className="text-color-dark">Buy now, Pay Later</h3>
									</div>
									
									<div className="plan-features">
										<ul>
											<li>Help ease budget strain</li>
											<li>No payments for up to 90 Days</li>
											<li>no payments for the first 90 days</li>
											<li>36, 48, 60-month contract terms available</li>
                                            <li>FMV and $1.00 buyout lease options available</li>
                                          
										</ul>
									</div>
									<div className="plan-footer">
										<a href="/quote" className="btn btn-dark btn-modern btn-outline py-2 px-4">Get More Info</a>
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="plan plan-featured">
									<div className="plan-header bg-light py-4">
										<h3 className="text-color-dark">Further Reading</h3>
									</div>
									<div className="plan-price">
										<span className="price"><span className="price-unit"></span>Links / Blogs</span>
									
									</div>
									<div className="plan-features">
										<ul>
											<li><a href="https://www.leafnow.com/blog/infographic-section-179-tax-deduction/">Section 179 Tax Deduction</a></li>
											<li><a href="https://www.leafnow.com/blog/cash-is-king-strategies-to-improve-your-bottom-line/">Cash Is King – Strategies to Improve Your Bottom Line</a></li>
											<li><a href="https://www.leafnow.com/blog/a-better-way-to-acquire-equipment/">A Better Way to Acquire Equipment?</a></li>
											<li><a href="https://www.leafnow.com/blog/infographic-5-ways-the-tax-reform-makes-now-a-great-time-to-expand/">5 Ways the Tax Reform Makes Now a Great Time to Expand</a></li>
                                            <li><a href="https://www.leafnow.com/blog/replacement-equipment-you-cant-afford-or-can-you/">Can’t Afford Replacement Equipment? Wait a Minute…</a></li>
                                            <li><a href="https://www.leafnow.com/blog/how-to-get-a-big-discount-on-commercial-equipment-anytime/">How to Get a Big Discount on Commercial Equipment Anytime</a></li>
                                
                                        
                                        </ul>
									</div>
									<div className="plan-footer">
										<a href="/quote" className="btn btn-primary btn-modern py-2 px-4">Get More Info</a>
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="plan">
									<div className="plan-header bg-light py-4">
										<h3 className="text-color-dark">Buy Now, Low Monthly Rental</h3>
									</div>
								
									<div className="plan-features">
										<ul>
											<li>Defer over 90% of the cost until 2021</li>
                                            <li>Minimize payments until 2021</li>
											<li>Approved HP Devices</li>
                                            <li>Pay 1% / monthly for the first 8 months</li>
										
											<li>FMV and $1.00 buyout lease options available</li>
                                       
                                            <li>36, 48, 60-month contract terms available</li>
										</ul>
									</div>
									<div className="plan-footer">
										<a href="/quote" className="btn btn-dark btn-modern btn-outline py-2 px-4">Get More Info</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>



                <div className="row">
                    <div className="col py-3">
                        <hr className="solid mt-5 mb-2"/>
                    </div>
                </div>

                <div className="container pb-1">
                    <div className="row pt-4">
                        <div className="col">
                            <div className="overflow-hidden mb-3">
                                <h2 className="font-weight-bold text-8 mb-0 appear-animation" data-appear-animation="maskUp">
                                Need a short or long-term rental? We can help with that!
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="overflow-hidden">
                                <p className="lead mb-0 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="200">
                                J2 has equipment available for both Wide format and office copiers and desktop device rentals.
                                </p>
                            </div>
                        </div>
                        
                    </div>
                </div>


            </div>
		  

			
		  	<ContactForm email="sales" subject="Contact Form [ Lease ]"/>
				
		  <Footer/>
		  </div>
	  )
  }
}