import React, { Component } from 'react';

import Header from '../header/header.js';
import Footer from '../footer/footer.js';
import ContactForm from './contactform.js';
import  {Helmet} from "react-helmet";
export default class Support extends Component {

  render() {
	  return (
		  <div>
			  <Helmet>
					<title>J2 Blueprint Supply Co. | Help!</title>	
					<meta name="description" content="Submit a support request with J2's Professionals." />
                </Helmet>
		  <Header page_support={true}/>

		  <div role="main" className="main">



<section className="page-header page-header-modern bg-color-dark page-header-md">

	<div className="container">

		<div className="row">





			<div className="col-md-8 order-2 order-md-1 align-self-center p-static">

				<h1 className=""><strong>Contact</strong> Us</h1>



			</div>





			<div className="col-md-4 order-1 order-md-2 align-self-center">

				<ul className="breadcrumb d-block text-md-right breadcrumb-light">

					<li><a href="/">Home</a></li>

					<li className="active">Help</li>

				</ul>

			</div>

		</div>

	</div>

</section>

			<div className="container py-5 mt-3">
				<section className="call-to-action call-to-action-primary mb-5">
					<div className="col-sm-9 col-lg-9">
						<div className="call-to-action-content">
							<h3>Place a <strong>Service Call</strong> online.</h3>
							<p className="mb-0 opacity-7">Our <strong className="font-weight-extra-bold">Service Team</strong> will get back to you right away.</p>
						</div>
					</div>
					<div className="col-sm-3 col-lg-3">
						<div className="">
							<a href="/repair/servicecall" target="_blank" className="btn btn-modern text-2 btn-light border-0">Place a Service Call Now!</a>
						</div>
					</div>
				</section>
			
			</div>

		<ContactForm/>


</div>



		  <Footer/>
		  </div>
	  )
  }
}