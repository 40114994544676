import React from "react";
import ReactDOM from "react-dom";
import scriptLoader from "react-async-script-loader";
//import Car from "../assets/img/car.jpg";
//import Spinner from "./Spinner";
import GridLoader from "react-spinners/GridLoader";



 const CLIENT = {
   sandbox:
     "AaMvKzsI23rWmw51T1p8LVnVkJHCbfX2ScvMEsgp9jl1lhRha5IybiJHp1C9eRY9O8gnZ9jvKJNsyok_",
   production:
     "AaMvKzsI23rWmw51T1p8LVnVkJHCbfX2ScvMEsgp9jl1lhRha5IybiJHp1C9eRY9O8gnZ9jvKJNsyok_"
 };

 const CLIENT_ID =
   process.env.NODE_ENV === "production" ? CLIENT.production : CLIENT.sandbox;

let PayPalButton = null;
class PaypalButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showButtons: false,
      loading: true,
      paid: false
    };

    window.React = React;
    window.ReactDOM = ReactDOM;
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;

    if (isScriptLoaded && isScriptLoadSucceed) {
      PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
      this.setState({ loading: false, showButtons: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

    const scriptJustLoaded =
      !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded;

    if (scriptJustLoaded) {
      if (isScriptLoadSucceed) {
        PayPalButton = window.paypal.Buttons.driver("react", {
          React,
          ReactDOM
        });
        this.setState({ loading: false, showButtons: true });
      }
    }
  }
  createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          description: +"Mercedes G-Wagon",
          amount: {
            currency_code: "USD",
            value: 200
          }
        }
      ]
    });
  };

  onApprove = (data, actions) => {
    actions.order.capture().then(details => {
      const paymentData = {
        payerID: data.payerID,
        orderID: data.orderID
      };
      console.log("Payment Approved: ", paymentData);
      this.setState({ showButtons: false, paid: true });
    });
  };

  render() {
    const { showButtons, loading, paid } = this.state;

    return (
      <div className="main">
        {loading &&  <GridLoader    
        size={50}
        color={"#214d8a"}
        loading={loading}
        />}

        {showButtons && (
          <div>

            {this.props.children}

            <PayPalButton
              createOrder={(data, actions) => this.createOrder(data, actions)}
              onApprove={(data, actions) => this.onApprove(data, actions)}
            />
          </div>
        )}

        {paid && (
          <div className="main">
            
            <h2>
              {this.props.success?this.props.success:(<p>Your purchase was successful!</p>)}
            </h2>
          </div>
        )}
      </div>
    );
  }
}


 export default scriptLoader(`https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}`)(PaypalButton);