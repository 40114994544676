import React, { Component } from 'react';

import Header from '../header/header.js';
import Footer from '../footer/footer.js';


export default class Account extends Component {

  render() {
	  return (
		  <div>
		  <Header page_account={true}/>
		  <Footer/>
		  </div>
	  )
  }
}