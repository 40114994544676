import React, { Component } from 'react';

import Header from '../header/header.js';
import Footer from '../footer/footer.js';
import  {Helmet} from "react-helmet";

export default class Repair extends Component {

  render() {
	  return (
		  <div>

				<Helmet>
					<title>{`${ process.env.REACT_APP_NAME } | Machine Repair and Service`} </title>	
					<meta name="description" content="We employee a full time staff of factory trained repair professionals to ensure your equipment is always running smoothly." />
                </Helmet>
		  <Header page_repair={true}/>
		  
			<div role="main" className="main">



				<section className="page-header page-header-modern page-header-md bg-transparent m-0">

					<div className="container">

						<div className="row">



							<div className="col-md-12 align-self-center p-static order-2 text-center">



								<h1 className="text-dark text-10"><strong>Equipment Repair</strong></h1>

<span className="sub-title text-dark">Factory trained repair professionals.</span>

							</div>



							<div className="col-md-12 align-self-center order-1">



								<ul className="breadcrumb d-block text-center">

									<li><a href="/">Home</a></li>

									<li className="active">Equipment Repair</li>

								</ul>

							</div>

						</div>

					</div>

				</section>

				<hr className="m-0" />

				
				<div className="container py-5 mt-3">
				
				<section className="call-to-action call-to-action-primary mb-5">
					<div className="col-sm-9 col-lg-9">
						<div className="call-to-action-content">
							<h3>Place a <strong>Service Call</strong> online.</h3>
							<p className="mb-0 opacity-7">Our <strong className="font-weight-extra-bold">Service Team</strong> will get back to you right away.</p>
						</div>
					</div>
					<div className="col-sm-3 col-lg-3">
						<div className="">
							<a href="/repair/servicecall" target="_blank" className="btn btn-modern text-2 btn-light border-0">Place a Service Call Now!</a>
						</div>
					</div>
				</section>
			


					<div className="row">

						<div className="col-lg-8">

							<div className="overflow-hidden mb-2">

								<h2 className="font-weight-normal text-7 mb-2 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="200">We fix <strong className="font-weight-extra-bold">problems.</strong></h2>

							</div>

							<div className="overflow-hidden mb-4">

								<p className="lead mb-0 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="400">We stand behind every piece of equipment we sell. We can provide all inclusive service/supply agreements for your equipment or provide factory authorized service and repair as you need it. </p>

							</div>

							<p className="text-color-light-3 mb-4 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="600">Our technicians are trained by the manufacturers to repair your equipment. They have years of experience working and the wealth of technical knowledge needed to keep your operations running smoothly.</p>

						</div>

						<div className="col-lg-4">

							<div className="testimonial testimonial-primary appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="800">
							<p>Join a Remote Support Session</p>
							<a href="http://www.logmein123.com"><img src="/img/logos/rescue-logo-desktop.svg" width="100%" alt="Rescue Log Me In"/></a>
							
								
								
								{false?(<blockquote><p className="mb-0">Quote from J2 Customer about service supply Agreements</p>
								</blockquote>):(<></>)}
									


							</div>

						</div>

					</div>



				</div>



				<section className="section section-default border-0 m-0">

					<div className="container py-4">



						<div className="row pb-4">

							<div className="col-md-8">

								<div className="appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="200">

									<h4 className="mt-2 mb-2">Repair <strong>Services</strong></h4>



									<div className="accordion accordion-modern accordion-modern-grey-scale-1 without-bg mt-4" id="accordion11">

										
									<div className="card card-default mb-2">

										<div className="card-header">

											<h4 className="card-title m-0">

												<a className="accordion-toggle text-3" data-toggle="collapse" data-parent="#accordion11" href="#collapse11One">

													Service Call

												</a>

											</h4>

										</div>

										<div id="collapse11One" className="collapse show">

											<div className="card-body mt-3">

											<p>If you are having issues with your machine, please choose ‘Place a Service Call’ below.  Be sure to list which machine, a detailed description of the machine issue (list error code if on screen), and contact info of end user who is having the issue.</p>





												<a href="/service/repair" className="btn btn-modern btn-dark">Place a Service Call</a>
												<br/><br/>

											</div>

										</div>

									</div>
										
										<div className="card card-default mb-2">

											<div className="card-header">

												<h4 className="card-title m-0">

													<a className="accordion-toggle text-3" data-toggle="collapse" data-parent="#accordion11" href="#collapse11Five">

														Service / Supply Agreements

													</a>

												</h4>

											</div>

											<div id="collapse11Five" className="collapse show">

												<div className="card-body mt-3">

													
											<p>Service supply agreements</p>

<p>Looking to have comfort in knowing your machine is covered top to bottom?  A Service/Supply agreement would take care of that need.  We offer many agreement options, from just supplies to covering every part of your machine.  All of our contracts include a 1 hour response time with a 4 hour average onsite commitment.  Our factory certified technicians can tackle any problem your machine has.</p>
<p>If you have any questions or would like a quote on a service/supply agreement, please give our office a call at 360-696-1861.  </p>

													


													<a href="/quote" className="btn btn-modern btn-dark">Request Quote</a>
													<br/><br/>
												</div>

											</div>

										</div>

										<div className="card card-default mb-2">

											<div className="card-header">

												<h4 className="card-title m-0">

													<a className="accordion-toggle text-3" data-toggle="collapse" data-parent="#accordion11" href="#collapse11Two">

														Remote Troubleshooting

													</a>

												</h4>

											</div>

											<div id="collapse11Two" className="collapse show">

												<div className="card-body mt-3">

													<p> 

Many issues can be fixed remotely and absolutely should! J2 utilizes the Rescue Log Me In to safely and securely login to your system to help you figure out why you can’t print!  No matter if you have a Mac or a Windows system we can help!  When time is of the essence, don’t hesitate to call us for a remote support session.</p>

												<a href="http://www.logmein123.com"><img src="/img/logos/rescue-logo-desktop.svg" alt="Rescue Log Me In"/></a><br/><br/>
													



													<a href="/callme" className="btn btn-modern btn-dark">Call me!</a>
													<br/><br/>
												</div>

											</div>

										</div>

										<div className="card card-default mb-2">

											<div className="card-header">

												<h4 className="card-title m-0">

													<a className="accordion-toggle text-3" data-toggle="collapse" data-parent="#accordion11" href="#collapse11Three">

														Onsite Troubleshooting

													</a>

												</h4>

											</div>

											<div id="collapse11Three" className="collapse show">

												<div className="card-body mt-3">

													<p>When onsite troubleshooting and repair is required make sure you're dealing with factory certified technicians. Your equipment is a critical part of your workflow and demands expert service. Our manufacturer trained service teams have access to all factory technical service bulletins, up to date parts listings, the latest firmware versions, software bulletins and direct lines of communication with the OEM. When you need your machine repaired only trust factory certified technicians. 
All J2 technicians are manufacturer trained and certified before they are even allowed to set foot in your office.</p>


													


													<a href="/callme" className="btn btn-modern btn-dark">Call me!</a>
													<br/><br/>

												</div>

											</div>

										</div>

										<div className="card card-default mb-2">

											<div className="card-header">

												<h4 className="card-title m-0">

													<a className="accordion-toggle text-3" data-toggle="collapse" data-parent="#accordion11" href="#collapse11Four">

														Trade Ins / Upgrades

													</a>

												</h4>

											</div>

											<div id="collapse11Four" className="collapse show">

												<div className="card-body mt-3">

													<p>Sometimes the cost of a repair just does not make any financial sense. It unfortunate when this happens, but J2 has you covered. The entire cost of the repair on your machine will be credited towards the purchase of a new machine from J2.</p>


													



													<a href="/callme" className="btn btn-modern btn-dark">Call Me!</a>
													<br/><br/>

												</div>

											</div>

										</div>

									

									</div>

								</div>



							</div>

							<div className="col-md-4">

								<div className="appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="400">

									<div className="owl-carousel owl-theme dots-inside mb-0 pb-0" data-plugin-options="{'items': 1, 'autoplay': true, 'autoplayTimeout': 4000, 'margin': 10, 'animateOut': 'fadeOut', 'dots': false}">

										<div className="pb-5">

											<img alt="" className="img-fluid rounded box-shadow-3" src="j2img/printer_repair.png" />

										</div>

									

									</div>



									<div className="toggle toggle-primary toggle-simple" data-plugin-toggle>

								

										

											<h4 className="pt-2">A la carte Services</h4>

										

												<p>In addition to our service and supply agreements our factory authorized technicians can repair and refurbish your equipment to OEM specs.</p>

										

											<h4 className="pt-2">Our Team</h4>

											

												<p>With our factory authorized and trained technicians, your equipment will be up and running with as little down time as possible. As a Factory authorized dealer for Hewlett Packard{/* , OCE, and Canon*/} wide format plotters, as well as Kyocera small format office equipment, J2 only uses the highest quality OEM parts for your equipment. You can also bring the machine to us if it is more convenient for you, either way, the J2 Service Dept. has the know how to get it running right.</p>

											

									</div>

								</div>

							</div>

						</div>



					</div>

				</section>



			</div>
		  
		  
		  
		  <Footer/>
		  </div>
	  )
  }
}