import React, { Component } from 'react';

import Header from '../header/header.js';
import Footer from '../footer/footer.js';
import ContactForm from '../support/contactform.js';
import  {Helmet} from "react-helmet";
import postscribe from 'postscribe';

import { Link } from "react-router-dom";

//import IframeResizer from 'iframe-resizer-react';


export default class EquipmentList extends Component {

	constructor(props) {
		super(props);
		this.state = {
					  machines:[],
					  myExternalLib:null
					 }

		this.handleScriptInject = this.handleScriptInject.bind(this);
	}

	componentDidMount() {
		fetch("/data/Machinesnc.json")
		.then(response => response.json())
		.then(data => {
						//console.log(data);
				this.setState({machines:data});
		})
	  if (this.props.cat === "mfp") {
		var desc = '//syndication.inc.hp.com/inpage/contentid.min.js#Yz1zaG93Y2FzZS91cy9lbi9hMy1tYW5hZ2VkJm09TkEmY2M9dXMmcD0zLUhTRTQtOTIyMg';
		

		postscribe('#postscribe', '<script src="'+desc+'"></script>');
		//	
	  }
	}

	handleScriptInject({ scriptTags }) {
        if (scriptTags) {
            const scriptTag = scriptTags[0];
            scriptTag.onload = () => {
                // I don't really like referencing window.
			//	console.log(`Flexsis loaded!`, window.myExternalLib);
			//	global.window.FlixjQ("#flixsis").SIS({descriptor: 'OTI4JjE2MzQzJnVzX2VuJnByaW50ZXJzLXNjYW5uZXJzLmh0bWw='});

                this.setState({
                    myExternalLib: window.myExternalLib
                });
            };
        }
    }

	renderMachines(cat) {
		var rows = "";
		var row="";
    
        console.log("Rendering " + this.state.machines[cat].length + " Machines.");
		if (this.state.machines[cat].length ===0) {
				if (cat === "mfp")
					rows=(<></>)
				else
					rows=(<div className="row mb-5 pb-3"><h1>No Machines Found</h1></div>)
		} else
		for(var i =this.state.machines[cat].length-1; i >=0 ;i--) {
           // console.log(i);
			var item = this.state.machines[cat][i];
			row = ( <>
            
                
				<div className="col-md-6 col-lg-4 mb-5 mb-lg-0 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200" >
								<h4 className="mb-4">{item.name}</h4>
	
								<div className="card">
								<a href={"/equipment/"+this.props.cat+"/"+i} >
									<img className="card-img-top" src={"/j2img/" + item.image_url} alt="Product Preview" />
									</a>
									<div className="card-body">
										<h4 className="card-title mb-1 text-4 font-weight-bold">{item.oneliner?item.oneliner:item.name}</h4>
										<ul>{item.features.map((feature)=>{return(<li key={feature}>{feature}</li>)})}</ul>
									
										<Link prefetch to={"/equipment/"+this.props.cat+"/"+i} className="read-more text-color-primary font-weight-semibold text-2">Read More <i className="fas fa-angle-right position-relative top-1 ml-1"></i></Link>
									</div>
								</div>
							</div>
							{row}
					</>
			)
			if (i%3===0) {
				
				rows = (<><div className="row mb-5 pb-3"> {row} </div>{rows}</>);
				row="";
	
			} 
		}
		return rows;
	}

  render() {

    var rows = "<h1>Loading</h1>";
    if (this.state.machines[this.props.cat] !==undefined) 
        rows = this.renderMachines(this.props.cat);
	  return (
		<div>
			 {this.props.cat==="mfp"?
				<Helmet script={[{ src: "//media.flixsyndication.net/js/v2/flixmedia.sis.https.js" }]}
                // Helmet doesn't support `onload` in script objects so we have to hack in our own
                onChangeClientState={(newState, addedTags) => this.handleScriptInject(addedTags)}>
>
					<title>{process.env.REACT_APP_NAME} |  Print and Scan Solutions -  {this.props.name} {(this.props.cat !=="scanners")?"Printers":""}</title>	
					<meta name="description" content="We sell and lease everything you need for printing and scanning. Large Format, Office MFP, Scanners and more!" />
					
			    </Helmet>
				:
				<Helmet>
>
					<title>{process.env.REACT_APP_NAME} |  Print and Scan Solutions -  {this.props.name} {(this.props.cat !=="scanners")?"Printers":""}</title>	
					<meta name="description" content="We sell and lease everything you need for printing and scanning. Large Format, Office MFP, Scanners and more!" />
					
			    </Helmet>

	  		}

		<Header page_equipment={true}/>					
		

		<section className="page-header page-header-modern bg-color-light-scale-1 page-header-md">

<div className="container">

  <div className="row">





	  <div className="col-md-8 order-2 order-md-1 align-self-center p-static">

		  <h1 className="text-dark">{this.props.name} <strong> {(this.props.cat !=="scanners")?"Printers":""}</strong></h1>

<span className="sub-title text-dark">{this.props.callout}</span>

	  </div>





	  <div className="col-md-4 order-1 order-md-2 align-self-center">

		  <ul className="breadcrumb d-block text-md-right">

			  <li><a href="/">Home</a></li>

			  <li><a href="/equipment">Equipment</a></li>

			  <li className="active">{this.props.name}</li>


		  </ul>

	  </div>

  </div>

</div>

</section>
		
			  <section className="">

				  <div className={this.props.cat !== "mfp"?("container"):("")} style={{width:"100%"}}>
						{rows}
						<div className="holds-the-iframe">
			  				<div id="postscribe"></div>
							<div id="flixsis"></div>
						
					<script type="text/javascript">
											
					</script>
				</div>
				  </div>

			  </section>
			  
			  <ContactForm email="sales" subject={"Contact Form [Equipment - ["+this.props.name+"]"} quote={true}/>
			  
		
		<Footer/>
		</div>
	  )
  }
}