import React, { Component } from 'react';

import Header from '../../header/header.js';
import Footer from '../../footer/footer.js';
import ContactForm from '../../support/contactform.js';

import  {Helmet} from "react-helmet";
export default class ServicesPrintSmallFormat extends Component {

  render() {
	  return (
		  <div>
			   <Helmet>
					<title>{`${ process.env.REACT_APP_NAME } |  Cards, Flyers, Brochures and more!`} </title>	
					<meta name="description" content="J2's digital press and precision folding/cutting systems can produce professional and repeatable." />
                </Helmet>
		  <Header page_services={true}/>
		  
<div role="main" className="main">

	<section className="page-header page-header-classic page-header-md">

		<div className="container">

			<div className="row">

				<div className="col-md-8 order-2 order-md-1 align-self-center p-static">

					<h1 data-title-border>Cards, Flyers &amp; Brochures</h1>

				</div>

				<div className="col-md-4 order-1 order-md-2 align-self-center">

					<ul className="breadcrumb d-block text-md-right">

						<li><a href="/">Home</a></li>
						<li><a href="/services">Services</a></li>

						<li className="active">Cards, Flyers &amp; Brochures</li>

					</ul>

				</div>

			</div>

		</div>

	</section>



	<div className="container pb-1">



		<div className="row pt-4">

			<div className="col">

				<div className="overflow-hidden mb-3">

					<h2 className="font-weight-bold text-8 mb-0 appear-animation" data-appear-animation="maskUp">

						We Are Here To Help You

					</h2>

				</div>

			</div>

		</div>



		<div className="row">

			<div className="col-lg-10">

				<div className="overflow-hidden">

					<p className="lead mb-0 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="200">

						We've been in the print business for more than a quarter century. We <span className="alternative-font">know</span> printing. Our talented team can help you get the output you deserve, whether its Engineering prints or fine artwork, we've got you covered.

					</p>

				</div>

			</div>

			<div className="col-lg-2 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="500">

				<a target="_new" href="https://uploadjob.j2b.com/upload/" className="btn btn-modern btn-dark mt-1">Send your Files!</a>

			</div>

		</div>



		<div className="row">

			<div className="col py-3">

				<hr className="solid mt-5 mb-2"/>

			</div>

		</div>



		

	</div>



	<section className="section bg-color-grey section-height-3 border-0 mt-5 mb-0">

		<div className="container">



			<div className="row">

				<div className="col">



					<div className="row align-items-center pt-4 appear-animation" data-appear-animation="fadeInLeftShorter">

						<div className="col-md-4 mb-4 mb-md-0">

							<img className="img-fluid scale-2 pr-5 pr-md-0 my-4" src="/j2img/cardsetc.png" alt="layout styles" />

						</div>

						<div className="col-md-8 pl-md-5">

							<h2 className="font-weight-normal text-6 mb-3"><strong className="font-weight-extra-bold">Business</strong> Cards, Flyers &amp; Brochures</h2>

							<p className="text-4">With our digital press we can do production runs of your business cards, flyers, brochures and more at a fraction of the cost of offset printing</p>

							<p>If you need a short run or high volume production output our team of highly trained professionals can get you the product you deserve with quick turn arounds and competative pricing.</p>

						</div>

					</div>



					<hr className="solid my-5"/>



					<div className="row align-items-center pt-5 pb-3 appear-animation" data-appear-animation="fadeInRightShorter">

						<div className="col-md-8 pr-md-5 mb-5 mb-md-0">

							<h2 className="font-weight-normal text-6 mb-3"><strong className="font-weight-extra-bold">Our Duplo</strong> Cuts, Folds and Creases</h2>

		  <p className="text-4">Automated and perfect cuts, folds and creases</p>

							<p>Consistency is key when you need to cut fold or crease your cards, menus, brochures etc. Our duplo machine gives us repeatable and precision that is unmatched in the industry.</p>

						</div>

						<div className="col-md-4 px-5 px-md-3">

							<img className="img-fluid scale-2 my-4" src="/img/duplo.jpg" alt="duplo" />

						</div>

					</div>

				</div>

			</div>

		</div>

	</section>				


	<ContactForm email="help" subject="Contact Form [ Small Format Services Page ]"/>



</div>
		  
		  
		  
		  <Footer/>
		  </div>
	  )
  }
}