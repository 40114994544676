
import React, { Component } from 'react';

import Header from '../../header/header.js';
import Footer from '../../footer/footer.js';
import ContactForm from '../../support/contactform.js';

import  {Helmet} from "react-helmet";
export default class ServicesPrintLamination extends Component {

  render() {
	  return (
		  <div>
			   <Helmet>
					<title>{`${ process.env.REACT_APP_NAME } | Lamination and Mounting`} </title>	
					<meta name="description" content="J2 can laminate and mount your existing prints with a wide range of laminations and mounting substrates" />
                </Helmet>
		  <Header page_services={true}/>
		  
<div role="main" className="main">

	<section className="page-header page-header-classic page-header-md">

		<div className="container">

			<div className="row">

				<div className="col-md-8 order-2 order-md-1 align-self-center p-static">

					<h1 data-title-border>Business Documents</h1>

				</div>

				<div className="col-md-4 order-1 order-md-2 align-self-center">

					<ul className="breadcrumb d-block text-md-right">

						<li><a href="/">Home</a></li>

						<li><a href="/services">Services</a></li>

						<li className="active">Lamination &amp; Mounting</li>

					</ul>

				</div>

			</div>

		</div>

	</section>



	<div className="container pb-1">



		<div className="row pt-4">

			<div className="col">

				<div className="overflow-hidden mb-3">

					<h2 className="font-weight-bold text-8 mb-0 appear-animation" data-appear-animation="maskUp">

						We Are Here To Help You

					</h2>

				</div>

			</div>

		</div>



		<div className="row">

			<div className="col-lg-10">

				<div className="overflow-hidden">

					<p className="lead mb-0 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="200">

						We've been in the print business for more than a quarter century. We <span className="alternative-font">know</span> printing. Our talented team can help you get the output you deserve, whether its Engineering prints or fine artwork, we've got you covered.

					</p>

				</div>

			</div>

			<div className="col-lg-2 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="500">

				<a target="_new" href="https://uploadjob.j2b.com/upload/" className="btn btn-modern btn-dark mt-1">Send your Files!</a>

			</div>

		</div>



		<div className="row">

			<div className="col py-3">

				<hr className="solid mt-5 mb-2"/>

			</div>

		</div>

	</div>



	<section className="section bg-color-grey section-height-3 border-0 mt-5 mb-0">

		<div className="container">



			<div className="row">

				<div className="col">



					<div className="row align-items-center pt-4 appear-animation" data-appear-animation="fadeInLeftShorter">

						<div className="col-md-4 mb-4 mb-md-0">

							<img className="img-fluid scale-2 pr-5 pr-md-0 my-4" src="/img/laminations.jpg" alt="laminations" />

						</div>

						<div className="col-md-8 pl-md-5">

							<h2 className="font-weight-normal text-6 mb-3"><strong className="font-weight-extra-bold">Large and Small</strong> format lamination and mounting</h2>

							
							


                            <p>Wide and Small Format Lamination and Mounting
        </p><p>

 We can laminate and mount your large and small format prints and documents with our pocket and pressure roller laminators. We offer a wide variety of lamination materials and mounting substrates. Make your posters last and presentations pop with our high quality laminate films. 
 
 </p><p>
 
 J2 can also mount your existing prints to a wide variety of rigid substrates, including Foam board and Gator board
 
 </p><p>
 
 If you would prefer, J2 can also print directly on any flat material (upto 2" thick). We can save you money by printing your digital files directly the the medium of your choice! This also opens up a wide variety of exotic materials such as metals, composites and other flat surfaces such as wood and clear polycarbonate or glass panels! 


 </p>



                            
						</div>

					</div>



					<hr className="solid my-5"/>



					

				</div>

			</div>

		</div>

	</section>				


	<ContactForm email="help" subject="Contact Form [ Business Services Page ]"/>



</div>
		  
		  
		  
		  <Footer/>
		  </div>
	  )
  }
}


