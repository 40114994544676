import React, { Component } from 'react';

import Header from '../header/header.js';
import Footer from '../footer/footer.js';
import ContactForm from '../support/contactform.js';
import NoMatch from '../404/404.js';
import  {Helmet} from "react-helmet";
import IframeResizer from 'iframe-resizer-react';

export default class EquipmentView extends Component {

	constructor(props) {
		super(props);
		this.state = {machines:[],ready:false}
	}

	componentDidMount() {
		fetch("/data/Machinesnc.json")
  .then(response => response.json())
  .then(data => {
	  			//console.log(data);
				this.setState({machines:data,ready:true});
  	})
	}



	renderMachine(cat,id) {
		
		var row="";
    
       // console.log("Rendering " + this.state.machines[cat].length + " Machines.");
        if (this.state.machines[cat] !== undefined)
        if (this.state.machines[cat][id] !== undefined) {
            var item = this.state.machines[cat][id];

            var datasheet = (
                <a href={"/"+item.datasheet_url} className="btn btn-modern btn-dark mt-3">Download Datasheet</a>
            );
            var video = (
                <a target="_new" href={item.video_url} className="btn btn-modern btn-dark mt-3">View Product Video</a>
            );

            if (item.datasheet_url === "")
                datasheet = "";
            if (item.video_url === "")
                video ="";
                if (item.video_url === undefined)
                video ="";
            
			row = ( <>
            
            <div className="row py-4 mb-2">
						<div className="col-md-7 order-2">
							<div className="overflow-hidden">
								<h2 className="text-color-dark font-weight-bold text-8 mb-0 pt-0 mt-0 appear-animation animated maskUp appear-animation-visible" data-appear-animation="maskUp" data-appear-animation-delay="300" >{item.name}</h2>
							</div>
							<div className="overflow-hidden mb-3">
								<p className="font-weight-bold text-primary text-uppercase mb-0 appear-animation animated maskUp appear-animation-visible" data-appear-animation="maskUp" data-appear-animation-delay="500" >Details</p>
							</div>
							<p className="lead appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="700" >{item.description}</p>
							<ul className="pb-3 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800" >

                            {item.features.map((feature)=>{return(<li key={feature}>{feature}</li>)})}

                            </ul>
							<hr className="solid my-4 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="900" />
							<div className="row align-items-center appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1000" >
								<div className="col-lg-6">
                                    {video}
									{datasheet}
								
								</div>
								<div className="col-sm-6 text-lg-right my-4 my-lg-0">
                                
								</div>
							</div>
						</div>
						<div className="col-md-5 order-md-2 mb-4 mb-lg-0 appear-animation animated fadeInRightShorter appear-animation-visible" data-appear-animation="fadeInRightShorter" >
							<img src={"/j2img/"+item.image_url} className="img-fluid mb-2" alt="description of equipment"/>
                           
						</div>
					</div>
                    {item.hp_3dseries?
                    <div className="row">
                        <div className="col-lg-12">
                        <IframeResizer 
                                heightCalculationMethod="lowestElement"
                                src={"https://hpdesignjetvirtualdemo.com/360/#/virtual-demo/"+item.hp_3dseries+"/"+item.hp_3dmodel+"/"}
                                inPageLinks
                            
                                bodyMargin="0px"
                                style={{ width: '0px', minWidth: '100%',border:'0px',height:'720px'}}
                            />
                        </div>
                    </div>
                    :<></>}


                    </>);

            } else {
                row = "<h1>Printer not Found</h1>";
            }
                    
            return row;
			
	
	}

  render() {

    var rows = "<h1>Loading</h1>";
    var name = "<h2>loading</h2>";
    var breadcrumb = "loading";
    var callout = "";
    
    if (this.state.ready) { 
    if (this.state.machines[this.props.cat] !== undefined)
    if (this.state.machines[this.props.cat][this.props.id] !== undefined) {

            
        if (this.state.machines[this.props.cat] !==undefined) {
            rows = this.renderMachine(this.props.cat,this.props.id);
            breadcrumb = this.state.machines[this.props.cat][this.props.id].name;
            name = breadcrumb;


        }


        return (
            <div>

                <Helmet>
					<title>{process.env.REACT_APP_NAME} | {name}</title>	
					<meta name="description" content={"Buy, Lease, Repair - "+ name} />
                </Helmet>
            <Header page_equipment={true}/>



            <section className="page-header page-header-modern bg-color-light-scale-1 page-header-md">

    <div className="container">

    <div className="row">





        <div className="col-md-8 order-2 order-md-1 align-self-center p-static">

            <h1 className="text-dark"><strong>{name}</strong></h1>

    <span className="sub-title text-dark">{callout}</span>

        </div>





        <div className="col-md-4 order-1 order-md-2 align-self-center">

            <ul className="breadcrumb d-block text-md-right">

                <li><a href="/">Home</a></li>

                <li><a href="/equipment">Equipment</a></li>

                <li><a href={"/equipment/" + this.props.cat }>{this.props.cat}</a></li>

                <li className="active">{breadcrumb}</li>


            </ul>

        </div>

    </div>

    </div>

    </section>
            
                <section className="">

                    <div className="container">
                            {rows}
                    </div>

                </section>
                
                <ContactForm email="sales" subject={"Contact Form [Equipment - ["+name+"]"} quote={true}/>
            
            <Footer/>
            </div>
        )
    } else {
        return (<NoMatch/>);
    }

    return (<NoMatch/>)
    } else
     return (null);
    }
}