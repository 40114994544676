import React, { Component } from 'react';

import Header from '../../header/header.js';
import Footer from '../../footer/footer.js';
import ContactForm from '../../support/contactform.js';

import  {Helmet} from "react-helmet";
export default class ServicesBidPlancenter extends Component {

    componentDidMount() {
        setTimeout(() => {
            window.initParallax();
            window.setupParralax();
           // window.theme.pluginParralax.ini
		}, 500);
   
    }

  render() {
	  return (
		  <div>
			   <Helmet>
					<title>J2 Blueprint Supply Co. | Online Plancenters</title>	
					<meta name="description" content="J2 can host your projects in our plancenter or provide you with the controls to your very own online plan room." />
                </Helmet>
		  <Header page_services={true}/>
		  
<div role="main" className="main">

<section className="page-header page-header-modern page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-7" style={{backgroundImage: "url(/img/plancenter/header2.jpg)"}}>

<div className="container">

    <div className="row mt-5">

        <div className="col-md-12 align-self-center p-static order-2 text-center">

            <h1 className="text-10">Private <strong>Plancenter</strong> Services</h1>

            <span className="sub-title">Take control of your own private online planroom</span>

        </div>

        <div className="col-md-12 align-self-center order-1">

            <ul className="breadcrumb breadcrumb-light d-block text-center">

                <li><a href="/">Home</a></li>

                <li className="active">Plancenter Services</li>

            </ul>

        </div>

    </div>

</div>

</section>



<div className="container py-5">



<div className="row pb-4 mt-2">

    <div className="col-lg-4 appear-animation" data-appear-animation="fadeInRightShorter">

        <div className="feature-box feature-box-style-2">

            <div className="feature-box-icon">

                <i className="icons icon-support text-color-primary"></i>

            </div>

            <div className="feature-box-info">

                <h4 className="font-weight-bold mb-2">Private Contractor Lists</h4>

                <p>You've got control of your own list of contractors. Invite the people you <span className="alternative-font">trust</span> to bid on your projects.</p>

            </div>

        </div>

    </div>

    <div className="col-lg-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="200">

        <div className="feature-box feature-box-style-2">

            <div className="feature-box-icon">

                <i className="icons icon-layers text-color-primary"></i>

            </div>

            <div className="feature-box-info">

                <h4 className="font-weight-bold mb-2">Unlimited Projects</h4>

                <p>Archive your old planroom and post your new projects all in one place.</p>

            </div>

        </div>

    </div>

    <div className="col-lg-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="400">

        <div className="feature-box feature-box-style-2">

            <div className="feature-box-icon">

                <i className="icons icon-menu text-color-primary"></i>

            </div>

            <div className="feature-box-info">

                <h4 className="font-weight-bold mb-2">Unlimited Users</h4>

                <p>We dont put any limits on the number of people you can invite to view your jobs.</p>

            </div>

        </div>

    </div>

</div>

<div className="row pb-4">

    <div className="col-lg-4 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="800">

        <div className="feature-box feature-box-style-2">

            <div className="feature-box-icon">

                <i className="icons icon-doc text-color-primary"></i>

            </div>

            <div className="feature-box-info">

                <h4 className="font-weight-bold mb-2">Desktop, Mobile, Tablet</h4>

                <p>Access all of your projects from whatever device you have at hand.</p>

            </div>

        </div>

    </div>

    <div className="col-lg-4 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="600">

        <div className="feature-box feature-box-style-2">

            <div className="feature-box-icon">

                <i className="icons icon-user text-color-primary"></i>

            </div>

            <div className="feature-box-info">

                <h4 className="font-weight-bold mb-2">Adminstrative Control</h4>

                <p>You control who has administrative control over your projects. Grant users rights on site wide or per project basis.</p>

            </div>

        </div>

    </div>

    <div className="col-lg-4 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="400">

        <div className="feature-box feature-box-style-2">

            <div className="feature-box-icon">

                <i className="icons icon-screen-desktop text-color-primary"></i>

            </div>

            <div className="feature-box-info">

                <h4 className="font-weight-bold mb-2">Online Previews</h4>

                <p>Many documents can be viewed right inside your planroom. No downloads, No plugins required.</p>

            </div>

        </div>

    </div>

</div>


</div>				



<section className="section section-primary border-top-0 mb-0">

<div className="container">

    <div className="row counters counters-sm counters-text-light">

        <div className="col-sm-6 col-lg-3 mb-5 mb-lg-0">

            <div className="counter">

                <strong data-to="30000" data-append="+">0</strong>

                <label className="opacity-5 text-4 mt-1">Users</label>

            </div>

        </div>

        <div className="col-sm-6 col-lg-3 mb-5 mb-lg-0">

            <div className="counter">

                <strong data-to="2500" data-append="+">0</strong>

                <label className="opacity-5 text-4 mt-1">Projects</label>

            </div>

        </div>

        <div className="col-sm-6 col-lg-3 mb-5 mb-sm-0">

            <div className="counter">

                <strong data-to="1000000" data-append="+">0</strong>

                <label className="opacity-5 text-4 mt-1">Documents Hosted</label>

            </div>

        </div>

        <div className="col-sm-6 col-lg-3">

            <div className="counter">

                <strong data-to="3000" data-append="+">0</strong>

                <label className="opacity-5 text-4 mt-1">GB Online</label>

            </div>

        </div>

    </div>

</div>

</section>



<div className="container-fluid">

<div className="row align-items-center bg-color-grey">

    <div className="col-lg-6 p-0">

        <section className="parallax section section-parallax custom-parallax-bg-pos-left custom-sec-left h-100 m-0" data-plugin-parallax data-plugin-options="{'speed': 1.5, 'horizontalPosition': '100%'}" data-image-src="/img/plancenter/mobile.jpg" style={{minHeight: "315px"}}>

        </section>

    </div>

    <div className="col-lg-6 p-0">

        <section className="section section-no-border h-100 m-0">

            <div className="row m-0">

                <div className="col-half-section col-half-section-left">

                    <div className="overflow-hidden">

                        <h4 className="mb-0 appear-animation" data-appear-animation="maskUp"><span className="text-4 font-weight-bold pt-2 d-block text-dark text-decoration-none pb-1">Mobile Access</span></h4>

                    </div>

                    <div className="overflow-hidden mb-2">

                        <p className="mb-0 lead text-4 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="200">Access all of your projects on the go.</p>

                    </div>

                    <p className="text-2 mb-0 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400">No matter where you are you'll be able to send invitations, view documents and issue project updates.</p>

                </div>

            </div>

        </section>

    </div>

</div>

<div className="row align-items-center bg-color-grey">

    <div className="col-lg-6 order-2 order-lg-1 p-0">

        <section className="section section-no-border h-100 m-0">

            <div className="row justify-content-end m-0">

                <div className="col-half-section col-half-section-right custom-text-align-right">

                    <div className="overflow-hidden">

                        <h4 className="mb-0appear-animation" data-appear-animation="maskUp"><span className="text-4 font-weight-bold pt-2 d-block text-dark text-decoration-none pb-1">Instant Notifications</span></h4>

                    </div>

                    <div className="overflow-hidden mb-2">

                        <p className="mb-0 lead text-4 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="200">Issue notifications directly to your bidders</p>

                    </div>

                    <p className="text-2 mb-0 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400">Automatically managed bidders and viewers lists, you can notify exactly who you need to with the click of a mouse. </p>

                </div>

            </div>

        </section>

    </div>

    <div className="col-lg-6 order-1 order-lg-2 p-0">

        <section className="parallax section section-parallax h-100 m-0" data-plugin-parallax data-plugin-options="{'speed': 1.5, 'horizontalPosition': '100%'}" data-image-src="/img/plancenter/notification.png" style={{minHeight: "315px"}}>

        </section>

    </div>

</div>

<div className="row align-items-center bg-color-grey">

    <div className="col-lg-6 p-0">

        <section className="parallax section section-parallax custom-parallax-bg-pos-left custom-sec-left h-100 m-0" data-plugin-parallax data-plugin-options="{'speed': 1.5, 'horizontalPosition': '100%'}" data-image-src="/img/plancenter/oldnew.jpg" style={{minHeight: "315px"}}>

        </section>

    </div>

    <div className="col-lg-6 p-0">

        <section className="section section-no-border h-100 m-0">

            <div className="row m-0">

                <div className="col-half-section col-half-section-left">

                    <div className="overflow-hidden">

                        <h4 className="mb-0 appear-animation" data-appear-animation="maskUp"><span className="text-4 font-weight-bold pt-2 d-block text-dark text-decoration-none pb-1">All your projects in one place</span></h4>

                    </div>

                    <div className="overflow-hidden mb-2">

                        <p className="mb-0 lead text-4 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="200">Manage upcoming bids and facilities maintenence projects all in one place.</p>

                    </div>

                    <p className="text-2 mb-0 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400">Do you have a room full of old crumbling plansets and specifications? Let us digitize them for you and store them in one streamlined interface. You'll have access to archived projects and current bidsets all in one convenient location.</p>

                </div>

            </div>

        </section>

    </div>

</div>

</div>







	<ContactForm email="help" subject="Contact Form [ Engineering Services Page ]"/>




</div>
		  
		  
		  
		  <Footer/>
		  </div>
	  )
  }
}