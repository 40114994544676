import React, { Component } from 'react';

import Header from '../header/header.js';
import Footer from '../footer/footer.js';

import ContactForm from '../support/contactform.js';
import  {Helmet} from "react-helmet";


export default class Equipment extends Component {


	/*componentDidMount() {
		fetch("/data/Machines.json")
  .then(response => response.json())
  .then(data => console.log(data));
	}
*/


  render() {
	  return (
		  <div>

				<Helmet>
					<title>{`${ process.env.REACT_APP_NAME } |  Print and Scan Solutions`} </title>	
					<meta name="description" content="We are proud to provide the most performant and reliable printers, plotters and scanners on the market" />
                </Helmet>
		  <Header page_equipment={true}/>
		  
				<section className="page-header page-header-modern bg-color-light-scale-1 page-header-md">

					<div className="container">

						<div className="row">





							<div className="col-md-8 order-2 order-md-1 align-self-center p-static">

								<h1 className="text-dark">Print and Scan <strong>Solutions</strong></h1>

<span className="sub-title text-dark">We are proud to provide the most performant and reliable printers, plotters and scanners on the market</span>

							</div>





							<div className="col-md-4 order-1 order-md-2 align-self-center">

								<ul className="breadcrumb d-block text-md-right">

									<li><a href="/">Home</a></li>

									<li className="active">Equipment</li>

								</ul>

							</div>

						</div>

					</div>

				</section>



				<div className="container py-4">



					<div className="row py-3 justify-content-center">

						<div className="col-sm-8 col-md-2 mb-3 mb-md-0 appear-animation" data-appear-animation="fadeIn">
						
							<article>

								<div className="row">

									<div className="col">

										<a href="/equipment/mfp" className="text-decoration-none">

											<img src="j2img/equipment_pagewide.jpg" className="img-fluid hover-effect-2 mb-3" alt="" />

										</a>

									</div>

								</div>

								<div className="row">

									<div className="col">

										<h4 className="mb-0"><a href="/equipment/mfp" className="text-2 text-uppercase font-weight-bold pt-2 d-block text-dark text-decoration-none">HP Business Printers</a></h4>

										<p className="mb-2 lead text-4">With a low cost, color is the new black and white.</p>

										<p className="text-2">Managing your printers and copiers without raising costs or compromising security is a challenge. That’s where HP and J2 can help: With a new line of MFPs and a suite of services to fit your needs, you can take your mind off printing and focus on the rest of your day. Avoid pauses in productivity with the HP A3 / A4 printer or MFP — designed for maximum uptime, so you can count on reliable results day in and day out.</p>

									</div>

								</div>

							</article>
							
						</div>

						<div className="col-sm-8 col-md-2 mb-3 mb-md-0 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="250">

							<article>

								<div className="row">

									<div className="col">

										<a href="/equipment/wide" className="text-decoration-none">

											<img src="j2img/equipment_pagewidexl.jpg" className="img-fluid hover-effect-2 mb-3" alt="" />

										</a>

									</div>

								</div>

								<div className="row">

									<div className="col">

										<h4 className="mb-0"><a href="/equipment/wide" className="text-2 text-uppercase font-weight-bold pt-2 d-block text-dark text-decoration-none">Wide Format MFP Printers</a></h4>

										<p className="mb-2 lead text-4">Get one machine that Scans, Prints and Copies.</p>

										<p className="text-2">Experience the fastest large-format monochrome and color printing with up to 50% savings in total production costs. Print faster than alternatives, even the fastest LEDs. Grow with GIS map and point-of-sale (POS) poster printing to generate new revenue streams.</p>

									</div>

								</div>

							</article>

						</div>

						<div className="col-sm-8 col-md-2 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="500">

							<article>

								<div className="row">

									<div className="col">

										<a href="/equipment/cad" className="text-decoration-none">

											<img src="j2img/equipment/cad/hp_t125.jpg" className="img-fluid hover-effect-2 mb-3" alt="" />

										</a>

									</div>

								</div>

								<div className="row">

									<div className="col">

										<h4 className="mb-0"><a href="/equipment/cad" className="text-2 text-uppercase font-weight-bold pt-2 d-block text-dark text-decoration-none">Wide Format CAD Printers</a></h4>

										<p className="mb-2 lead text-4">Wide Format CAD Printers</p>

										<p className="text-2">The 8-color 60-inch imagePROGRAF PRO-6000S printer designed for the production signage and commercial photography markets exceeds expectations for speed and color consistency in a large format production printer.</p>

									</div>

								</div>

							</article>

						</div>

						<div className="col-sm-8 col-md-2 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="500">

							<article>

								<div className="row">

									<div className="col">

										<a href="/equipment/graphics" className="text-decoration-none">

											<img src="j2img/equipment_prograf.jpg" className="img-fluid hover-effect-2 mb-3" alt="" />

										</a>

									</div>

								</div>

								<div className="row">

									<div className="col">

										<h4 className="mb-0"><a href="/equipment/graphics" className="text-2 text-uppercase font-weight-bold pt-2 d-block text-dark text-decoration-none">Wide Format Graphics Printers</a></h4>

										<p className="mb-2 lead text-4">Wide Format Graphics Printers</p>

										<p className="text-2">The 8-color 60-inch imagePROGRAF PRO-6000S printer designed for the production signage and commercial photography markets exceeds expectations for speed and color consistency in a large format production printer.</p>

									</div>

								</div>

							</article>

						</div>

						<div className="col-sm-8 col-md-2 mb-3 mb-md-0 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="250">

							<article>

								<div className="row">

									<div className="col">

										<a href="/equipment/scanners" className="text-decoration-none">

											<img src="/j2img/equipment/scanners/WT36ART-600_RENDER_DIAGONAL_TEMPLATE_MODERN.png" className="img-fluid hover-effect-2 mb-3" alt="" />

										</a>

									</div>

								</div>

								<div className="row">

									<div className="col">

										<h4 className="mb-0"><a href="/equipment/scanners" className="text-2 text-uppercase font-weight-bold pt-2 d-block text-dark text-decoration-none">Small and Wide Format Scanners</a></h4>

										<p className="mb-2 lead text-4">Small and Wide Format Scanners.</p>

										<p className="text-2">The wide format scanner market has changed dramatically over the last few years. Improvements in
color and grayscale image quality, actual scanner speed and file processing times allow users to be
more productive and realize initial return on investment much faster. Wide format scanner
manufacturers now also incorporate more options and accessories, further broadening the choices
customers can make.</p>

									</div>

								</div>

							</article>

						</div>






					</div>



				</div>
		  
		  
				<section className="section bg-color-primary section-height-3 border-0 mt-4 mb-0">

					<div className="container">



						<div className="row">

							<div className="col-md-10 py-3 mx-md-auto">

								<div className="row pt-2 clearfix">

									<div className="col-lg-6">

										<div className="feature-box feature-box-style-2 reverse appear-animation" data-appear-animation="fadeInRightShorter">

											<div className="feature-box-icon">

												<i className="icon-user-following icons text-color-light"></i>

											</div>

											<div className="feature-box-info">

												<h4 className="mb-2 text-5 text-color-light">Customer Support</h4>

												<p className="mb-4 text-color-light opacity-6">Our factory certified repair technitians are ready to make sure you keep printing</p>

											</div>

										</div>

									</div>

									<div className="col-lg-6">

										<div className="feature-box feature-box-style-2 appear-animation" data-appear-animation="fadeInLeftShorter">

											<div className="feature-box-icon">

												<i className="icon-layers icons text-color-light"></i>

											</div>

											<div className="feature-box-info">

												<h4 className="mb-2 text-5 text-color-light">Print Media</h4>

												<p className="mb-4 text-color-light opacity-6">We stock a complete range of paper &amp; media for all of your printing needs</p>

											</div>

										</div>

									</div>

								</div>

								<div className="row">

									<div className="col-lg-6">

										<div className="feature-box feature-box-style-2 reverse appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="200">

											<div className="feature-box-icon">

												<i className="icon-calculator icons text-color-light"></i>

											</div>

											<div className="feature-box-info">

												<h4 className="mb-2 text-5 text-color-light">Service Agreements</h4>

												<p className="mb-4 text-color-light opacity-6">We actively monitor your equipment to make sure it runs smoothly with no interruptions or downtime.</p>

											</div>

										</div>

									</div>

									<div className="col-lg-6">

										<div className="feature-box feature-box-style-2 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="200">

											<div className="feature-box-icon">

												<i className="icon-star icons text-color-light"></i>

											</div>

											<div className="feature-box-info">

												<h4 className="mb-2 text-5 text-color-light">Service Levels</h4>

												<p className="mb-4 text-color-light opacity-6">You choose the level of service that fits your needs and budget, everthing from simple maintenence to all inclusive parts/service/supplies.</p>

											</div>

										</div>

									</div>

								</div>

								<div className="row">

									<div className="col-lg-6">

										<div className="feature-box feature-box-style-2 reverse appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="400">

											<div className="feature-box-icon">

												<i className="icon-drop icons text-color-light"></i>

											</div>

											<div className="feature-box-info">

												<h4 className="mb-2 text-5 text-color-light">Ink and Toner</h4>

												<p className="mb-0 text-color-light opacity-6">We stock a complete line of inks and toners for all the machines we support.</p>

											</div>

										</div>

									</div>

									<div className="col-lg-6">

										<div className="feature-box feature-box-style-2 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="400">

											<div className="feature-box-icon">

												<i className="icon-mouse icons text-color-light"></i>

											</div>

											<div className="feature-box-info">

												<h4 className="mb-2 text-5 text-color-light">Remote Support</h4>

												<p className="mb-0 text-color-light opacity-6">Sometimes we can fix your problems without an onsite visit, saving you time and money in the process.</p>

											</div>

										</div>

									</div>

								</div>

							</div>

						</div>

				

					</div>



				</section>
				
				<ContactForm email="sales" subject="Contact Form [Equipment]"/>
		  
		  <Footer/>
		  </div>
	  )
  }
}