import React, { Component } from 'react';

import Header from '../header/header.js';
import Footer from '../footer/footer.js';
import  {Helmet} from "react-helmet";

export default class HP extends Component {


    componentDidMount() {
       
       // window.initCarousel();
       // window.setupCarousel();
     //  window.setupSlider();
     //  window.initSlider();
     // window.setupSlider();
       //window.theme.PluginRevSlider.initialize();
       // window.theme.PluginCarousel.initialize();

       window.$("#revolutionSliderCarousel").show().revolution({
        sliderType: "carousel",
        sliderLayout: "fullwidth",
        dottedOverlay: "none",
        delay: 4000,
        navigation: {
            keyboardNavigation: "off",
            keyboard_direction: "horizontal",
            mouseScrollNavigation: "off",
            onHoverStop: "off",
            arrows: {
                style: "tparrows-carousel",
                enable: true,
                hide_onmobile: false,
                hide_onleave: false,
                tmp: '',
                left: {
                    h_align: "left",
                    v_align: "center",
                    h_offset: 30,
                    v_offset: 0
                },
                right: {
                    h_align: "right",
                    v_align: "center",
                    h_offset: 30,
                    v_offset: 0
                }
            }
        },
        carousel: {
            maxRotation: 65,
            vary_rotation: "on",
            minScale: 75,
            vary_scale: "on",
            horizontal_align: "center",
            vertical_align: "center",
            fadeout: "on",
            vary_fade: "on",
            maxVisibleItems: 5,
            infinity: "on",
            space: -150,
            stretch: "off"
        },
        gridwidth: 600,
        gridheight: 600,
        lazyType: "none",
        shadow: 0,
        spinner: "off",
        stopLoop: "off",
        stopAfterLoops: 0,
        stopAtSlide: -1,
        shuffle: "off",
        autoHeight: "off",
        disableProgressBar: "on",
        hideThumbsOnMobile: "off",
        hideSliderAtLimit: 0,
        hideCaptionAtLimit: 0,
        hideAllCaptionAtLilmit: 0,
        debugMode: false,
        fallbacks: {
            simplifyAll: "off",
            nextSlideOnWindowFocus: "off",
            disableFocusListener: false,
        }
    });

    }


  render() {
	  return (
		  <div>
              <Helmet>
					<title>J2 Blueprint Supply Co. | HP Multi function print systems.</title>	
					<meta name="description" content="We sell and service the HP Print systems you need to get business done." />
                </Helmet>
		  <Header page_home={true}/>
		  <div>
		  <section className="page-header page-header-modern bg-color-light-scale-1 page-header-md  mb-0">
					<div className="container-fluid">
						<div className="row align-items-center">

							
							<div className="col">
								<div className="row">
									<div className="col-md-12 align-self-center p-static order-2 text-center">
										<div className="overflow-hidden pb-2">
											<h1 className="text-dark font-weight-bold text-9 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="100">HP Print and Security Symposium</h1>
										</div>
									</div>
									<div className="col-md-12 align-self-center order-1">
										<ul className="breadcrumb d-block text-center appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="300">
											<li><a href="/">Home</a></li>
											<li><a href="/HP">HP</a></li>
											<li className="active">HP Print and Security Symposium</li>
										</ul>
									</div>
								</div>
							</div>

							
						</div>
					</div>
				</section>
            </div>
				<div id="revolutionSliderCarouselContainer" className="rev_slider_wrapper fullwidthbanner-container mb-4" data-alias="" style={{background: "#f3f3f2", height: "600px"}}>
					<div id="revolutionSliderCarousel" className="rev_slider fullwidthabanner" data-version="5.4.8">
						<ul>
							<li data-index="rs-1" data-transition="fade" data-slotamount="7" data-easein="default" data-easeout="default" data-masterspeed="300" data-rotate="0" data-saveperformance="off" data-title="" data-description="">
								<img src="/j2img/jj_slides/slide1.png" alt="" data-bgposition="center center" data-bgfit="contain" data-bgrepeat="no-repeat" className="rev-slidebg" data-no-retina />
							</li>
							<li data-index="rs-2" data-transition="fade" data-slotamount="7" data-easein="default" data-easeout="default" data-masterspeed="300" data-rotate="0" data-saveperformance="off" data-title="" data-description="">
								<img src="/j2img/jj_slides/slide2.png" alt="" data-bgposition="center center" data-bgfit="contain" data-bgrepeat="no-repeat" className="rev-slidebg" data-no-retina/>
							</li>
							<li data-index="rs-3" data-transition="fade" data-slotamount="7" data-easein="default" data-easeout="default" data-masterspeed="300" data-rotate="0" data-saveperformance="off" data-title="" data-description="">
								<img src="/j2img/jj_slides/slide3.jpg" alt="" data-bgposition="center center" data-bgfit="contain" data-bgrepeat="no-repeat" className="rev-slidebg" data-no-retina/>
							</li>
							<li data-index="rs-4" data-transition="fade" data-slotamount="7" data-easein="default" data-easeout="default" data-masterspeed="300" data-rotate="0" data-saveperformance="off" data-title="" data-description="">
								<img src="/j2img/jj_slides/slide4.jpg" alt="" data-bgposition="center center" data-bgfit="contain" data-bgrepeat="no-repeat" className="rev-slidebg" data-no-retina/>
							</li>
							<li data-index="rs-5" data-transition="fade" data-slotamount="7" data-easein="default" data-easeout="default" data-masterspeed="300" data-rotate="0" data-saveperformance="off" data-title="" data-description="">
								<img src="/j2img/jj_slides/slide5.jpg" alt="" data-bgposition="center center" data-bgfit="contain" data-bgrepeat="no-repeat" className="rev-slidebg" data-no-retina/>
							</li>
							
						</ul>
					</div>
				</div>

				<div className="container py-4">

					<div className="row pb-5 pt-2">
						<div className="col-md-7 mb-4 mb-md-0">

							<div className="overflow-hidden mb-2">
								<h2 className="text-color-dark font-weight-normal text-5 mb-0 appear-animation" data-appear-animation="maskUp">HP Print <strong className="font-weight-extra-bold">and Security Symposium</strong></h2>
							</div>

							<p className="appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">J2 is pleased to have you at HP’s Print &amp; Security Symposium.</p>

							<p className="appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400">HP security experts and product specialists will do a deep dive into the current state of print system security and sustainability. This will be an informative event structured to help you make informed decisions about your agency’s existing and future print infrastructure. We hope that you will join us and HP for informative presentations, discussion of best practices and Q&amp;A sessions.</p>

							<hr className="solid my-5 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="500"/>

							<div className="appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="600">
								<strong className="text-uppercase text-1 mr-3 text-dark float-left position-relative top-2">Share</strong>
								<ul className="social-icons">
									<li className="social-icons-facebook"><a href="http://www.facebook.com/" target="_blank" rel="noopener noreferrer" title="Facebook"><i className="fab fa-facebook-f"></i></a></li>
									<li className="social-icons-twitter"><a href="http://www.twitter.com/" target="_blank" rel="noopener noreferrer" title="Twitter"><i className="fab fa-twitter"></i></a></li>
									<li className="social-icons-linkedin"><a href="http://www.linkedin.com/" target="_blank" rel="noopener noreferrer" title="Linkedin"><i className="fab fa-linkedin-in"></i></a></li>
								</ul>
							</div>

						</div>
						<div className="col-md-5 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="900">
							<h2 className="text-color-dark font-weight-normal text-5 mb-2">Todays <strong className="font-weight-extra-bold">Schedule</strong></h2>
							<ul className="list list-icons list-primary list-borders text-2">
								<li><i className="fas fa-caret-right left-10"></i> <strong className="text-color-primary">10:25am - 10:30am</strong> J2 - Welcome to the sessions! </li>
								<li><i className="fas fa-caret-right left-10"></i> <strong className="text-color-primary">10:30am - 11:55am</strong> HP - Today's Security Risks</li>
								<li><i className="fas fa-caret-right left-10"></i> <strong className="text-color-primary">12:00pm - 12:30pm</strong> Lunch</li>
								<li><i className="fas fa-caret-right left-10"></i> <strong className="text-color-primary">12:05pm - 12:30pm</strong> HP - ROAM Services</li>
                                <li><i className="fas fa-caret-right left-10"></i> <strong className="text-color-primary">12:35pm -  1:25pm</strong> HP - New Products / Technology</li>
                                <li><i className="fas fa-caret-right left-10"></i> <strong className="text-color-primary"> 1:30pm -  2:00pm</strong> HP - Sustainability</li>
                                <li><i className="fas fa-caret-right left-10"></i> <strong className="text-color-primary"> 2:05pm -  2:30pm</strong> HP - SDS</li>
                                <li><i className="fas fa-caret-right left-10"></i> <strong className="text-color-primary"> 2:30pm -  3:00pm</strong> Closing / Meet &amp; Greet / Product Demos</li>
                                
							</ul>
						</div>
					</div>

				</div>

			
            

			
            

        
		  <Footer/>
          </div>
		  
	  
	  )
  }
}