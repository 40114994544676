import React, { Component } from 'react';

import Header from '../../header/header.js';
import Footer from '../../footer/footer.js';
import ContactForm from '../../support/contactform.js';

import IframeResizer from 'iframe-resizer-react';
import  {Helmet} from "react-helmet";


export default class HPDesign extends Component {

    constructor(props)
    {
        super(props);
        this.state = { framesrc: "https://designer.hpwallart.com/j2blueprint?external_link=true&sku=PSTR" };

    }


    render() {
        return(
            <div>
                 <Helmet>
				<title>J2 Blueprint Supply Co. |  Document Designer</title>	
			
				<meta name="description" content="Design print ready documents right in your browswer" />
                </Helmet>
            <Header page_services={true}/>
            
              <div role="main" className="main">

              

              <section className="section section-text-light section-background section-center section-overlay-opacity section-overlay-opacity-scale-8" style={{backgroundImage: "url(/j2img/hpdesign/page/donutsNew.jpg"}}>
					<div className="container">
						<div className="row">
							<div className="col">
                        
								<h4 className="mb-0">Create and Print</h4>
								<p className="mb-0">We provide an easy to use self-service deisgn tool or access to our talented graphic designers</p>
							</div>
						</div>
					</div>
				</section>
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">

                    <div className="row">
						<div className="col">
							<div className="heading heading-border heading-middle-border heading-middle-border-center heading-border-xl">
								<h2 className="font-weight-normal">Self service <strong className="font-weight-extra-bold">Design</strong></h2>
							</div>
						</div>
					</div>
                    

                    <div className="row justify-content-center">

                    
                    <div className="col-4 col-sm-4 col-lg-2">
                        <div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
                            <div className="featured-box featured-box-no-borders featured-box-box-shadow" style={{height:"118px"}}>
                                <a href="https://uploadjob.j2b.com/upload/" className="text-decoration-none">
                                    <span className="box-content px-1 py-4 text-center d-block">
                                        <span className="text-primary text-8 position-relative top-3 mt-3"><img src="/j2img/hpdesign/canvas.png" alt="Posters"/></span>
                                        <span className="elements-list-shadow-icon text-default"><img src="/j2img/hpdesign/canvas.png" alt="Posters"/></span>
                                        <span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">Your Tools</span>
                                    </span>
                                </a>
                                
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-8 col-sm-8 col-lg-10">
                        <div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
                            If you're already a seasoned designer we can accept files in most popular image formats, industry standard PDF's and many vector files including files created with the Adobe Creative and Corel Draw suites.
                            <br/><br/><a href="https://uploadjob.j2b.com/upload/" className="btn btn-modern btn-primary">Upload your files now!</a>
                        </div>
                    </div>

                    </div>
                
            
      
                    </div>
                    <div className="col-md-3"></div>
                </div>

                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">

                    <div className="row">
						<div className="col">
							<div className="heading heading-border heading-middle-border heading-middle-border-center heading-border-xl">
								<h2 className="font-weight-normal">Work with our <strong className="font-weight-extra-bold">Designers</strong></h2>
							</div>
						</div>
					</div>
                    <div className="row justify-content-center">

                    
                        <div className="col-4 col-sm-4 col-lg-2">
                            <div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
                                <div className="featured-box featured-box-no-borders featured-box-box-shadow" style={{height:"118px"}}>
                                    <a  href="#contact" className="text-decoration-none">
                                        <span className="box-content px-1 py-4 text-center d-block">
                                            <span className="text-primary text-8 position-relative top-3 mt-3"><img src="/j2img/hpdesign/canvas.png" alt="Design"/></span>
                                            <span className="elements-list-shadow-icon text-default"><img src="/j2img/hpdesign/canvas.png" alt="Design"/></span>
                                            <span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">We Design</span>
                                        </span>
                                    </a>
                                    
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-8 col-sm-8 col-lg-10">
                            <div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
                               If you aren't comfortable designing your own documents you can work with our in house design team to get the prints you want. Please contact us below with as many details about your project as you can provide and one of our talented artists will contact you to go over the details.
                               <br/><br/><a href="#contact" className="btn btn-modern btn-primary">Contact Us</a>
                            </div>
                        </div>
    
                    </div>

           
                
            
      
                    </div>
                    <div className="col-md-3"></div>
                </div>


                <span id="contact"></span>
                <section className="call-to-action call-to-action-dark mb-5">
					<div className="container">
						<div className="row">
							<div className="col-sm-12 col-lg-12 text-center">
								<div className="call-to-action-content text-center">
									<h3>If you have any <strong>questions</strong> about designing for print, reach out, we've got your <strong>answers!</strong></h3>
									
								</div>
							</div>
							
						</div>
					</div>
				</section>
                
                <ContactForm email="help" subject="Contact Form [ HP Poster Design Page ]"/>
                </div>
            <Footer />
            </div>
        );
    }

}






