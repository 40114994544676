import React, { Component } from 'react';

import Header from '../../header/header.js';
import Footer from '../../footer/footer.js';
import ContactForm from '../../support/contactform.js';

import  {Helmet} from "react-helmet";
export default class ServicesPrintEngineering extends Component {

  render() {
	  return (
		  <div>
			   <Helmet>
					<title>{`${ process.env.REACT_APP_NAME } | Engineering / Construction Reprographics`} </title>	
					<meta name="description" content="J2's business was founded in construction reprographics. We're your experts in the field." />
                </Helmet>
		  <Header page_services={true}/>
		  
<div role="main" className="main">

	<section className="page-header page-header-classic page-header-md">

		<div className="container">

			<div className="row">

				<div className="col-md-8 order-2 order-md-1 align-self-center p-static">

					<h1 data-title-border>Engineering / Construction</h1>

				</div>

				<div className="col-md-4 order-1 order-md-2 align-self-center">

					<ul className="breadcrumb d-block text-md-right">

						<li><a href="/">Home</a></li>
						<li><a href="/services">Services</a></li>

						<li className="active">Engineering / Construction</li>

					</ul>

				</div>

			</div>

		</div>

	</section>



	<div className="container pb-1">



		<div className="row pt-4">

			<div className="col">

				<div className="overflow-hidden mb-3">

					<h2 className="font-weight-bold text-8 mb-0 appear-animation" data-appear-animation="maskUp">

						Plans and Specifications are our specialty

					</h2>

				</div>

			</div>

		</div>



		<div className="row">

			<div className="col-lg-10">

				<div className="overflow-hidden">

					<p className="lead mb-0 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="200">

						We've been in the print business for more than a quarter century. We <span className="alternative-font">know</span> printing. Our talented team can help you get the output you deserve, whether its Engineering prints or fine artwork, we've got you covered.

					</p>

				</div>

			</div>

			<div className="col-lg-2 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="500">

				<a target="_new" href="https://uploadjob.j2b.com/upload/" className="btn btn-modern btn-dark mt-1">Send your Files!</a>

			</div>

		</div>



		<div className="row">

			<div className="col py-3">

				<hr className="solid mt-5 mb-2"/>

			</div>

		</div>



		

	</div>



	<section className="section bg-color-grey section-height-3 border-0 mt-5 mb-0">

		<div className="container">



			<div className="row">

				<div className="col">



					<div className="row align-items-center pt-4 appear-animation" data-appear-animation="fadeInLeftShorter">

						<div className="col-md-4 mb-4 mb-md-0">

							<img className="img-fluid scale-2 pr-5 pr-md-0 my-4" src="/img/xl8000.jpg" alt="layout styles" />

						</div>

						<div className="col-md-8 pl-md-5">

							<h2 className="font-weight-normal text-6 mb-3"><strong className="font-weight-extra-bold">Plan</strong> Sheets &amp; Sets</h2>

							<p className="text-4">We've come along way from the old days of diazo prints. Our high speed full color machines reproduce documents with a speed,accuracy and fidelity only dreamed of before. </p>

							<p>It doesn't matter if you only need a few sheets, or 100 sets of 1000, we've got the capacity and expertise needed to get it done quickly and accurately.</p>
							

								<h4> 100% Recycled Media.</h4>
								
								
								<p>We like to use 100% recycled bond paper where possible, but also offer a full selection of canvas, high-gloss photo, matte, vellums, mylars and heavy weight presentation bonds. </p>
								
								
								
								<h4>Plans, Banners, Presentation Graphics, Site Signage and more.</h4>
								
								
								<p>If you just need a few plan sheets or want a whole set, we've got your covered. We can provide you with everything you need 
									for your project. 
								</p>

								<p>Our specialized CAD plotters and scanners will produce high speed prints perfectly to scale everytime.</p>
						

						
						</div>

					</div>



					

				</div>

			</div>

		</div>

	</section>				

	<ContactForm email="help" subject="Contact Form [ Engineering Services Page ]"/>




</div>
		  
		  
		  
		  <Footer/>
		  </div>
	  )
  }
}