import React, { Component } from 'react';

import Header from '../header/header.js';
import Footer from '../footer/footer.js';
import ContactForm from './contactform.js';
import  {Helmet} from "react-helmet";
export default class Quote extends Component {

  render() {
	  return (
		  <div>
			  <Helmet>
					<title>J2 Blueprint Supply Co. | Request a Quote</title>	
					<meta name="description" content="Request a quotation for Services, Supplies or Hardware" />
                </Helmet>
		  <Header page_support={true}/>

		  <div role="main" className="main">



<section className="page-header page-header-modern bg-color-dark page-header-md">

	<div className="container">

		<div className="row">





			<div className="col-md-8 order-2 order-md-1 align-self-center p-static">

				<h1 className="">Request a <strong>Quote</strong></h1>



			</div>





			<div className="col-md-4 order-1 order-md-2 align-self-center">

				<ul className="breadcrumb d-block text-md-right breadcrumb-light">

					<li><a href="/">Home</a></li>

					<li className="active">Support</li>

				</ul>

			</div>

		</div>

	</div>

</section>


		<ContactForm email="service" subject="Contact Form [Service Quote]" quote={true}/>


</div>



		  <Footer/>
		  </div>
	  )
  }
}