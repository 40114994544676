import React, { Component } from 'react';

import Header from '../../header/header.js';
import Footer from '../../footer/footer.js';
import ContactForm from '../../support/contactform.js';

import  {Helmet} from "react-helmet";
export default class ServicesPrintSigns extends Component {

  render() {
	  return (
		  <div>
			   <Helmet>
					<title>{`${ process.env.REACT_APP_NAME } |  Signs and Banners`}</title>	
					<meta name="description" content="Our 4x8 flatbed printer can print your signs and banners in UV protected full color glory on almost any flat material up to 2-inches thick!" />
                </Helmet>
		  <Header page_services={true}/>
		  
<div role="main" className="main">

	<section className="page-header page-header-classic page-header-md">

		<div className="container">

			<div className="row">

				<div className="col-md-8 order-2 order-md-1 align-self-center p-static">

					<h1 data-title-border>Signs and Banners</h1>

				</div>

				<div className="col-md-4 order-1 order-md-2 align-self-center">

					<ul className="breadcrumb d-block text-md-right">

						<li><a href="/">Home</a></li>

						<li><a href="/services">Services</a></li>

						<li className="active">Signs and Banners</li>

					</ul>

				</div>

			</div>

		</div>

	</section>



	<div className="container pb-1">



		<div className="row pt-4">

			<div className="col">

				<div className="overflow-hidden mb-3">

					<h2 className="font-weight-bold text-8 mb-0 appear-animation" data-appear-animation="maskUp">

						We Are Here To Help You

					</h2>

				</div>

			</div>

		</div>



		<div className="row">

			<div className="col-lg-10">

				<div className="overflow-hidden">

					<p className="lead mb-0 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="200">

						We've been in the print business for more than a quarter century. We <span className="alternative-font">know</span> printing. Our talented team can help you get the output you deserve, whether its Engineering prints or fine artwork, we've got you covered.

					</p>

				</div>

			</div>

			<div className="col-lg-2 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="500">

				<a target="_new" href="https://uploadjob.j2b.com/upload/" className="btn btn-modern btn-dark mt-1">Send your Files!</a>

			</div>

		</div>



		<div className="row">

			<div className="col py-3">

				<hr className="solid mt-5 mb-2"/>

			</div>

		</div>



		

	</div>



	<section className="section bg-color-grey section-height-3 border-0 mt-5 mb-0">

		<div className="container">



			<div className="row">

				<div className="col">




				<div className="row align-items-center pt-5 pb-3 appear-animation" data-appear-animation="fadeInRightShorter">

					<div className="col-md-8 pr-md-5 mb-5 mb-md-0">

						<h2 className="font-weight-normal text-6 mb-3"><strong className="font-weight-extra-bold">Wooden</strong> and Metal Sign Options </h2>

								<p className="text-4">Print full-color graphics onto wood and metal. Directly onto pre-manufactured and manufactured wooden materials and signs.</p>
								
						
						
					</div>

					<div className="col-md-4 px-5 px-md-3">

						<img className="img-fluid scale-2 my-4" src="/img/woodensign.jpg" alt="Wooden and metal signs" />

					</div>

				</div>



				<div className="row align-items-center pt-5 pb-3 appear-animation" data-appear-animation="fadeInLeftShorter">
				<div className="col-md-4 mb-4 mb-md-0">
					<img className="img-fluid scale-2 pr-5 pr-md-0 my-4" src="/img/ada.jpg" alt="ada signs" />

				</div>
					<div className="col-md-8 pl-md-5">

						<h2 className="font-weight-normal text-6 mb-3"><strong className="font-weight-extra-bold">ADA</strong> Signs </h2>

								<p className="text-4">Produce ID, directional and ADA signage as well as nameplates, badges, plaques, promotional items</p>

					</div>

					

				</div>


				<div className="row align-items-center pt-5 pb-3 appear-animation" data-appear-animation="fadeInRightShorter">

					<div className="col-md-8 pr-md-5 mb-5 mb-md-0">

						<h2 className="font-weight-normal text-6 mb-3"><strong className="font-weight-extra-bold">Banners</strong>, POP's and Temporary Signage </h2>

								<p className="text-4">J2 can print large format banners, POP’s, tradeshow signs and banners with vibrant and consistent colors, smooth gradations and razor-sharp details.
</p>
								
						
						
					</div>

					<div className="col-md-4 px-5 px-md-3">

						<img className="img-fluid scale-2 my-4" src="/img/pop.jpg" alt="Banners pops and temporary signs" />

					</div>

				</div>





					<div className="row align-items-center pt-4 appear-animation" data-appear-animation="fadeInLeftShorter">

						<div className="col-md-4 mb-4 mb-md-0">

							<img className="img-fluid scale-2 pr-5 pr-md-0 my-4" src="/j2img/services_flatbed.png" alt="Flatbed printer" />

						</div>

						<div className="col-md-8 pl-md-5">

							<h2 className="font-weight-normal text-6 mb-3"><strong className="font-weight-extra-bold">Outdoor Signs</strong> and Banners</h2>

							<p className="text-4">Aluminum ePanel, acrylic sheets, vinyl media and more. We can produce the weather and UV resistant signs and banners you project demands.</p>
							<p>
							Effective and Affordable Outdoor Signage
									From Permanent displays to sandwich boards we've got your outdoor signage needs covered. 
									</p><p>
								If your outdoor signage isn't giving you the edge in visibilty you'd hoped for then its time for customized outdoor signage from J2! 

								
								</p><p>
								No matter what kind of signs you need, Business signs, church signs, event signs, school signs, or full color banners and advertisements, we've got the perfect product to meet your needs.

								
								</p><p>
								We offer a full range of material options for you to choose from, and can print on any flat material you need! Flexible signs, wooden signs, metal signs, outdoor banners, if you can display it, J2 can print it for you!

								

								
								</p><p>
								

								Contact us today for more information about our outdoor signs and banners!</p>
						</div>

					</div>

					<hr className="solid my-5"/>

					<div className="row align-items-center pt-5 pb-3 appear-animation" data-appear-animation="fadeInRightShorter">

						<div className="col-md-8 pr-md-5 mb-5 mb-md-0">

							<h2 className="font-weight-normal text-6 mb-3"><strong className="font-weight-extra-bold">Indoor Signs</strong> and Presentation Graphics </h2>

									<p className="text-4">We can print on any material thats flat and less than 2-inches thick, up to 8ft by 4ft</p>
									<p>
									Indoor Signs and Displays
							With our large format flat bed printer we can print upto 4 feet by 8 feet on any flat media. We can create perfect indoor signage, backlit displays and presentation materials to make your trade show or demo floor display really pop! We offer a full line of sign media, stands and display frameworks perfect for all types of indoor signage. 

							</p><p>

							Back lit displays
							Large folding displays
							Table top displays
							banners and graphics
							vinyl graphics
							pop up displays
							booth backdrops
							etc.
 

							
							</p><p>
							Call us today for more information about what we can do for you!

							</p>
							
							
							
							
							</div>

						<div className="col-md-4 px-5 px-md-3">

							<img className="img-fluid scale-2 my-4" src="/img/indoordisplay.jpg" alt="Indoor Display Graphics" />

						</div>

					</div>

				</div>

			</div>

		</div>

	</section>				


	<ContactForm email="help" subject="Contact Form [ Signs and Banners Services Page ]"/>



</div>
		  
		  
		  
		  <Footer/>
		  </div>
	  )
  }
}