import React, { Component } from 'react';

import Header from '../header/header.js';
import Footer from '../footer/footer.js';

import ContactForm from '../support/contactform.js';
import  {Helmet} from "react-helmet";

import PaypalButton from "../paypal/button.js";

var librarySet = [
    { 
      filename: "bodytempcheck",
      sizes: ["8.5x11","17x22","34x44"],
      description: "Body temperature Check",
      price: [5,10,20]
    
    },
    { 
        filename: "donotenterifsick",
        sizes: ["8.5x11","17x22","34x44"],
        description: "Do not enter if sick",
        price: [5,10,20]
      
    },
    { 
        filename: "employeetempcheck",
        sizes: ["8.5x11","17x22","34x44"],
        description: "Employees must check temp",
        price: [5,10,20]
      
    },
    { 
        filename: "facemaskrequired",
        sizes: ["8.5x11","17x22","34x44"],
        description: "Face masks requiredk",
        price: [5,10,20]
      
    },
    { 
        filename: "handsanitizerstation",
        sizes: ["8.5x11","17x22","34x44"],
        description: "hand sanitizer station",
        price: [5,10,20]
      
    },
    { 
        filename: "maskrequired",
        sizes: ["8.5x11","17x22","34x44"],
        description: "Masks Required",
        price: [5,10,20]
      
    },
    { 
        filename: "newhours",
        sizes: ["8.5x11","17x22","34x44"],
        description: "New Business Hours",
        price: [5,10,20]
      
    },
    { 
        filename: "opencurbside",
        sizes: ["8.5x11","17x22","34x44"],
        description: "Cubside Pickup",
        price: [5,10,20]
      
    },
    { 
        filename: "socialdistancing",
        sizes: ["8.5x11","17x22","34x44"],
        description: "Social Distancing",
        price: [5,10,20]
      
    },
    { 
        filename: "socialdistancing2",
        sizes: ["8.5x11","17x22","34x44"],
        description: "Social Distancing",
        price: [5,10,20]
      
    },
    { 
        filename: "takeoutcurbside",
        sizes: ["8.5x11","17x22","34x44"],
        description: "Take out or Curbside",
        price: [5,10,20]
      
    },
    { 
        filename: "takeoutonly",
        sizes: ["8.5x11","17x22","34x44"],
        description: "Takeout only",
        price: [5,10,20]
      
    },
    { 
        filename: "tempscreening",
        sizes: ["8.5x11","17x22","34x44"],
        description: "Body temperature Check",
        price: [5,10,20]
      
    },
    { 
        filename: "wewilldeliver",
        sizes: ["8.5x11","17x22","34x44"],
        description: "We will deliver!k",
        price: [5,10,20]
      
    }

]


export default class Template extends Component {

constructor(props) {
    super(props);
    

}


componentDidMount() {
    

}


    renderTemplate(id) {
        var ret ="";

        var styleOptions= (
            <>
            <option id="m1" valuie="m1">Poster</option>
            <option id="m2" valuie="m2">Flyer</option>
            <option id="m3" valuie="m3">Window Cling</option>
            <option id="m4" valuie="m4">Sticker</option>
            <option id="m4" valuie="m4">Banner</option>
            </>
        )
        var mediaOptions= (
            <>
            <option id="m1" valuie="m1">Glossy Poster</option>
            <option id="m2" valuie="m2">Matte Poster</option>
            <option id="m3" valuie="m3">Foam Board</option>
            <option id="m4" valuie="m4">Gator Board</option>
            </>
        )

        var sizeOptions ="";
        for(var i =librarySet[id].sizes.length-1; i >=0;i--) {
            sizeOptions = (<>
            <option id={i} value={i}>{librarySet[id].sizes[i]}</option>
            {sizeOptions}
            </>)
        }

        ret = ( <>
        <div className="row">

            <div className="col-md-4">
                <h1>{librarySet[id].description}</h1>
                <img src={"/templates/"+librarySet[id].filename+"_tn.jpg"}/>

            </div>
          
            <div className="col-md-8">
                <form>
                    <div className="form-group row">
                        <label className="col-lg-3 font-weight-bold text-dark col-form-label form-control-label text-2">Select Style</label>
                        <div className="col-lg-9">
                            <select id="style" class="form-control">
                                {styleOptions}
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-lg-3 font-weight-bold text-dark col-form-label form-control-label text-2">Select Material</label>
                        <div className="col-lg-9">
                            <select id="media" class="form-control">
                                {mediaOptions}
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-lg-3 font-weight-bold text-dark col-form-label form-control-label text-2">Select Size</label>
                        <div className="col-lg-9">
                            <select id="pagesize" class="form-control">
                                {sizeOptions}
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-lg-3 font-weight-bold text-dark col-form-label form-control-label text-2">Quantity</label>
                        <div className="col-lg-4">
                                        <div class="quantity">
                                    <input type="button" className="minus" value="-"/>
                                    <input type="text" className="input-text qty text" title="Qty" value="1" name="quantity" min="1" step="1"/>
                                    <input type="button" className="plus" value="+"/>
                                </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-lg-3 font-weight-bold text-dark col-form-label form-control-label text-2">Extra Instructions</label>
                        <div className="col-lg-8">
                                      
                                    <input type="text" style={{width:"100%"}} className="form-control"/>
                                    
                        </div>
                        
                    </div>
                    <br/>
                    <PaypalButton/>
                </form>
            </div>



        </div>
        
        </>)
        return ret;
    }

  render() {
      var content = this.renderTemplate(this.props.id?this.props.id:0);

	  return (
        <div>
            <Helmet>
              <title>J2 Blueprint Supply Co. | Print Template</title>	
              <meta name="description" content="Print template example" />
            </Helmet>


            <Header page_services={true}/>
    
            <div role="main" className="main" suppressHydrationWarning={true}>
            <section className="page-header page-header-modern scale-1 page-header-md" >

<div className="container">

    <div className="row">

        <div className="col-md-8 order-2 order-md-1 align-self-center p-static">

            <h1 className="text-light">Order a print from our <strong>Library</strong></h1>

<span className="sub-title text-light">Please contact us below if you'd like to customise this template</span>

        </div>

        <div className="col-md-4 order-1 order-md-2 align-self-center">

            <ul className="breadcrumb d-block text-md-right">

                <li><a href="/" className="text-light">Home</a></li>

                <li><a href="/library" className="text-light">Library</a></li>
                <li className="active text-light">Template {this.props.id}</li>

            </ul>

        </div>

    </div>

</div>

</section>
            <section className="section section-center">
                <div className="container align-self-center">
                 {content}
                </div>
            </section>
    



            <section class="section section-default border-0 my-5">
					<div class="container py-4">

						<div class="row">
							<div class="col pb-4 text-center">
								<h2 class="text-color-dark font-weight-normal text-5 mb-0 pt-2">Need this template <strong class="font-weight-extra-bold">Customized?</strong></h2>
								<p>Contact us below and our graphics designers can modify this template to meet your needs.</p>
							</div>
						</div>
					</div>
				</section>
                <ContactForm email="help" subject={"Contact Form [ Print Template - "+librarySet[this.props.id].description+"]"}/>
            </div>
            <Footer />
        </div>
      )
    }

}